var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"other-banner"},[_c('Header'),_c('div',{staticClass:"banner-content"},[_c('div',{staticClass:"banner-caption"},[_c('h1',[_vm._v(_vm._s(_vm.$route.params.subscriptionName))]),_c('div',{staticClass:"banner-btn mt-1 text-dark"},[_c('nav',{staticClass:"flex-sm-00-auto ml-sm-3",attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb breadcrumb-alt"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_vm._v("Home")])],1),(_vm.$route.params.categoryId)?_c('li',{staticClass:"breadcrumb-item",attrs:{"aria-current":"page"}},[_c('router-link',{attrs:{"to":{
                    name: 'Placement Prep',
                    params: {
                      categoryId: _vm.$route.params.categoryId,
                      categoryName: _vm.$route.params.categoryName,
                    },
                  }}},[_vm._v(_vm._s(_vm.$route.params.categoryName))])],1):_vm._e(),_c('li',{staticClass:"breadcrumb-item",attrs:{"aria-current":"page"}},[_c('router-link',{attrs:{"to":{ name: 'Subscription Description' }}},[_vm._v(_vm._s(_vm.$route.params.subscriptionName))])],1),_c('li',{staticClass:"breadcrumb-item",attrs:{"aria-current":"page"}},[_c('router-link',{attrs:{"to":{ name: 'Subscription Mock Test List' }}},[_vm._v("Subscription Mock Test List")])],1)])])])])])],1),_c('section',{staticClass:"plac-pre-prac-tst mt-4 my-2"},[_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"home","role":"tabpanel","aria-labelledby":"home-tab"}},[(_vm.mockTests.length > 0)?_c('div',[_c('section',{staticClass:"tl-lst-tst pt-0 pb-2"},_vm._l((_vm.mockTests),function(mockTest,index){return _c('router-link',{key:index,staticClass:"in-list-test",attrs:{"to":{ name: 'Subscription Description' }}},[_c('div',{staticClass:"list-test"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"lft-lst-tst flex-grow-1 pe-4"},[_c('h5',[_vm._v(_vm._s(mockTest.name))]),_c('p',{staticClass:"text-primary"},[_vm._v(" Duration: "+_vm._s(mockTest.duration)+" Mins ")]),_c('div',{staticClass:"d-flex"},[_c('router-link',{staticClass:"mt-2",attrs:{"to":{
                          name: 'Subscription Mock Test Details',
                          params: {
                            testId: mockTest.id,
                            testType: 'subscription_mock_test',
                          },
                        }}},[(_vm.$route.params.userSubscriptionStatus == 1)?_c('p',{staticClass:"viw-dwn h6 py-2 mt-2",staticStyle:{"width":"fit-content"}},[_c('b',[_vm._v("View Details")])]):_vm._e()]),(_vm.$route.params.userSubscriptionStatus != 1)?_c('p',{staticClass:"lock-vid"},[_c('i',{staticClass:"fas fa-lock",attrs:{"ahref":"/subscription"}})]):_vm._e()],1)]),_c('div',{staticClass:"rt-comp-img d-grid"},[_c('img',{attrs:{"src":mockTest.image,"alt":""}})])])])])}),1)]):_c('div',[_c('h5',{staticClass:"mx-5"},[_vm._v("you have exhausted your attempt for this Test")])])])])]),_c('b-overlay',{staticClass:"position-fixed",attrs:{"show":_vm.loader,"no-wrap":"","blur":"2px","opacity":"0.60"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('i',{staticClass:"fa fa-spinner fa-pulse fa-4x fa-fw text-primary"})]},proxy:true}])}),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }