<template>
  <div>
    <Header />
    <section class="other-banner">
      <div class="banner-content">
        <div class="banner-caption">
          <h1>{{ $route.params.topicName }}</h1>
          <div class="banner-btn mt-1 text-dark">
            <!--           <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
            <a class="lst-a mx-1">Placement Prep Practies Test</a> -->
            <nav class="flex-sm-00-auto ml-sm-3" aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-alt">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </li>
                <li
                  v-if="$route.params.categoryName"
                  class="breadcrumb-item"
                  aria-current="page"
                >
                  <router-link
                    :to="{
                      name: 'Placement Prep',
                      params: {
                        categoryId: $route.params.categoryId,
                        categoryName: $route.params.categoryName,
                      },
                    }"
                    >{{ $route.params.categoryName }}</router-link
                  >
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Subscription Description' }">{{
                    $route.params.subscriptionName
                  }}</router-link>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Practice Test List' }"
                    >Practice Test List</router-link
                  >
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Practice Tests' }">{{
                    $route.params.topicName
                  }}</router-link>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>

    <section class="plac-pre-prac-tst mt-4 my-2">
      <!-- video list -->
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <div v-if="subtopics.length > 0">
            <section
              class="tl-lst-tst pt-0 pb-2"
              v-for="(subtopic, index) in subtopics"
              :key="index"
            >
              <router-link class="in-list-test" to="">
                <div href="" class="list-test">
                  <div class="d-flex">
                    <div class="lft-lst-tst flex-grow-1 pe-4">
                      <h5>{{ subtopic.topic.name }}</h5>
                      <p class="text-primary">
                        {{ subtopic.topic.description }}
                      </p>
                      <router-link
                        :to="{
                          name: 'Practice Test Details',
                          params: {
                            subId: $route.params.subscriptionId,
                            subTopicId: subtopic.id,
                          },
                        }"
                      >
                        <p
                          class="viw-dwn h6 py-2 mt-3"
                          style="width: fit-content;"
                        >
                          <b>View Details</b>
                        </p>
                      </router-link>
                    </div>
                    <div class="rt-comp-img d-grid">
                      <img :src="subtopic.topic.image" alt="" />
                    </div>
                  </div>
                </div>
              </router-link>
            </section>
          </div>
          <div v-else>
            <h5 class="mx-5">you have exhausted your attempt for this Test</h5>
          </div>
        </div>
        <!-- End of video list -->
      </div>
    </section>
    <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      childTopicId: "",
      subtopics: [],
      loader: false,
    };
  },
  mounted() {
    this.getSubtopics();

    console.log(this.subtopics.length);
  },
  methods: {
    async getSubtopics() {
      this.loader = true;
      const formdata = new FormData();
      formdata.append("sub_id", this.$route.params.subscriptionId);
      formdata.append("sub_content", "childtest");
      formdata.append("mapped_topic_id", this.$route.params.topicId);

      await axios
        .post(
          `${this.$store.getters.baseUrl}/subscription/sub_topics?type=web`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data.status == "success") {
            this.subtopics = response.data.sub_topics;
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
