import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  //local url: "http://192.168.1.47/aptitude_buster/public/api"
  //live url: "https://test.aptitudebuster.com/aptitude_buster/public/index.php/api"
  // test url: "https://website.aptitudebuster.com/public/api"

  // "https://test.aptitudebuster.com/aptitude_buster/public/index.php/api",
  // "https://laraveltesting.aptitudebuster.com/aptitude_buster/public/index.php/api",

  state: {
    baseUrl: "https://test-testing.aptitudebuster.com/public/index.php/api", // TEST URL
    // "https://test.aptitudebuster.com/aptitude_buster/public/index.php/api", // live
    // "https://backendscript.aptitudebuster.in/aptitude_buster/public/index.php/api",//last used

    // "http://192.168.1.46/aptitude_buster/public/api",
    // "https://test-testing.aptitudebuster.com/public/api",
  },

  getters: {
    baseUrl: (state) => state.baseUrl,
  },
  mutations: {},
  actions: {},
  modules: {},
});
