<template>
  <div>
    <section
      class="other-banner"
      style="
        background: url('../../src/assets/img/test/banner.jpg') no-repeat;
        background-position: center;
        min-height: auto;
      "
    >
      <Header />
    </section>

    <!-- <div class="d-flex ref-clr ">
      <p class="bg-gn">Correct</p>
      <p class="bg-rd">Incorrect</p>
      <p class="bg-def">Unattempted</p>
    </div> -->
    <section
      class="d-flex justify-content-center p-0 mx-0"
      style="min-height: 535px"
    >
      <aside class="p-3 w-100" v-if="resultQuestions.length > 0">
        <div class="mb-2">
          <div class="justify-content-center ref-clr d-flex mb-3">
            <p v-if="resultQuestions[index].result == 1" class="bg-gn">
              Correct
            </p>
            <p v-if="resultQuestions[index].result == 2" class="bg-rd">
              Incorrect
            </p>
            <p v-if="resultQuestions[index].result == 3" class="bg-def">
              Unattempted
            </p>
          </div>
          <div class="text-center">
            <h4>
              <b>{{ resultQuestions[index].topicName }}</b>
            </h4>
          </div>
          <span class="float-left"
            >Q.No <b>{{ index + 1 }}</b> of {{ resultQuestions.length }}</span
          >
        </div>

        <article class="question-img">
          <p
            class="mb-1"
            id="groupQuestion"
            style="display: none"
            v-if="resultQuestions[index].questions.group_question != null"
          >
            <b
              v-html="
                `${resultQuestions[index].questions.group_question.question}`
              "
            ></b>
          </p>
          <img
            class="mb-1"
            id="groupImage"
            v-if="resultQuestions[index].questions.group_question != null"
            :src="resultQuestions[index].questions.group_question.image"
            style="width: 50%; display: none"
            alt=""
          />
          <p
            class="mb-1"
            v-html="`${resultQuestions[index].questions.question}`"
          ></p>
          <div class="row">
            <div class="col-md-6">
              <div class="questions-img">
                <img
                  class="mb-1"
                  :src="resultQuestions[index].questions.image"
                  style="width: 100%"
                  alt=""
                />
              </div>
            </div>
            <div class="col-md-6" style="padding: 0">
              <div class="questions-read">
                <button
                  class="btn-radious mx-2"
                  style="float: right"
                  v-if="resultQuestions[index].questions.group_question != null"
                  @click="readMore()"
                  id="readMore"
                >
                  Read More
                </button>
              </div>
            </div>
          </div>

          <div
            class="radi-box"
            v-if="resultQuestions[index].questions.question_type == 'objective'"
          >
            <ul class="row">
              <li
                class="w-50"
                v-for="(option, index1) in resultQuestions[index].questions
                  .options"
                :key="index1"
              >
                <input
                  type="radio"
                  :checked="
                    resultQuestions[index].answer == option.option ||
                    resultQuestions[index].questions.answer == option.option
                      ? true
                      : false
                  "
                />
                <label class="btn-cek">
                  <span>{{
                    index1 == 0
                      ? "A"
                      : index1 == 1
                      ? "B"
                      : index1 == 2
                      ? "C"
                      : index1 == 3
                      ? "D"
                      : "E"
                  }}</span
                  >{{ option.option }}</label
                >
                <div
                  :class="
                    resultQuestions[index].questions.answer == option.option
                      ? 'check gn-ck'
                      : 'check rd-ck'
                  "
                ></div>
              </li>
            </ul>
          </div>
          <div
            v-if="
              resultQuestions[index].questions.question_type == 'fill_in_blank'
            "
          >
            <label
              ><span
                >Your Answer:
                <b>{{
                  resultQuestions[index].answer
                    ? resultQuestions[index].answer
                    : "Not Available"
                }}</b></span
              ></label
            >
            <br />
            <label
              ><span
                >Correct Answer:
                <b>{{ resultQuestions[index].questions.answer }}</b></span
              ></label
            >
          </div>

          <div
            v-if="
              resultQuestions[index].questions.solutions.length > 0 &&
                resultQuestions[index].questions.question_type != 'coding'
            "
          >
            <br />
            Explanations:

            <div
              class="mt-2"
              v-for="(solution, index3) in resultQuestions[index].questions
                .solutions"
              :key="index3"
            >
              <p
                v-if="
                  resultQuestions[index].questions.solutions[index3].type ==
                    'description'
                "
              >
                <b
                  v-html="
                    `${resultQuestions[index].questions.solutions[index3].description}`
                  "
                ></b>
              </p>
              <img
                class="mt-2"
                v-if="resultQuestions[index].questions.solutions[index3].image"
                :src="resultQuestions[index].questions.solutions[index3].image"
                alt=""
                style="width: 50%"
              />

              <p
                class="vw-btn explan-more"
                v-if="
                  resultQuestions[index].questions.solutions[index3]
                    .video_url != null
                "
                @click="
                  (youtubeId =
                    resultQuestions[index].questions.solutions[index3]
                      .video_url),
                    videosubmit(),
                    (show = 'show')
                "
              >
                Watch Videos
              </p>
              <!-- {{ resultQuestions[index].questions.solutions[index3].video_url }} -->
              <!--</a> -->
            </div>
          </div>

          <div
            class="solution-page"
            v-if="resultQuestions[index].questions.question_type == 'coding'"
          >
            <b-card no-body>
              <b-tabs pills card>
                <b-tab title="Solution Code" active>
                  <b-card-text>
                    <div class="solutin-bg">
                      <div
                        class="mt-2"
                        v-for="(solution, index3) in resultQuestions[index]
                          .questions.solutions"
                        :key="index3"
                      >
                        <h6
                          v-if="
                            resultQuestions[index].questions.solutions[index3]
                              .type == 'description'
                          "
                        >
                          Explanations:
                        </h6>

                        <b
                          v-html="
                            `${resultQuestions[index].questions.solutions[index3].description}`
                          "
                        ></b>
                      </div>
                      <!-- <ul>
                      <li>let A0 = 1</li>
                      <li>let A1 = 2</li>
                      <li>let A2 = A0 + A1</li>
                    <li>A0 = 2 console.log(A2)</li>

                    </ul> -->
                    </div>
                  </b-card-text>
                </b-tab>
                <b-tab title="Your Code"
                  ><b-card-text>
                    <div class="solutin-bg">
                      <span
                        v-html="`${resultQuestions[index].source_code}`"
                      ></span>
                    </div> </b-card-text
                ></b-tab>
                <b-tab title="Solution Videos">
                  <b-card-text>
                    <div
                      v-for="(solution, index3) in resultQuestions[index]
                        .questions.solutions"
                      :key="index3"
                    >
                      <img
                        class="mt-2"
                        v-if="
                          resultQuestions[index].questions.solutions[index3]
                            .image
                        "
                        :src="
                          resultQuestions[index].questions.solutions[index3]
                            .image
                        "
                        alt=""
                        style="width: 50%"
                      />

                      <p
                        class="vw-btn explan-more"
                        v-if="
                          resultQuestions[index].questions.solutions[index3]
                            .video_url != null
                        "
                        @click="
                          (youtubeId =
                            resultQuestions[index].questions.solutions[index3]
                              .video_url),
                            videosubmit(),
                            (show = 'show')
                        "
                      >
                        Watch Videos
                      </p>
                    </div>
                  </b-card-text></b-tab
                >
              </b-tabs>
            </b-card>
          </div>

          <div
            style="position: relative"
            id="youtubeVideo"
            v-if="youtubeId"
            v-show="!show"
          >
            <LazyYoutube
              :src="youtubeId"
              ref="youtubeLazyVideo"
              aspect-ratio="16:9"
              thumbnail-quality="standard"
            />
            <!-- <div
              style="
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
              "
            ></div> -->
            <div
              style="position: absolute; top:0; left:0; width:100%; height:90%;"
            ></div>
            <div
              style="position: absolute; bottom:0; right:0px; width:250px; height:45px;"
            ></div>
            <div
              class="buttons"
              style="    position: absolute;
              bottom: -44px;
              left: 0px;
              display: flex;"
            >
              <!-- class="btn-radious mx-2" -->

              <button
                class="play_btn"
                :class="{ play_btns: buttonCol == 1 }"
                @click="handleClick('playVideo', 'youtubeLazyVideo', 1)"
              >
                Play
              </button>
              <!-- <button
                class="btn-radious mx-2"
                @click="handleClick('stopVideo', 'youtubeLazyVideo', 2)"
              >
                Stop
              </button> -->
              <button
                class="pause_btn"
                :class="{ pause_btns: buttonCol == 2 }"
                @click="handleClick('pauseVideo', 'youtubeLazyVideo', 2)"
              >
                Pause
              </button>
              <button
                class="reset_btn"
                :class="{ reset_btns: buttonCol == 3 }"
                @click="handleClick('resetView', 'youtubeLazyVideo', 3)"
              >
                Reset
              </button>
            </div>
          </div>
          <br />

          <div class="my-3 justify-content-between">
            <button
              class="btn-radious"
              @focus="reRender()"
              @click="
                (index = index - 1),
                  reRender(),
                  resultQuestions[index].questions.group_question != null
                    ? groupUpdate()
                    : ''
              "
              v-if="!index == 0"
              style="float: left"
            >
              Previous
            </button>
            <div id="youtubeVideo" v-if="youtubeId">
              <button
                class="btn-radious mx-2"
                @focus="reRender()"
                @click="
                  handleClick('stopVideo', 'youtubeLazyVideo'),
                    (index = index + 1),
                    reRender(),
                    resultQuestions[index].questions.group_question != null
                      ? groupUpdate()
                      : ''
                "
                v-if="index != resultQuestions.length - 1"
                style="float: right"
              >
                Next
              </button>
            </div>
            <div v-else>
              <button
                class="btn-radious mx-2"
                @focus="reRender()"
                @click="
                  // handleClick('stopVideo', 'youtubeLazyVideo'),
                  (index = index + 1),
                    reRender(),
                    resultQuestions[index].questions.group_question != null
                      ? groupUpdate()
                      : ''
                "
                v-if="index != resultQuestions.length - 1"
                style="float: right"
              >
                Next
              </button>
            </div>
          </div>
        </article>
      </aside>

      <aside
        class="ques-ptn"
        style="width: 450px"
        v-if="resultQuestions.length > 0"
      >
        <h4 class="mb-2">Question Palette</h4>
        <div class="ref-clr d-flex">
          <p class="bg-gn">Correct</p>
          <p class="bg-rd">Incorrect</p>
          <p class="bg-def">Unattempted</p>
        </div>
        <div
          class="fst-qes-pag"
          v-for="(topic, index2) in palette"
          :key="index2"
        >
          <h5 class="mb-1">{{ topic.topic_name }}</h5>
          <div
            class="qes-pag-p-tl"
            v-for="(question, index1) in resultQuestions"
            :key="index1"
          >
            <p
              class="bg-gn"
              v-if="
                question.result == 1 && question.topicName == topic.topic_name
              "
              @click="index = index1"
            >
              {{ index1 + 1 }}
            </p>
            <p
              class="bg-def"
              v-if="
                question.result == 3 && question.topicName == topic.topic_name
              "
              @click="index = index1"
            >
              {{ index1 + 1 }}
            </p>
            <p
              class="bg-rd"
              v-if="
                question.result == 2 && question.topicName == topic.topic_name
              "
              @click="index = index1"
            >
              {{ index1 + 1 }}
            </p>
          </div>
        </div>
      </aside>
    </section>
    <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { LazyYoutube } from "vue-lazytube";
import axios from "axios";
export default {
  components: {
    Header,
    Footer,
    LazyYoutube,
  },
  data() {
    return {
      show: false,
      resultQuestions: [],
      index: 0,
      palette: [],
      loader: false,
      youtubeId: "",
      youtubeLink: "",
      buttonCol: 0,
    };
  },
  mounted: async function() {
    await this.getResultQuestions();
    this.reRender();
  },
  watch: {
    resultQuestions: function() {
      console.log(window);
      console.log("data changed1");
      this.$nextTick().then(() => {
        //this.$forceUpdate();
        this.reRender();
      });
    },
    index: function() {
      console.log(window);
      console.log("data changed");
      // document.getElementById("youtubeVideo").show = false;
      document.getElementById("youtubeVideo").style.display = "none";
      this.$nextTick().then(() => {
        //  this.$forceUpdate();
        this.reRender();
      });
    },
  },
  methods: {
    videosubmit() {
      document.getElementById("youtubeVideo").style.display = "block";
      //document.getElementById(`youtubeVideo`).show("show");
      console.log(this.youtubeLink);
      console.log();
    },
    handleClick(event, ref, col) {
      this.$refs[ref][event]();
      this.buttonCol = col;
    },
    async reRender() {
      console.log(window);
      if (window.MathJax) {
        console.log("rendering mathjax");
        await window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () =>
          console.log("done")
        );
      }
      //this.$mount();
    },
    groupUpdate() {
      if (document.getElementById("groupQuestion")) {
        let groupQuestion = document.getElementById("groupQuestion");
        groupQuestion.style.display = "none";
        let groupImage = document.getElementById("groupImage");
        groupImage.style.display = "none";
        document.getElementById("readMore").innerHTML = "read more";
      }
      // this.$mount();
    },
    readMore() {
      let groupQuestion = document.getElementById("groupQuestion");
      let groupImage = document.getElementById("groupImage");
      groupQuestion.style.display =
        groupQuestion.style.display == "none" ? "block" : "none";
      groupImage.style.display =
        groupImage.style.display == "none" ? "block" : "none";

      let readMore = document.getElementById("readMore");
      readMore.innerHTML =
        groupQuestion.style.display == "none" ? "read more" : "read less";
    },
    async getResultQuestions() {
      this.loader = true;
      const formdata = new FormData();
      formdata.append("user_test_id", this.$route.params.testId);
      await axios
        .post(
          `${this.$store.getters.baseUrl}/mock-tests/question-answer`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.palette = response.data.tests_with_answers.topic_report;
            response.data.tests_with_answers.topic_report.forEach((ele) => {
              this.resultQuestions = [
                ...this.resultQuestions,
                ...ele.question_answer.map((ele1) => {
                  return { ...ele1, topicName: ele.topic_name };
                }),
              ];
            });
            this.loader = false;
            console.log(this.resultQuestions);
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>

<style>
.play_btn {
  background-color: dodgerblue;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: dodgerblue;
  text-align: center;
  padding: 6px 15px;
  height: fit-content;
}

.play_btns {
  background-color: dodgerblue;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: dodgerblue;
  text-align: center;
  padding: 6px 15px;
  height: fit-content;
  opacity: 0.5;
}

.pause_btn {
  background-color: green;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  text-align: center;
  padding: 6px 15px;
  height: fit-content;
  border: green;
}

.pause_btns {
  background-color: green;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: green;
  text-align: center;
  padding: 6px 15px;
  height: fit-content;
  opacity: 0.5;
}

.reset_btn {
  background-color: slategray;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  text-align: center;
  padding: 6px 15px;
  height: fit-content;
  border: slategray;
}

.reset_btns {
  background-color: slategray;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: slategray;
  text-align: center;
  padding: 6px 15px;
  height: fit-content;
  opacity: 0.5;
}

.stop_btn {
  background-color: orangered;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: orangered;
  text-align: center;
  padding: 6px 15px;
  height: fit-content;
}

.stop_btns {
  background-color: orangered;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: orangered;
  text-align: center;
  padding: 6px 15px;
  height: fit-content;
  opacity: 0.5;
}
</style>
