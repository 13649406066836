<template>
  <div class="d-sm-flex lo-g-in-page">
    <div class="col-12 col-sm-6 col-md-7 col-lg-8 p-0">
      <div class="lft-log-in d-grid">
        <img
          class="m-auto"
          src="../../src/assets/img/login/login-illus-1.svg"
          alt=""
        />
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-5 col-lg-4 p-0 tl-rit-login">
      <div class="main-log-in" id="logIn">
        <div class="rt-log-in d-grid">
          <div class="rt-cont">
            <img
              class="ab-logo mb-3"
              src="../../src/assets/img/ab-logo.png"
              alt=""
            />
            <p class="p-welcom"><span>Welcome</span><br />to Aptitude Buster</p>
            <div class="material-form-design">
              <div class="group-material">
                <input
                  type="number"
                  @keyup.enter="login()"
                  v-model="mobileNumber"
                  @focus="
                    (mobileNumberEmpty = false), (invalidMobileNumber = false)
                  "
                  required
                />
                <span class="highlight"></span>
                <span class="bar"></span>
                <div style="color: red" v-if="mobileNumber.length > 10">
                  Mobile number only 10 digits
                </div>
                <div style="color: red" v-if="mobileNumberEmpty">
                  Mobile number is required
                </div>
                <div style="color: red" v-if="invalidMobileNumber">
                  Invalid mobile number
                </div>
                <label>Enter Mobile Number</label>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-btn-login">
          <button class="btn-btn mb-3" type="button" @click="login()">
            Next
          </button>

          <p class="text-center mt-2">
            By Signing up you agree to our
            <a href="/#/terms-policy">terms & Conditions</a>
          </p>
        </div>
      </div>
    </div>
    <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
  </div>
</template>

<script>
import axios from "axios";
import vue from "vue";
export default {
  name: "Login",
  data() {
    return {
      mobileNumber: "",
      invalidMobileNumber: false,
      mobileNumberEmpty: false,
      mobile: "",
      loader: false,
    };
  },
  mounted() {
    /* window.addEventListener('keyup', event => {
      if (event.keyCode === 13) { 
        this.login()
      }
    })*/
  },
  methods: {
    async login() {
      // this.loader = true;
      if (this.mobileNumber == "") {
        this.mobileNumberEmpty = true;
        //this.loader = false;
        return;
      } else this.mobileNumberEmpty = false;

      if (this.mobileNumber.length != 10 || this.mobileNumber.length > 10) {
        this.invalidMobileNumber = true;
        // this.loader = false;
        return;
      } else this.invalidMobileNumber = false;

      const formdata = new FormData();
      formdata.append("mobile", this.mobileNumber);
      await axios
        .post(`${this.$store.getters.baseUrl}/login`, formdata)
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            localStorage.setItem("mobile", response.data.user.mobile);
            // this.loader = false;
            // alert(response.data.message);
            vue.swal({
              title: "success",
              icon: "success",
              text: response.data.message,
              showCancelButton: false,
              reverseButtons: false,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              timer: 2500,
            });
            // this.$swal("success", response.data.message, "success");
            setTimeout(() => {
              this.$router.push({ name: "Otp" });
            }, 2500);
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    nxtOtp() {
      var element1 = document.getElementById("logIn");
      element1.classList.add("log-in-srn");

      var element2 = document.getElementById("otp-srn");
      element2.classList.remove("otp-srn");
    },

    nxtRegi() {
      var element1 = document.getElementById("otp-srn");
      element1.classList.add("otp-srn");

      var element2 = document.getElementById("regi-srn");
      element2.classList.remove("regis-srn");
    },
  },
};
</script>
