var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"fixedRitLogin"}),_c('div',{staticClass:"index-banner"},[_c('Header'),_vm._m(0)],1),_c('div',{staticClass:"login-cat"},[_c('div',{staticClass:"in-login-cat d-flex justify-content-between"},[_c('router-link',{staticClass:"cat-list text-center",attrs:{"to":{ name: 'E Library' }}},[_c('img',{attrs:{"src":require("../../src/assets/img/icons/library.png"),"alt":""}}),_c('h5',[_vm._v("e-Library")])]),_c('router-link',{staticClass:"cat-list text-center",attrs:{"to":{ name: 'Subscription' }}},[_c('img',{attrs:{"src":require("../../src/assets/img/icons/subscription.png"),"alt":""}}),_c('h5',[_vm._v("Subscription")])]),_c('router-link',{staticClass:"cat-list text-center",attrs:{"to":{ name: 'View Result' }}},[_c('img',{attrs:{"src":require("../../src/assets/img/icons/test.png"),"alt":""}}),_c('h5',[_vm._v("Test Result")])]),_c('router-link',{staticClass:"cat-list text-center",attrs:{"to":{ name: 'Live Sessions' }}},[_c('img',{attrs:{"src":require("../../src/assets/img/icons/live-session.png"),"alt":""}}),_c('h5',[_vm._v("Live sessions")])]),(this.ebox == 1)?_c('button',{staticClass:"cat-list text-center border-0 bg-transparent",attrs:{"type":"button"},on:{"click":function($event){return _vm.eboxNavi()}}},[_c('img',{attrs:{"src":require("../../src/assets/img/web-development.png"),"alt":""}}),_c('h5',[_vm._v("Coding")])]):_vm._e()],1)]),_c('div',{staticClass:"ind-test-cat"},[_vm._m(1),_c('div',{staticClass:"row"},[_vm._l((_vm.categories),function(category,index){return _c('router-link',{key:index,staticClass:"col-md-3 col-6 p-2",attrs:{"to":{
          name: 'Placement Prep',
          params: { categoryName: category.name, categoryId: category.id },
        }}},[_c('div',{staticClass:"ind-test-cat-container text-center d-grid"},[_c('div',{staticClass:"m-auto"},[_c('img',{attrs:{"src":category.image,"alt":""}}),_c('p',[_vm._v(_vm._s(category.name))])])])])}),_c('router-link',{staticClass:"col-md-3 col-6 p-2",attrs:{"to":{ name: 'Recruitment List' }}},[_c('div',{staticClass:"ind-test-cat-container text-center d-grid"},[_c('div',{staticClass:"m-auto"},[_c('img',{attrs:{"src":require("../../src/assets/img/categories/placement-prep.png"),"alt":""}}),_c('p',[_vm._v("Recruitment Drives")])])])])],2)]),_c('section',[_c('div',{staticClass:"ind-daily-chal"},[_c('div',{staticClass:"w-80"},[_c('div',{staticClass:"row"},[_vm._m(2),(_vm.dailyChallengeEnable)?_c('div',{staticClass:"col-12 col-md-6 m-auto"},[_c('div',{staticClass:"daily-chal-cont text-center"},[_c('h2',[_vm._v("Daily Challenge")]),_c('p',{staticClass:"cont-p"},[_vm._v(" "+_vm._s(_vm.dailyChallenge.description)+" ")]),_c('div',{staticClass:"time-slat d-flex justify-content-center"},[_c('div',{staticClass:"ques-hrs"},[_c('h3',[_vm._v(_vm._s(_vm.dailyChallenge.no_of_questions))]),_c('p',[_vm._v("Questions")])]),_c('div',{staticClass:"ques-hrs-lin"}),_c('div',{staticClass:"ques-hrs"},[_c('h3',[_vm._v(_vm._s(_vm.dailyChallenge.duration))]),_c('p',[_vm._v("Minutes")])])]),_c('router-link',{staticClass:"acpt-chal",attrs:{"to":{
                  name: 'Daily Challenge',
                  params: { testType: 'daily_test' },
                }}},[_vm._v("Accept Challenge")])],1)]):_c('div',{staticClass:"col-12 col-md-6 m-auto text-center"},[_c('h2',[_vm._v("Daily challenges are not available at this moment.")])])])])])]),(_vm.isAllIndia)?_c('section',{staticClass:"ind-mock"},[_c('div',{staticClass:"w-80"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-4 col-lg-5 m-auto"},[_c('div',{staticClass:"ind-mock-img"},[_c('h4',[_vm._v(_vm._s(_vm.months[_vm.month - 1])),_c('br'),_vm._v(_vm._s(_vm.day))])])]),_c('div',{staticClass:"col-12 col-md-8 col-lg-7 m-auto mock-t"},[_c('h4',{attrs:{"id":"headline"}},[_vm._v("All India Mock Test")]),_c('div',{attrs:{"id":"countdown"}},[_vm._m(3),_c('div',{staticClass:"d-flex justify-content-center"},[_c('router-link',{staticClass:"vw-noti",attrs:{"to":{
                  name: 'All India Mock Test',
                  params: {
                    testType: 'all_india_mock_test',
                    testId: _vm.allIndiaId ? _vm.allIndiaId : 0,
                  },
                }}},[_vm._v("View More")]),_c('button',{staticClass:"vw-noti",on:{"click":function($event){return _vm.notifyMe()}}},[_vm._v("Notify")])],1)]),_c('div',{staticClass:"message"},[_c('div',{attrs:{"id":"content"}},[_c('p',[_vm._v(" "+_vm._s(_vm.allIndiaMockTestName)+" ")]),_c('router-link',{staticClass:"acpt-chal1",attrs:{"to":{
                  name: 'All India Mock Test',
                  params: {
                    testType: 'all_india_mock_test',
                    testId: _vm.allIndiaId ? _vm.allIndiaId : 0,
                  },
                }}},[_vm._v("Accept Challenge")])],1)])])])])]):_vm._e(),_c('b-overlay',{staticClass:"position-fixed",attrs:{"show":_vm.loader,"no-wrap":"","blur":"2px","opacity":"0.60"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('i',{staticClass:"fa fa-spinner fa-pulse fa-4x fa-fw text-primary"})]},proxy:true}])}),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-content"},[_c('div',{staticClass:"banner-caption"},[_c('h1',[_vm._v("India's Leading Learning App")]),_c('div',{staticClass:"banner-btn mt-3"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-ind text-center mb-3"},[_c('h2',[_vm._v("Subscription plans")]),_c('img',{attrs:{"src":require("../../src/assets/img/line.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-6 m-auto"},[_c('div',{staticClass:"daily-chal-img"},[_c('img',{attrs:{"src":require("../../src/assets/img/daily-task.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"time-coun"},[_c('p',{staticClass:"time-coun-tim",attrs:{"id":"days"}}),_c('p',{staticClass:"time-coun-cont"},[_vm._v("days")])]),_c('div',{staticClass:"time-coun"},[_c('p',{staticClass:"time-coun-tim",attrs:{"id":"hours"}}),_c('p',{staticClass:"time-coun-cont"},[_vm._v("hours")])]),_c('div',{staticClass:"time-coun"},[_c('p',{staticClass:"time-coun-tim",attrs:{"id":"minutes"}}),_c('p',{staticClass:"time-coun-cont"},[_vm._v("minutes")])]),_c('div',{staticClass:"time-coun"},[_c('p',{staticClass:"time-coun-tim",attrs:{"id":"seconds"}}),_c('p',{staticClass:"time-coun-cont"},[_vm._v("seconds")])])])
}]

export { render, staticRenderFns }