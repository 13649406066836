<template>
  <div>
    <section
      class="other-banner"
      style="
        background: url('assets/img/test/banner.jpg') no-repeat;
        background-position: center;
      "
    >
      <Header />
      <div class="banner-content">
        <div class="banner-caption">
          <h1>Notification</h1>
          <div class="banner-btn mt-1">
            <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Notifications' }"
                    >Notification</router-link
                  >
                </li>
            </ol>
          </div>
          <h5 class="mt-2">Unread- <b>25</b></h5>
          <!-- <button class="btn-sub-trail mt-3">Edit</button> -->
        </div>
      </div>
    </section>
    <section class="tl-lst-tst">
      <div class="in-list-test" v-for="(notification, index) in notifications" :key="index">
        <router-link
          to=""
          class="list-test"
        >
          <div class="d-flex">
            <div class="lft-notif d-grid">
              <i class="fas fa-bell"></i>
            </div>
            <div class="lft-lst-tst flex-grow-1 ps-4">
              <h5>{{ notification.title }}</h5>
              <p>
                {{ notification.description }}
              </p>
              <p class="mt-1 text-primary"><i>{{ notification.created_at.split(" ")[0] }}, {{ notification.created_at.split(" ")[1] }}</i></p>
            </div>
          </div>
        </router-link>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  name: "Profile",
  components: {
    Header,
    Footer,
  },
  data() {
      return {
          notifications: []
      }
  },
  mounted() {
      this.getNotifications()
  },
  methods: {
    async getNotifications() {
      await axios
        .get(`${this.$store.getters.baseUrl}/user/notifications`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.notifications = response.data.notify.slice(-3);
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>