<template>
  <div>
    <section class="other-banner">
      <Header />
      <div class="banner-content">
        <div class="banner-caption">
          <h1>{{ $route.params.subscriptionName }}</h1>
          <div class="banner-btn mt-1 text-dark">
            <!--      <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
            <a class="lst-a mx-1">Placement Prep Practies Test</a> -->
            <nav class="flex-sm-00-auto ml-sm-3" aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-alt">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </li>
                <li
                  v-if="$route.params.categoryId"
                  class="breadcrumb-item"
                  aria-current="page"
                >
                  <router-link
                    :to="{
                      name: 'Placement Prep',
                      params: {
                        categoryId: $route.params.categoryId,
                        categoryName: $route.params.categoryName,
                      },
                    }"
                    >{{ $route.params.categoryName }}</router-link
                  >
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Subscription Description' }">{{
                    $route.params.subscriptionName
                  }}</router-link>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Subscription Mock Test List' }"
                    >Subscription Mock Test List</router-link
                  >
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>

    <section class="plac-pre-prac-tst mt-4 my-2">
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <div v-if="mockTests.length > 0">
            <section class="tl-lst-tst pt-0 pb-2">
              <router-link
                :to="{ name: 'Subscription Description' }"
                class="in-list-test"
                v-for="(mockTest, index) in mockTests"
                :key="index"
              >
                <div class="list-test">
                  <div class="d-flex">
                    <div class="lft-lst-tst flex-grow-1 pe-4">
                      <h5>{{ mockTest.name }}</h5>
                      <!-- <h4>
                                    <sapan>₹</sapan>652
                                </h4> -->
                      <p class="text-primary">
                        Duration: {{ mockTest.duration }} Mins
                      </p>
                      <div class="d-flex">
                        <router-link
                          :to="{
                            name: 'Subscription Mock Test Details',
                            params: {
                              testId: mockTest.id,
                              testType: 'subscription_mock_test',
                            },
                          }"
                          class="mt-2"
                        >
                          <p
                            v-if="$route.params.userSubscriptionStatus == 1"
                            class="viw-dwn h6 py-2 mt-2"
                            style="width: fit-content"
                          >
                            <b>View Details</b>
                          </p>
                        </router-link>
                        <p
                          v-if="$route.params.userSubscriptionStatus != 1"
                          class="lock-vid"
                        >
                          <i class="fas fa-lock" ahref="/subscription"></i>
                        </p>
                      </div>
                    </div>
                    <div class="rt-comp-img d-grid">
                      <img :src="mockTest.image" alt="" />
                    </div>
                  </div>
                </div>
              </router-link>
            </section>
          </div>
          <div v-else>
            <h5 class="mx-5">you have exhausted your attempt for this Test</h5>
          </div>
        </div>
      </div>
    </section>
    <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      mockTests: [],
      loader: false,
    };
  },
  created() {
    this.getMockTests();
  },
  methods: {
    async getMockTests() {
      this.loader = true;
      const formdata = new FormData();
      formdata.append("subscription_id", this.$route.params.subscriptionId);

      await axios
        .post(
          `${this.$store.getters.baseUrl}/subscription-mock-tests?type=web`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data.status == "success") {
            this.mockTests = response.data.mock_tests;
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          // console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
