<template>
  <div>
    <section
      class="other-banner"
      style="
        background: url('../../src/assets/img/test/banner.jpg') no-repeat;
        background-position: center;
        min-height: auto;
      "
    >
      <Header />
    </section>
    <section class="d-flex justify-content-center p-3">
      <aside class="my-auto" style="width: 430px">
        <h5>{{ dailyChallenge.description }}</h5>
        <br />
        <table class="table table-bordered text-center">
          <thead>
            <tr>
              <th class="tit-text" scope="col">Section</th>
              <th class="tit-text" scope="col">Questions</th>
              <th class="tit-text" scope="col">Duration (Mins)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(topic, index) in topics" :key="index">
              <th scope="row">{{ topic.topic_name }}</th>
              <td>{{ topic.no_of_questions }}</td>
              <td>{{ topic.duration }}</td>
            </tr>
          </tbody>
        </table>
        <article class="detail-test-dur">
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">Duration</p>
            <p class="py-1">: {{ dailyChallenge.duration }} Mins</p>
          </div>
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">No. of Questions</p>
            <p class="py-1">: {{ dailyChallenge.no_of_questions }}</p>
          </div>
          <!-- <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">No. of Attempts</p>
            <p class="py-1">: {{ dailyChallenge.attempts }}</p>
          </div> -->
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">Each Question Mark</p>
            <p class="py-1">: {{ dailyChallenge.each_question_mark }}</p>
          </div>
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">Negative Mark</p>
            <p class="py-1">: {{ dailyChallenge.negative_mark }}</p>
          </div>
        </article>
        <router-link
          :to="{
            name: 'Questions',
            params: {
              type: 'practice-test',
              testId: dailyChallenge.id ? dailyChallenge.id : 0,
              submitId: dailyChallenge.id ? dailyChallenge.id : 0,
            },
          }"
          class="a-radious mt-2"
          >Start Test</router-link
        >
      </aside>
      <aside class="my-auto mx-5">
        <img
          style="width: 380px"
          src="../../src/assets/img/test/test-img.png"
          alt=""
        />
      </aside>
    </section>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      dailyChallenge: {},
      topics: [],
    };
  },
  mounted() {
    this.getPracticeTestDetails();
  },
  methods: {
    async getPracticeTestDetails() {
      const formdata = new FormData();
      formdata.append("sub_id", this.$route.params.subId);
      formdata.append("sub_topic_id", this.$route.params.subTopicId);

      await axios
        .post(
          `${this.$store.getters.baseUrl}/subscription/sub_tests`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
         // console.log(response);
          if (response.data.status == "success") {
            localStorage.setItem(
              "testExpiry",
              response.data.test_detail.end_date_time
            );
            this.dailyChallenge = response.data.test_detail;
            this.topics = response.data.topic_detail;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>