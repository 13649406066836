import vue from 'vue'
import axios from 'axios'
import store from '../store/index'
import router from '../router/index'

export default async (to, from, next) => {  
    if (from.name == "Practice Test Details" || from.name == "Subscription Mock Test Details" || from.name == "Daily Challenge"|| from.name =="All India Mock Test" || from.name == null) {
        console.log("valid from route")
        // checking test page is refreshed
        if (window.location.pathname.split("/")[1] != "questions") {
            // checking DB exists or not
            if (
                (await window.indexedDB.databases())
                    .map((db) => db.name)
                    .includes("QuestionsDB")
            ) {
                // 1. DB open
                let DBOpenReq = indexedDB.open("QuestionsDB", 1);
                DBOpenReq.addEventListener("error", (err) => {
                    console.log(err);
                });

                DBOpenReq.addEventListener("success", async (ev) => {
                    let db = ev.target.result;
                    console.log("got db", db);
                    let tx = db.transaction("questions", "readonly");
                    tx.oncomplete = (ev) => {
                        console.log(ev);
                    };

                    tx.onerror = (err) => {
                        console.log(err);
                    };

                    let dbData = await tx.objectStore("questions");
                    let data = await dbData.get("finalObject");

                    data.onsuccess = async (ev) => {
                        console.log("got final object");
                        let finalObject = ev.target.result
                        var today = new Date();
                        var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                        var dateTime = date + ' ' + time;
                        console.log(dateTime);
                        console.log(finalObject);

                        // Check for practice test / null check (test expiry date)
                        if (finalObject.testExpiry != null) {
                            // check for test expiry
                            if (new Date(finalObject.testExpiry) < new Date(dateTime)) {
                                // Expired Test
                                // getting final object from DB and updating end test time with current time
                                console.log("expired");
                                let tx = db.transaction("questions", "readonly");
                                tx.oncomplete = (ev) => {
                                    console.log(ev);
                                };

                                tx.onerror = (err) => {
                                    console.log(err);
                                };

                                let dbData = await tx.objectStore("questions");
                                let data = await dbData.get("finalObject");

                                data.onsuccess = async (ev) => {
                                    console.log("expired, got finalobject");
                                    let tx1 = db.transaction("questions", "readwrite");
                                    tx1.oncomplete = (ev) => {
                                        console.log(ev);
                                    };

                                    tx1.onerror = (err) => {
                                        console.log(err);
                                    };

                                    var today = new Date();

                                    var date =
                                        today.getFullYear() +
                                        "-" +
                                        (today.getMonth() + 1) +
                                        "-" +
                                        today.getDate();

                                    var time =
                                        today.getHours() +
                                        ":" +
                                        today.getMinutes() +
                                        ":" +
                                        today.getSeconds();

                                    var dateTime = date + " " + time;
                                    let dataToUpdate = ev.target.result;
                                    dataToUpdate.end_date_time = dateTime;

                                    let dbData1 = await tx1.objectStore("questions");
                                    let data1 = await dbData1.put(dataToUpdate);
                                    // final object updated with test end time 
                                    data1.onsuccess = async (ev) => {
                                        console.log("expired, got final object, updated test end time", ev);
                                        let tx = db.transaction("questions", "readonly");
                                        tx.oncomplete = (ev) => {
                                            console.log(ev);
                                        };

                                        tx.onerror = (err) => {
                                            console.log(err);
                                        };

                                        let dbData = await tx.objectStore("questions");
                                        let data = await dbData.get("finalObject");

                                        // Got final object to hit the API
                                        data.onsuccess = async (ev) => {
                                            console.log("expired, got final object, updated test end time, got final object to hit API");
                                            let final = ev.target.result;
                                            const formdata = new FormData();
                                            formdata.append("test_id", final.test_id);
                                            formdata.append("start_date_time", final.start_date_time);
                                            formdata.append("end_date_time", final.end_date_time);

                                            for (var i = 0; i < final.topic.length; i++) {
                                               // let source_code = source_code;
                                                formdata.append(
                                                    "topic[" + i + "][topic_id]",
                                                    final.topic[i].topic_id
                                                );
                                                formdata.append(
                                                    "topic[" + i + "][no_of_questions]",
                                                    final.topic[i].no_of_questions
                                                );

                                                for (var j = 0; j < final.topic[i].questions.length; j++) {
                                                    formdata.append(
                                                        "topic[" + i + "][questions][" + j + "][question_id]",
                                                        final.topic[i].questions[j].question_id
                                                    );
                                                    formdata.append(
                                                        "topic[" + i + "][questions][" + j + "][question_type]",
                                                        final.topic[i].questions[j].question_type
                                                    );
                                                    formdata.append(
                                                        "topic[" + i + "][questions][" + j + "][option_id]",
                                                        final.topic[i].questions[j].option_id
                                                    );
                                                    formdata.append(
                                                        "topic[" + i + "][questions][" + j + "][user_answer]",
                                                        final.topic[i].questions[j].user_answer
                                                    );
                                                    formdata.append(
                                                      "topic[" + i + "][questions][" + j + "][language]",
                                                      final.topic[i].questions[j].language
                                                    );
                                                    formdata.append(
                                                      "topic[" + i + "][questions][" + j + "][source_code]",
                                                      final.topic[i].questions[j].source_code
                                                    );
                                                    if(final.topic[i].questions[j].source_code != null){
                                                      formdata.append(
                                                        "topic[" + i + "][questions][" + j + "][user_answer]",
                                                        final.topic[i].questions[j].source_code
                                                      );
                                                    }
                                                }
                                            }
                                            // Hiting API
                                            await axios
                                                .post(
                                                    `${store.getters.baseUrl}/mock-tests/submit-test`,
                                                    formdata,
                                                    {
                                                        headers: {
                                                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                                                        },
                                                    }
                                                )
                                                .then(async (response) => {
                                                    console.log("API success");
                                                    if (response.data.status == "success") {
                                                        var req = indexedDB.deleteDatabase("QuestionsDB");
                                                        window.location.reload();
                                                        req.onsuccess = function (e) {
                                                            console.log("Deleted database successfully", e);
                                                            console.log(response.data.message);
                                                            next()
                                                        };
                                                        req.onblocked = async function (e) {
                                                            console.log("blocked", e);
                                                            await db.close();
                                                        };
                                                        req.onerror = function (e) {
                                                            console.log("Couldn't delete database", e);
                                                        };

                                                    } else {
                                                        vue.swal("Error", response.data.message, "warning");
                                                    }
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    vue.swal("Error", error.response.data.message, "error");
                                                });
                                        }
                                    }

                                    data1.onerror = (err) => {
                                        console.log(err);
                                    }
                                }

                                data.onerror = (err) => {
                                    console.log(err);
                                }

                            } else {

                                
                               /* if(finalObject.w_attempt >= 3){

                                    var req = indexedDB.deleteDatabase("QuestionsDB")
                                    window.location.reload();
                                    req.onsuccess = async function (e) {
                                        console.log("Deleted database successfully", e);
                                        router.push({ name: "Home" });
                                        vue.swal("Success", "yOUR eXISTING tEST hAS bEEN cLEARED", "success");
                                    };


                                }else{

                                    console.log("update checking");

                                    let tx = await db.transaction("questions", "readonly");
                                    tx.oncomplete = (ev) => {
                                        console.log(ev);
                                    };

                                    tx.onerror = (err) => {
                                        console.log(err);
                                    };

                                    let dbDataQuestions = await tx.objectStore("questions");
                                    let dbfinalObject = await dbDataQuestions.get("finalObject");

                                    dbfinalObject.onsuccess = async (ev) => {
                                        // updating final object with test end date
                                        let txtest = await db.transaction("questions", "readwrite");
                                        txtest.oncomplete = (ev) => {
                                            console.log(ev);
                                        };

                                        txtest.onerror = (err) => {
                                            console.log(err);
                                        };

                                        let finalOUpdate = ev.target.result;
                                        finalOUpdate.w_attempt += 1;

                                        let dbQ = await txtest.objectStore("questions");
                                        let saved = await dbQ.put(finalOUpdate);

                                        saved.onsuccess = async () => {

                                            console.log("attemp increased");
                                        }

                                        saved.onerror = async () => {

                                            console.log("some error occured attemp increased");
                                        }
                                    }
                                } */

                                // Not Expired - swal asking, continue or submit the test
                                vue.swal({
                                    title: "Resume Existing Test",
                                    icon: "warning",
                                    text: "We could notice an incomplete test remaining, Do you want to continue with the incomplete test or submit the incomplete test before entering the new test",
                                    confirmButtonText: "Yes, Continue",
                                    cancelButtonText: "No, Submit",
                                    showCancelButton: true,
                                    reverseButtons: true,
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                })
                                    .then(async (result) => {
                                        // Test continue
                                        if (result.isConfirmed) {
                                            next()
                                        }
                                        // Test submit
                                        // Getting final object to update test end time before submit
                                        if (result.isDismissed) {
                                            let tx = await db.transaction("questions", "readonly");
                                            tx.oncomplete = (ev) => {
                                                console.log(ev);
                                            };

                                            tx.onerror = (err) => {
                                                console.log(err);
                                            };

                                            let dbData = await tx.objectStore("questions");
                                            let data = await dbData.get("finalObject");
                                            // Updating final object
                                            data.onsuccess = async (ev) => {
                                                let tx1 = await db.transaction("questions", "readwrite");
                                                tx1.oncomplete = (ev) => {
                                                    console.log(ev);
                                                };

                                                tx1.onerror = (err) => {
                                                    console.log(err);
                                                };

                                                var today = new Date();

                                                var date =
                                                    today.getFullYear() +
                                                    "-" +
                                                    (today.getMonth() + 1) +
                                                    "-" +
                                                    today.getDate();

                                                var time =
                                                    today.getHours() +
                                                    ":" +
                                                    today.getMinutes() +
                                                    ":" +
                                                    today.getSeconds();

                                                var dateTime = date + " " + time;
                                                let dataToUpdate = ev.target.result;
                                                dataToUpdate.end_date_time = dateTime;

                                                let dbData1 = await tx1.objectStore("questions");
                                                let data1 = await dbData1.put(dataToUpdate);

                                                // Getting test end time updated final object before hitting API
                                                data1.onsuccess = async () => {
                                                    let tx = await db.transaction("questions", "readonly");
                                                    tx.oncomplete = (ev) => {
                                                        console.log(ev);
                                                    };

                                                    tx.onerror = (err) => {
                                                        console.log(err);
                                                    };

                                                    let dbData = await tx.objectStore("questions");
                                                    let data = await dbData.get("finalObject");

                                                    // Hitting API
                                                    data.onsuccess = async (ev) => {
                                                        let final = await ev.target.result;
                                                        const formdata = new FormData();
                                                        formdata.append("test_id", final.test_id);
                                                        formdata.append("start_date_time", final.start_date_time);
                                                        formdata.append("end_date_time", final.end_date_time);
                                                        for (var i = 0; i < final.topic.length; i++) {
                                                           // let source_code = source_code;
                                                            formdata.append(
                                                                "topic[" + i + "][topic_id]",
                                                                final.topic[i].topic_id
                                                            );
                                                            formdata.append(
                                                                "topic[" + i + "][no_of_questions]",
                                                                final.topic[i].no_of_questions
                                                            );
                                                            for (var j = 0; j < final.topic[i].questions.length; j++) {
                                                                formdata.append(
                                                                    "topic[" + i + "][questions][" + j + "][question_id]",
                                                                    final.topic[i].questions[j].question_id
                                                                );
                                                                formdata.append(
                                                                    "topic[" + i + "][questions][" + j + "][question_type]",
                                                                    final.topic[i].questions[j].question_type
                                                                );
                                                                formdata.append(
                                                                    "topic[" + i + "][questions][" + j + "][option_id]",
                                                                    final.topic[i].questions[j].option_id
                                                                );
                                                                formdata.append(
                                                                    "topic[" + i + "][questions][" + j + "][user_answer]",
                                                                    final.topic[i].questions[j].user_answer
                                                                );
                                                                formdata.append(
                                                                  "topic[" + i + "][questions][" + j + "][language]",
                                                                  final.topic[i].questions[j].language
                                                                );
                                                                formdata.append(
                                                                  "topic[" + i + "][questions][" + j + "][source_code]",
                                                                  final.topic[i].questions[j].source_code
                                                                );
                                                                if(final.topic[i].questions[j].source_code != null){
                                                                  formdata.append(
                                                                    "topic[" + i + "][questions][" + j + "][user_answer]",
                                                                    final.topic[i].questions[j].source_code
                                                                  );
                                                                }
                                                            }
                                                        }

                                                        await axios
                                                            .post(
                                                                `${store.getters.baseUrl}/mock-tests/submit-test`,
                                                                formdata,
                                                                {
                                                                    headers: {
                                                                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                                                                    },
                                                                }
                                                            )
                                                            .then(async (response) => {
                                                                console.log("API success");
                                                                if (response.data.status == "success") {
                                                                    var req = indexedDB.deleteDatabase("QuestionsDB");
                                                                    window.location.reload();
                                                                    req.onsuccess = function (e) {
                                                                        console.log("Deleted database successfully", e);
                                                                        router.push({ name: "Home" });
                                                                        vue.swal("Success", response.data.message, "success");
                                                                    };
                                                                    req.onblocked = async function (e) {
                                                                        console.log("blocked", e);
                                                                        await db.close();
                                                                    };
                                                                    req.onerror = function (e) {
                                                                        console.log("Couldn't delete database", e);
                                                                    };

                                                                } else {
                                                                    vue.swal("Error", response.data.message, "warning");
                                                                }
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                                vue.swal("Error", error.response.data.message, "error");
                                                            });
                                                    }

                                                    data.onerror = (err) => {
                                                        console.log(err);
                                                    }
                                                }

                                                data1.onerror = (err) => {
                                                    console.log(err);
                                                }

                                            }

                                            data.onerror = (err) => {
                                                console.log(err);
                                            }
                                        }
                                    })
                            }
                            // Practice test logic
                        } else {
                            
                          /*  if(finalObject.w_attempt >= 3){

                                var req1 = indexedDB.deleteDatabase("QuestionsDB")
                                window.location.reload();
                                req1.onsuccess = async function (e) {
                                    console.log("Deleted database successfully", e);
                                    router.push({ name: "Home" });
                                    vue.swal("Success", "yOUR eXISTING tEST hAS bEEN cLEARED", "success");
                                };


                            }else{

                                console.log("update checking");

                                let tx = await db.transaction("questions", "readonly");
                                tx.oncomplete = (ev) => {
                                    console.log(ev);
                                };

                                tx.onerror = (err) => {
                                    console.log(err);
                                };

                                let dbDataQuestions = await tx.objectStore("questions");
                                let dbfinalObject = await dbDataQuestions.get("finalObject");

                                dbfinalObject.onsuccess = async (ev) => {
                                    // updating final object with test end date
                                    let txtest = await db.transaction("questions", "readwrite");
                                    txtest.oncomplete = (ev) => {
                                        console.log(ev);
                                    };

                                    txtest.onerror = (err) => {
                                        console.log(err);
                                    };

                                    let finalOUpdate = ev.target.result;
                                    finalOUpdate.w_attempt += 1;

                                    let dbQ = await txtest.objectStore("questions");
                                    let saved = await dbQ.put(finalOUpdate);

                                    saved.onsuccess = async () => {

                                        console.log("attemp increased");
                                    }

                                    saved.onerror = async () => {

                                        console.log("some error occured attemp increased");
                                    }
                                }
                            } */
                            // Swal asking user to continue or submit te existing test
                            vue.swal({
                                title: "Resume Existing Test",
                                icon: "warning",
                                text: "We could notice an incomplete test remaining, Do you want to continue with the incomplete test or submit the incomplete test before entering the new test",
                                confirmButtonText: "Yes, Continue",
                                cancelButtonText: "No, Submit",
                                showCancelButton: true,
                                reverseButtons: true,
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            })
                                .then(async (result) => {
                                    // Continue 
                                    if (result.isConfirmed) {
                                        next()
                                    }
                                    // Submit
                                    if (result.isDismissed) {
                                        // getting final object to update test end time 
                                        let tx = await db.transaction("questions", "readonly");
                                        tx.oncomplete = (ev) => {
                                            console.log(ev);
                                        };

                                        tx.onerror = (err) => {
                                            console.log(err);
                                        };

                                        let dbData = await tx.objectStore("questions");
                                        let data = await dbData.get("finalObject");

                                        data.onsuccess = async (ev) => {
                                            // updating final object with test end date
                                            let tx1 = await db.transaction("questions", "readwrite");
                                            tx1.oncomplete = (ev) => {
                                                console.log(ev);
                                            };

                                            tx1.onerror = (err) => {
                                                console.log(err);
                                            };

                                            var today = new Date();

                                            var date =
                                                today.getFullYear() +
                                                "-" +
                                                (today.getMonth() + 1) +
                                                "-" +
                                                today.getDate();

                                            var time =
                                                today.getHours() +
                                                ":" +
                                                today.getMinutes() +
                                                ":" +
                                                today.getSeconds();

                                            var dateTime = date + " " + time;
                                            let dataToUpdate = ev.target.result;
                                            dataToUpdate.end_date_time = dateTime;

                                            let dbData1 = await tx1.objectStore("questions");
                                            let data1 = await dbData1.put(dataToUpdate);

                                            // Getting the updated final object to hit the API
                                            data1.onsuccess = async () => {
                                                let tx = await db.transaction("questions", "readonly");
                                                tx.oncomplete = (ev) => {
                                                    console.log(ev);
                                                };

                                                tx.onerror = (err) => {
                                                    console.log(err);
                                                };

                                                let dbData = await tx.objectStore("questions");
                                                let data = await dbData.get("finalObject");

                                                // Hitting API
                                                data.onsuccess = async (ev) => {
                                                    let final = await ev.target.result;
                                                    const formdata = new FormData();
                                                    formdata.append("test_id", final.test_id);
                                                    formdata.append("start_date_time", final.start_date_time);
                                                    formdata.append("end_date_time", final.end_date_time);
                                                    for (var i = 0; i < final.topic.length; i++) {
                                                       // let source_code = source_code;
                                                        formdata.append(
                                                            "topic[" + i + "][topic_id]",
                                                            final.topic[i].topic_id
                                                        );
                                                        formdata.append(
                                                            "topic[" + i + "][no_of_questions]",
                                                            final.topic[i].no_of_questions
                                                        );
                                                        for (var j = 0; j < final.topic[i].questions.length; j++) {
                                                            formdata.append(
                                                                "topic[" + i + "][questions][" + j + "][question_id]",
                                                                final.topic[i].questions[j].question_id
                                                            );
                                                            formdata.append(
                                                                "topic[" + i + "][questions][" + j + "][question_type]",
                                                                final.topic[i].questions[j].question_type
                                                            );
                                                            formdata.append(
                                                                "topic[" + i + "][questions][" + j + "][option_id]",
                                                                final.topic[i].questions[j].option_id
                                                            );
                                                            formdata.append(
                                                                "topic[" + i + "][questions][" + j + "][user_answer]",
                                                                final.topic[i].questions[j].user_answer
                                                            );
                                                            formdata.append(
                                                              "topic[" + i + "][questions][" + j + "][language]",
                                                              final.topic[i].questions[j].language
                                                            );
                                                            formdata.append(
                                                              "topic[" + i + "][questions][" + j + "][source_code]",
                                                              final.topic[i].questions[j].source_code
                                                            );
                                                            if(final.topic[i].questions[j].source_code != null){
                                                              formdata.append(
                                                                "topic[" + i + "][questions][" + j + "][user_answer]",
                                                                final.topic[i].questions[j].source_code
                                                              );
                                                            }
                                                        }
                                                    }

                                                    await axios
                                                        .post(
                                                            `${store.getters.baseUrl}/mock-tests/submit-test`,
                                                            formdata,
                                                            {
                                                                headers: {
                                                                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                                                                },
                                                            }
                                                        )
                                                        .then(async (response) => {
                                                            console.log("API success");
                                                            if (response.data.status == "success") {
                                                                await db.close()
                                                                var req = indexedDB.deleteDatabase("QuestionsDB")
                                                                window.location.reload();
                                                                req.onsuccess = async function (e) {
                                                                    console.log("Deleted database successfully", e);
                                                                    router.push({ name: "Home" });
                                                                    vue.swal("Success", response.data.message, "success");
                                                                };
                                                                req.onblocked = async function (e) {
                                                                    console.log("blocked", e);
                                                                };
                                                                req.onerror = function (e) {
                                                                    console.log("Couldn't delete database", e);
                                                                };
                                                            } else {
                                                                vue.swal("Error", response.data.message, "warning");
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                            vue.swal("Error", error.response.data.message, "error");
                                                        });
                                                }

                                                data.onerror = (err) => {
                                                    console.log(err);
                                                }
                                            }

                                            data1.onerror = (err) => {
                                                console.log(err);
                                            }
                                        }

                                        data.onerror = (err) => {
                                            console.log(err);
                                        }
                                    }
                                })
                        }
                    }

                    data.onerror = (err) => {
                        console.log(err);
                    }
                })

            } else {
                next()
            }
        } else {
            next()
        }
    } else {
        router.push({ name: "Home" });
        console.log("invalid from route")
    }
}


