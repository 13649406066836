<template>
  <div>
    <section class="other-banner">
      <Header />
      <div class="banner-content">
        <div class="banner-caption">
          <h1>Discussion</h1>
          <div class="banner-btn mt-1">
            <!--          <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
            <a class="lst-a mx-1">Discussion</a> -->
            <nav class="flex-sm-00-auto ml-sm-3" aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-alt">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Discussion' }"
                    >Discussion</router-link
                  >
                </li>
              </ol>
            </nav>
          </div>
          <!-- <h5 class="mt-2">Unread- <b>25</b></h5> -->
          <!-- <button class="btn-sub-trail mt-3">Edit</button> -->
        </div>
      </div>
    </section>

    <section class="plac-pre-prac-tst my-2 mt-3">
      <ul class="nav nav-tabs plac-prep-nav-link" id="myTab" role="tablist">
        <li
          class="nav-item"
          role="presentation"
          v-for="(topic, index) in topics"
          :key="index"
        >
          <button
            @click="(topicId = topic.id), getDiscussions()"
            class="nav-link"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            {{ topic.name }}
          </button>
        </li>
      </ul>

      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <section class="tl-lst-tst pt-1">
            <div
              :style="
                discussion.approval != 1 ? 'background-color: #f0f0f0' : ''
              "
              :to="
                discussion.approval == 1
                  ? {
                      name: 'Discussion Threads',
                      params: { discussionId: discussion.id },
                    }
                  : ''
              "
              v-for="(discussion, index) in discussions"
              :key="index"
            >
              <div class="list-test">
                <div class="d-flex">
                  <div class="lft-notif d-grid">
                    <img :src="discussion.user.image" alt="" />
                  </div>
                  <div class="lft-lst-tst flex-grow-1 ps-4">
                    <h5>{{ discussion.user.name }}</h5>
                    <p>
                      {{ discussion.query }}
                    </p>
                    <div class="d-flex justify-content-between">
                      <router-link
                        :to="
                          discussion.approval == 1
                            ? {
                                name: 'Discussion Threads',
                                params: { discussionId: discussion.id },
                              }
                            : ''
                        "
                        class="mt-1 text-primary"
                        ><i>View More</i></router-link
                      >
                      <p class="mt-1">
                        <i>{{ discussion.created_at }}</i>
                      </p>
                      <button
                        style="btn-cmd"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal1"
                        @click="setDiscussion(discussion.id)"
                      >
                        Comment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>

    <button
      class="chat-icon"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
    >
      <i class="fas fa-comments"></i>
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered post-ques">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h5 class="modal-title" id="exampleModalLabel">Post Question</h5>
            <button
              type="button"
              class="btn-close"
              id="closeModal"
              @click="closeModal()"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <textarea
              name=""
              id=""
              cols="55"
              rows="3"
              aria-roledescription=""
              v-model="question"
              placeholder="Enter your Question"
            ></textarea>

            <textarea
              name=""
              id=""
              cols="55"
              rows="3"
              aria-roledescription=""
              v-model="description"
              placeholder="Enter your Description"
            ></textarea>
            <div style="color: green" v-if="imageStatus == true">
              Image uploaded successfully
            </div>
          </div>
          <div class="modal-footer border-0">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="closeModal()"
            >
              Cancel
            </button>
            <label class="cameraButton">
              Upload Image
              <input
                type="file"
                accept="image/*;capture=camera"
                @change="imagePost($event)"
              />
            </label>
            <button type="button" id="post" class="btn" @click="addPost()">
              Post
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- discuss modal -->
    <div
      class="modal fade"
      id="exampleModal1"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered post-ques">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h5 class="modal-title" id="exampleModalLabel">Post Reply</h5>
            <button
              type="button"
              class="btn-close"
              id="closeModal1"
              @click="closeModal1()"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
    
            <textarea
              name=""
              id=""
              cols="55"
              rows="3"
              aria-roledescription=""
              v-model="thread_description"
              placeholder="Reply..."
            ></textarea>
            <div style="color: green" v-if="imageStatus1 == true">
              Image uploaded successfully
            </div>
          </div>
          <div class="modal-footer border-0">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="closeModal1()"
            >
              Cancel
            </button>
            <label class="cameraButton">
              Upload Image
              <input
                type="file"
                accept="image/*;capture=camera"
                @change="imagePost1($event)"
              />
            </label>
            <button type="button" id="post" class="btn" @click="addPostDiscussion()">
              Post
            </button>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  name: "Profile",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      topics: [],
      topicId: 0,
      discussions: [],
      image: [],
      imageStatus: false,
      question: "",
      description: "",
      thread_description:"",
      thread_image: [],
      imageStatus1: false,
      loader: false,
      discussion_id:"",
    };
  },

  mounted: async function() {
    await this.getDiscussionTopics();
    this.getDiscussions();
  },
  methods: {

    setDiscussion(id){
      this.discussion_id = id;
    },

    async addPost() {
      const formdata = new FormData();
      formdata.append("topic_id", this.topicId);
      if (this.imageStatus == true) {
        formdata.append("image", this.image);
      }
      formdata.append("description", this.description);
      formdata.append("query", this.question);
      await axios
        .post(`${this.$store.getters.baseUrl}/discussions/store`, formdata, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.$swal("Success", "Question posted successfully", "success");
            document.getElementById("closeModal").click();
            this.getDiscussions();
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
     async addPostDiscussion() {
      const formdata = new FormData();
      formdata.append("discussion_id", this.discussion_id);
      if (this.imageStatus1 == true) {
        formdata.append("image", this.thread_image);
      }
      formdata.append("reply", this.thread_description);
      await axios
        .post(`${this.$store.getters.baseUrl}/discussions/threads`, formdata, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.$swal("Success", "Replied successfully", "success");
            document.getElementById("closeModal1").click();
            this.getDiscussions();
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    closeModal() {
      (this.question = ""), (this.description = ""), (this.image = []);
    },
    closeModal1() {
      (this.thread_description = ""), (this.thread_image = []);
    },
    imagePost(event) {
      this.imageStatus = false;
      this.image = event.target.files[0];
      this.image ? (this.imageStatus = true) : (this.imageStatus = false);
    },
     imagePost1(event) {
      this.imageStatus1 = false;
      this.thread_image = event.target.files[0];
      this.thread_image ? (this.imageStatus1 = true) : (this.imageStatus1 = false);
    },
    async getDiscussionTopics() {
      this.loader = true;
      await axios
        .get(`${this.$store.getters.baseUrl}/discussion-topics`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.topics = response.data.topics;
            this.topicId = this.topics[0].id;
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    async getDiscussions() {
      this.loader = true;
      const formdata = new FormData();
      formdata.append("topic_id", this.topicId);
      await axios
        .post(`${this.$store.getters.baseUrl}/discussions/lists`, formdata, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.discussions = [
              ...response.data.discussions,
              ...response.data.awaiting_discussions,
            ];
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>

<style scoped>
#post {
  background-color: #0530ba;
  color: #fff;
}

#post:hover {
  background-color: #021b79;
}

textarea {
  resize: none;
  border-radius: 5px;
  border-color: #cfd2dc;
}
.lft-lst-tst {
  color: #000 !important;
}
::placeholder {
  color: grey;
  opacity: 1; /* Firefox */
}

label.cameraButton {
  display: inline-block;

  /* Styles to make it look like a button */
  padding: 0.375rem 0.75rem;
  border: 1px solid transparent;
  color: #fff;
  background-color: #0530ba;
  right: 30px;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  user-select: none;
}

label.cameraButton:hover {
  background-color: #021b79;
}
.btn-cmd {
  background: none;
  color: #000;
  border: 0;
}
/* Look like a clicked/depressed button */
label.cameraButton:active {
  border-color: #ccc #eee #eee #ccc;
}

/* This is the part that actually hides the 'Choose file' text box for camera inputs */
label.cameraButton input[accept*="camera"] {
  display: none;
}

.post-ques textarea {
  resize: none;
  border-radius: 2px;
  width: 100%;
  height: 70px;
  border: 1px solid #c5c5c5;
  margin-bottom: 25px;
  padding: 7px;
}
</style>
