<template>
  <div class="d-sm-flex lo-g-in-page">
    <div class="col-12 col-sm-6 col-md-7 col-lg-8 p-0">
      <div class="lft-log-in d-grid">
        <img
          class="m-auto"
          src="../../src/assets/img/login/login-illus-1.svg"
          alt=""
        />
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-5 col-lg-4 p-0 tl-rit-login">
      <div class="main-log-in">
        <div class="rt-log-in d-grid">
          <div class="rt-cont">
            <img
              class="ab-logo mb-3"
              src="../../src/assets/img/ab-logo.png"
              alt=""
            />
            <h5 class="p-welcom"><span>Registration</span></h5>
            <p>Sign up to enjoy awsome learning experience!</p>
            <form class="material-form-design">
              <div class="group-material">
                <input
                  type="text"
                  v-model="name"
                  @focus="nameEmpty = false"
                  required
                />
                <span class="highlight"></span>
                <span class="bar"></span>
                <div style="color: red" v-if="nameEmpty">Name is required</div>
                <label>Name </label>
              </div>
              <div class="group-material">
                <input
                  type="text"
                  v-model="email"
                  @focus="emailEmpty = false"
                  required
                />
                <span class="highlight"></span>
                <span class="bar"></span>
                <div style="color: red" v-if="emailEmpty">
                  Email is required
                </div>
                <label>Email </label>
              </div>
              <div class="group-material">
                <input
                  type="text"
                  v-model="collegeName"
                  @focus="collegeNameEmpty = false"
                  required
                />
                <span class="highlight"></span>
                <span class="bar"></span>
                <div style="color: red" v-if="collegeNameEmpty">
                  college name is required
                </div>
                <label>Name of the college/Institute </label>
              </div>
              <div class="group-material">
                <select
                  class="bg-white"
                  v-model="state"
                  @input="stateEmpty = false"
                  required
                >
                  <option
                    :value="state.id"
                    v-for="(state, index) in states"
                    :key="index"
                  >
                    {{ state.name }}
                  </option>
                </select>
                <div style="color: red" v-if="stateEmpty">
                  State is required
                </div>
              </div>
              <p class="refer-code" @click="referCode()">
                Do you have <span>referral code?</span>
              </p>
              <div class="group-material refer-code-show" id="refer_code">
                <input
                  v-model="referralCode"
                  type="number"
                  @keyup="referralValidation()"
                />
                <span class="highlight"></span>
                <span class="bar"></span>
                <div v-if="invalidReferral" id="refer"></div>
                <label>Referal Code</label>
              </div>
            </form>
          </div>
        </div>

        <div class="submit-btn-login">
          <router-link to="" class="submit-btn-login">
            <button class="submit-btn" @click="register()" v-if="!hideApplyNow">Apply Now</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      hideApplyNow: false,
      states: [],
      referralCode: "",
      name: "",
      nameEmpty: false,
      email: "",
      emailEmpty: false,
      collegeName: "",
      collegeNameEmpty: false,
      state: "",
      stateEmpty: false,
      mobileNumber: "",
      invalidReferral: false,
    };
  },
  mounted() {
    this.getStates();
    this.fetchmobilenumber();
  },

  methods: {
    async referralValidation() {
      if (this.referralCode.length == 0) {
        this.hideApplyNow = false
        document.getElementById("refer").innerHTML = "";
        return
      }
      this.invalidReferral = true;
      const formdata = new FormData();
      formdata.append("mobile", this.referralCode);
      await axios
        .post(`${this.$store.getters.baseUrl}/referral_code`, formdata)
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.hideApplyNow = false
            document.getElementById("refer").innerHTML =
              "This is a valid referral number";
            document.getElementById("refer").style.color = "green";
          } else {
            this.hideApplyNow = true
            document.getElementById("refer").innerHTML =
              "This is not a valid referral number";
            document.getElementById("refer").style.color = "red";
          }
        })
        .catch((error) => {
          console.log(error);
          this.hideApplyNow = true
          document.getElementById("refer").innerHTML =
            "This is not a valid referral number";
          document.getElementById("refer").style.color = "red";
        });
    },

    fetchmobilenumber() {
      this.mobileNumber = localStorage.getItem("mobile");
    },
    async register() {
      if (!this.mobileNumber) {
        alert("no mobile number");
        return;
      }

      if (!this.name) {
        this.nameEmpty = true;
        return;
      }

      if (!this.email) {
        this.emailEmpty = true;
        return;
      }

      if (!this.state) {
        this.stateEmpty = true;
        return;
      }

      if (!this.collegeName) {
        this.collegeNameEmpty = true;
        return;
      }
      const formdata = new FormData();
      formdata.append("mobile", this.mobileNumber);
      formdata.append("name", this.name);
      formdata.append("email", this.email);
      formdata.append("state_id", this.state);
      formdata.append("college_name", this.collegeName);
      if (this.referralCode.length > 0) {
        formdata.append("referral_mobile", this.referralCode);
      }
      await axios
        .post(`${this.$store.getters.baseUrl}/register`, formdata)
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            let userDetails = response.data.user;
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
            let token = response.data.token;
            localStorage.setItem("token", token);
            this.$router.push({ name: "Home" });
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    async getStates() {
      await axios
        .get(`${this.$store.getters.baseUrl}/states`)
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.states = response.data.states;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    referCode() {
      this.referralCode.length != 0 ? this.hideApplyNow = true : this.hideApplyNow = false
      var referrer = document.getElementById("refer_code");
      referrer.classList.remove("refer-code-show");
    },
  },
};
</script>