<template>
  <div>
    <section
      class="other-banner"
      style="
        background: url('../../src/assets/img/test/banner.jpg') no-repeat;
        background-position: center;
        min-height: auto;
      "
    >
      <Header />
      <!-- <div class="banner-content">
        <div class="banner-caption">
            <h1>Profile</h1>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Error quaerat alias beatae laboriosam nobis
                tempore
                harum eos architecto illo laborum iure deleniti sunt</p>
            <div class="banner-btn mt-1">
                <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
                <a class="lst-a mx-1">Profile</a>
            </div>
            <button class="btn-sub-trail mt-3">Edit</button>
        </div>
    </div> -->
    </section>
    <section class="d-flex justify-content-center p-3">
      <aside class="my-auto" style="width: 430px">
        <h5>{{ dailyChallenge.description }}</h5>
        <br />
        <table class="table table-bordered text-center">
          <thead>
            <tr>
              <th class="tit-text" scope="col">Section</th>
              <th class="tit-text" scope="col">Questions</th>
              <th class="tit-text" scope="col">Duration (Mins)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(topic, index) in topics" :key="index">
              <th scope="row">{{ topic.topic_name }}</th>
              <td>{{ topic.no_of_questions }}</td>
              <td>{{ topic.duration }}</td>
            </tr>
          </tbody>
        </table>
        <article class="detail-test-dur">
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">Duration</p>
            <p class="py-1">: {{ dailyChallenge.duration }} Mins</p>
          </div>
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">No. of Questions</p>
            <p class="py-1">: {{ dailyChallenge.no_of_questions }}</p>
          </div>
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">Available Form</p>
            <p class="py-1">: {{ dailyChallenge.start_date_time }}</p>
          </div>
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">End By</p>
            <p class="py-1">: {{ dailyChallenge.end_date_time }}</p>
          </div>
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">No. of Attempts</p>
            <p class="py-1">: {{ dailyChallenge.attempts }}</p>
          </div>
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">Each Question Mark</p>
            <p class="py-1">: {{ dailyChallenge.each_question_mark }}</p>
          </div>
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">Negative Mark</p>
            <p class="py-1">: {{ dailyChallenge.negative_mark }}</p>
          </div>
        </article>
        <router-link
          :to="{
            name: 'Questions',
            params: {
              type: $route.params.testType,
              testId: $route.params.testId,
              submitId: dailyChallenge.id ? dailyChallenge.id : 0,
            },
          }"
          class="a-radious mt-2"
          >Start Test</router-link
        >
      </aside>
      <aside class="my-auto mx-5">
        <img
          style="width: 380px"
          src="../../src/assets/img/test/test-img.png"
          alt=""
        />
      </aside>
    </section>
    <!--start Loader -->
            <b-overlay :show="loader" no-wrap blur="2px" opacity="0.60" class="position-fixed">
          <template #overlay>
            <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
          </template>
        </b-overlay>
        <!-- End Loader -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      dailyChallenge: {},
      topics: [],
      loader: false,
    };
  },

  mounted() {
    this.getDailyChallenge();
  },
  methods: {
    async getDailyChallenge() {
      const formdata = new FormData();
      formdata.append("type", this.$route.params.testType);
      formdata.append("test_or_drive_id", this.$route.params.testId);
      await axios
        .post(
          `${this.$store.getters.baseUrl}/mock-tests/test-detail`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            localStorage.setItem("testExpiry", response.data.test_detail.end_date_time)
            this.dailyChallenge = response.data.test_detail;
            this.topics = response.data.topic_detail;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>