<template>
  <div class="home">
    <div class="fixedRitLogin">
      <!-- <div>
        <button
          class="btn-btn mb-3 border-0 bg-transparent"
          type="button"
          @click="eboxNavi()"
        >
          <img src="../../src/assets/img/code.png" alt="" />
          <img src="../../src/assets/img/eboxlogoTop.png" alt="" /> 
        </button>
      </div> -->
    </div>
    <div class="index-banner">
      <Header />
      <div class="banner-content">
        <div class="banner-caption">
          <h1>India's Leading Learning App</h1>
          <!-- <p>
            Get Trainned From The Market Experts
          </p> -->
          <div class="banner-btn mt-3">
            <!--       <router-link
              :to="{
                name: loggedIn ? 'All India Mock Test' : 'Login',
                params: loggedIn
                  ? {
                      testType: 'all_india_mock_test',
                      testId: allIndiaId ? allIndiaId : 0,
                    }
                  : '',
              }"
              class="btn-a me-3"
              >Trail Free</router-link
            > 

            <router-link :to="{ name: 'Login' }" id="loginButton"
              ><button class="btn-a">Login</button></router-link
            > -->
          </div>
        </div>
      </div>
    </div>
    <div class="login-cat">
      <div class="in-login-cat d-flex justify-content-between">
        <router-link :to="{ name: 'E Library' }" class="cat-list text-center">
          <img src="../../src/assets/img/icons/library.png" alt="" />
          <h5>e-Library</h5>
        </router-link>
        <router-link
          :to="{ name: 'Subscription' }"
          class="cat-list text-center"
        >
          <img src="../../src/assets/img/icons/subscription.png" alt="" />
          <h5>Subscription</h5>
        </router-link>
        <router-link :to="{ name: 'View Result' }" class="cat-list text-center">
          <img src="../../src/assets/img/icons/test.png" alt="" />
          <h5>Test Result</h5>
        </router-link>
        <router-link
          :to="{ name: 'Live Sessions' }"
          class="cat-list text-center"
        >
          <img src="../../src/assets/img/icons/live-session.png" alt="" />
          <h5>Live sessions</h5>
        </router-link>
        <button
          v-if="this.ebox == 1"
          class="cat-list text-center border-0 bg-transparent"
          type="button"
          @click="eboxNavi()"
        >
          <img src="../../src/assets/img/web-development.png" alt="" />
          <h5>Coding</h5>
        </button>
      </div>
    </div>

    <div class="ind-test-cat">
      <div class="tit-ind text-center mb-3">
        <h2>Subscription plans</h2>
        <img src="../../src/assets/img/line.png" alt="" />
      </div>

      <div class="row">
        <router-link
          v-for="(category, index) in categories"
          :key="index"
          :to="{
            name: 'Placement Prep',
            params: { categoryName: category.name, categoryId: category.id },
          }"
          class="col-md-3 col-6 p-2"
        >
          <div class="ind-test-cat-container text-center d-grid">
            <div class="m-auto">
              <img :src="category.image" alt="" />
              <p>{{ category.name }}</p>
            </div>
          </div>
        </router-link>
        <router-link
          :to="{ name: 'Recruitment List' }"
          class="col-md-3 col-6 p-2"
        >
          <div class="ind-test-cat-container text-center d-grid">
            <div class="m-auto">
              <img
                src="../../src/assets/img/categories/placement-prep.png"
                alt=""
              />
              <p>Recruitment Drives</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <section>
      <div class="ind-daily-chal">
        <div class="w-80">
          <div class="row">
            <div class="col-12 col-md-6 m-auto">
              <div class="daily-chal-img">
                <img src="../../src/assets/img/daily-task.png" alt="" />
              </div>
            </div>
            <div class="col-12 col-md-6 m-auto" v-if="dailyChallengeEnable">
              <div class="daily-chal-cont text-center">
                <h2>Daily Challenge</h2>
                <p class="cont-p">
                  {{ dailyChallenge.description }}
                </p>
                <div class="time-slat d-flex justify-content-center">
                  <div class="ques-hrs">
                    <h3>{{ dailyChallenge.no_of_questions }}</h3>
                    <p>Questions</p>
                  </div>
                  <div class="ques-hrs-lin"></div>
                  <div class="ques-hrs">
                    <h3>{{ dailyChallenge.duration }}</h3>
                    <p>Minutes</p>
                  </div>
                </div>
                <router-link
                  :to="{
                    name: 'Daily Challenge',
                    params: { testType: 'daily_test' },
                  }"
                  class="acpt-chal"
                  >Accept Challenge</router-link
                >
              </div>
            </div>
            <div class="col-12 col-md-6 m-auto text-center" v-else>
              <h2>Daily challenges are not available at this moment.</h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="ind-mock" v-if="isAllIndia">
      <div class="w-80">
        <div class="row">
          <div class="col-12 col-md-4 col-lg-5 m-auto">
            <div class="ind-mock-img">
              <h4>{{ months[month - 1] }}<br />{{ day }}</h4>
            </div>
          </div>
          <div class="col-12 col-md-8 col-lg-7 m-auto mock-t">
            <h4 id="headline">All India Mock Test</h4>
            <div id="countdown">
              <div>
                <div class="time-coun">
                  <p class="time-coun-tim" id="days"></p>
                  <p class="time-coun-cont">days</p>
                </div>
                <div class="time-coun">
                  <p class="time-coun-tim" id="hours"></p>
                  <p class="time-coun-cont">hours</p>
                </div>
                <div class="time-coun">
                  <p class="time-coun-tim" id="minutes"></p>
                  <p class="time-coun-cont">minutes</p>
                </div>
                <div class="time-coun">
                  <p class="time-coun-tim" id="seconds"></p>
                  <p class="time-coun-cont">seconds</p>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <router-link
                  class="vw-noti"
                  :to="{
                    name: 'All India Mock Test',
                    params: {
                      testType: 'all_india_mock_test',
                      testId: allIndiaId ? allIndiaId : 0,
                    },
                  }"
                  >View More</router-link
                >
                <button class="vw-noti" @click="notifyMe()">Notify</button>
              </div>
            </div>
            <div class="message">
              <div id="content">
                <p>
                  {{ allIndiaMockTestName }}
                </p>
                <router-link
                  class="acpt-chal1"
                  :to="{
                    name: 'All India Mock Test',
                    params: {
                      testType: 'all_india_mock_test',
                      testId: allIndiaId ? allIndiaId : 0,
                    },
                  }"
                  >Accept Challenge</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="ind-rank-det">
      <h3 class="text-center">Top 5 Holders</h3>
      <div class="row">
        <div class="box-rank p-1">
          <div class="box-img">
            <img src="../../src/assets/img/student/1.jpg" alt="" />
          </div>
          <div class="box-cont">
            <h4>Dhanya</h4>
            <p>Rank: 1</p>
            <h5>All India Mock test</h5>
          </div>
        </div>
        <div class="box-rank p-1">
          <div class="box-img">
            <img src="../../src/assets/img/student/5.jpg" alt="" />
          </div>
          <div class="box-cont">
            <h4>Dhanya</h4>
            <p>Rank: 1</p>
            <h5>All India Mock test</h5>
          </div>
        </div>
        <div class="box-rank p-1">
          <div class="box-img">
            <img src="../../src/assets/img/student/3.jpg" alt="" />
          </div>
          <div class="box-cont">
            <h4>Dhanya</h4>
            <p>Rank: 1</p>
            <h5>All India Mock test</h5>
          </div>
        </div>
        <div class="box-rank p-1">
          <div class="box-img">
            <img src="../../src/assets/img/student/4.jpg" alt="" />
          </div>
          <div class="box-cont">
            <h4>Abdul kamal michel raj</h4>
            <p>Rank: 1</p>
            <h5>All India Mock test</h5>
          </div>
        </div>
        <div class="box-rank p-1">
          <div class="box-img">
            <img src="../../src/assets/img/student/2.jpg" alt="" />
          </div>
          <div class="box-cont">
            <h4>Dhanya Sekar Narayanan</h4>
            <p>Rank: 1</p>
            <h5>All India Mock test</h5>
          </div>
        </div>
      </div>
    </section> -->
    <!--start Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import image from "@/assets/img/categories/company.png";
import axios from "axios";
export default {
  name: "Home",
  data() {
    return {
      allIndiaId: localStorage.getItem("allIndiaId")
        ? localStorage.getItem("allIndiaId")
        : 0,
      isAllIndia: localStorage.getItem("allIndiaStatus") == 1 ? true : false,
      image,
      loggedIn: localStorage.getItem("token") ? true : false,
      categories: [],
      e_libraryEnable: 0,
      dailyChallengeEnable: 0,
      dailyChallenge: {},
      allIndiaMockTestDetails: {},
      allIndiaMockTestName: "",
      month: "",
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      day: "",
      x: null,
      loader: false,
      ebox: "",
    };
  },
  components: {
    Header,
    Footer,
  },
  created() {
    //this.getDailyChallenge();
    this.ebox = localStorage.getItem("ebox");
  },
  mounted: async function() {
    await this.getHomeContents();
    //this.loginButtonEnabled();
    await this.getDailyChallenge();
    this.isAllIndia ? this.count() : "";
    this.menuBar();
  },
  methods: {
    async eboxNavi() {
      const formdata = new FormData();
      formdata.append("mobile", localStorage.getItem("mobile"));

      await axios
        .post(`${this.$store.getters.baseUrl}/check-ebox`, formdata, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response?.data?.status == "success") {
            let jsonParser = JSON.parse(response?.data?.response);
            // console.log("jsonParser", jsonParser);
            if (jsonParser) {
              let code_url = jsonParser?.data?.url;
              let new_url = code_url.replace(
                "https://pro.e-box.co.in/",
                "https://coding.aptitudebuster.com/"
              );
              // console.log("code_url", code_url);
              // console.log("new_url", new_url);
              if (new_url) {
                window.open(new_url, "_blank");
              } else {
                this.$swal("Error", jsonParser?.errorMessage, "error");
              }
            }
          } else {
            this.$swal("Error", response?.data?.message, "warning");
          }
          console.log("response", response);
        })
        .catch((error) => {
          console.log("error", error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    async notifyMe() {
      const formdata = new FormData();
      formdata.append("test_id", localStorage.getItem("allIndiaId"));
      await axios
        .post(`${this.$store.getters.baseUrl}/user-tests-notifies`, formdata, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            if (
              response.data.message == "You will be notified on the test date."
            ) {
              this.$swal(
                "Success",
                "You will be notified on test date",
                "success"
              );
            } else
              this.$swal(
                "Success",
                "You will no longer receive any notification for this test",
                "success"
              );
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    async getDailyChallenge() {
      console.log("I am Loading");
      const formdata = new FormData();
      formdata.append("type", "daily_test");
      await axios
        .post(
          `${this.$store.getters.baseUrl}/mock-tests/test-detail`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response.data);
            this.dailyChallenge = response.data.test_detail;
            console.log(this.dailyChallenge);
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getHomeContents() {
      this.dailyChallengeEnable = localStorage.getItem("dailyTestStatus");
      await axios
        .get(`${this.$store.getters.baseUrl}/home_page?page_type=web`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.categories = response.data.categories;
            localStorage.setItem("library", response.data.enable_library);
            localStorage.setItem(
              "allIndiaStatus",
              response.data.all_india_mock_test ? 1 : 0
            );
            if (response.data.all_india_mock_test) {
              localStorage.setItem(
                "allIndiaId",
                response.data.all_india_mock_test.id
              );
              localStorage.setItem(
                "allIndiaName",
                response.data.all_india_mock_test.name
              );
              this.allIndiaMockTestName =
                response.data.all_india_mock_test.name;
              this.allIndiaMockTestDetails =
                response.data.all_india_mock_test.tests;
              this.month = this.allIndiaMockTestDetails.start_date_time.split(
                "-"
              )[1];
              for (var i = 0; i < this.month.length; i++) {
                if (this.month[0] == "0") {
                  this.month = this.month[1];
                }
              }
              this.day = this.allIndiaMockTestDetails.start_date_time
                .split("-")[2]
                .split(" ")[0];
            }
            localStorage.setItem(
              "dailyTestStatus",
              response.data.daily_test ? 1 : 0
            );
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    /*  loginButtonEnabled() {
      if (localStorage.getItem("userDetails")) {
        localStorage.getItem("userDetails").registered_status == 1
          ? document.getElementById("loginButton").setAttribute("hidden", true)
          : document
              .getElementById("loginButton")
              .setAttribute("hidden", false);
      }
    }, */

    menuBar() {
      var element = document.getElementById("bar-cont1");
      element.classList.toggle("bor-der1");

      element = document.getElementById("bar-cont2");
      element.classList.toggle("bor-der2");

      element = document.getElementById("bar-cont3");
      element.classList.toggle("bor-der3");

      var navFun = document.getElementById("nav-bar-coll");
      navFun.classList.toggle("nav-bar-coll-show");
    },

    count() {
      const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;
      var birthday = this.allIndiaMockTestDetails.start_date_time;
      var countDown = new Date(birthday).getTime();
      this.x = setInterval(function() {
        let now = new Date().getTime(),
          distance = countDown - now;

        (document.getElementById("days").innerText = Math.floor(
          distance / day
        )),
          (document.getElementById("hours").innerText = Math.floor(
            (distance % day) / hour
          )),
          (document.getElementById("minutes").innerText = Math.floor(
            (distance % hour) / minute
          )),
          (document.getElementById("seconds").innerText = Math.floor(
            (distance % minute) / second
          ));
        //do something later when date is reached
        if (distance < 0) {
          let headline = document.getElementById("headline"),
            countdown = document.getElementById("countdown"),
            content = document.getElementById("content");

          headline.innerText = "All India Mock Test";
          countdown.style.display = "none";
          content.style.display = "block";
          clearInterval(this.x);
        }
        //seconds
      }, 0);
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.x) clearInterval(this.x);
    next();
  },
};
</script>
