import Vue from "vue";
import VueRouter from "vue-router";
import hasElibraryPermission from "./ElibraryPermission";
import hasAllIndiaPermission from "./AllIndiaPermission";
import hasDailyTestPermission from "./DailyTestPermission";
import newTestPermission from "./NewTestPermission";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Otp from "../views/Otp.vue";
import Registration from "../views/Registration.vue";
import Profile from "../views/Profile.vue";
import Subscription from "../views/Subscription.vue";
import Subscription_Description from "../views/SubscriptionDescription.vue";
import Subscription_Video_List from "../views/SubscriptionVideoList.vue";
import Subscription_Practice_Test from "../views/SubscriptionPracticeTest.vue";
import Subscription_Mock_Test_List from "../views/SubscriptionMockTestList.vue";
import Subscription_Videos from "../views/SubscriptionVideos.vue";
import Questions from "../views/Questions.vue";
import Result from "../views/Result.vue";
import Solution from "../views/Solution.vue";
import View_Result from "../views/ViewResult.vue";
import About_Us from "../views/AboutUs.vue";
import Invite_Earn from "../views/InviteEarn.vue";
import Rate_Us from "../views/RateUs.vue";
import Placement_Prep from "../views/PlacementPrep.vue";
import Company_Specific_Module from "../views/CompanySpecificModule.vue";
import CSAT_Prep from "../views/CSATPrep.vue";
import Discussion from "../views/Discussion.vue";
import Daily_Challenge from "../views/DailyChallenge.vue";
import Subscription_Mock_Test_Details from "../views/SubscriptionMockTestDetails.vue";
import E_Library from "../views/E-Library.vue";
import Live_Sessions from "../views/LiveSessions.vue";
import Notifications from "../views/Notifications.vue";
import All_India_Mock_Test from "../views/AllIndiaMockTest.vue";
import Practice_Test_List from "../views/PracticeTestList.vue";
import Practice_Test_List_Final from "../views/PracticeTestListFinal.vue";
import Discussion_Threads from "../views/DiscussionThreads.vue";
import Recruitment_List from "../views/RecruitmentList.vue";
import Practice_Test_Details from "../views/PracticeTestDetails.vue";
import subscription_Payment_Pay from "../views/subscriptionPaymentPay.vue";
import payment_status from "../views/PaymentStatus.vue";
import faq from "../views/Faq.vue";
import Help_And_Support from "../views/HelpAndSupport.vue";
import test from "../views/test.vue";
import test2 from "../views/test2.vue";
import Pdf_View from "../views/PdfView.vue";
import compserview from "../views/testcompser.vue";
import Termsandpolicy from "../views/Termsandpolicy";

// FOR LOGIN
import LoginPermission from "./LoginPermission";

Vue.use(VueRouter);

// Router Configuration
const router = new VueRouter({
  linkActiveClass: "active",
  linkExactActiveClass: "",
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },

  routes: [
    {
      path: "/",
      component: Home,
      meta: {
        requiresAuth: true,
      },
      // children: [
      //   {
      //     path: "/login",
      //     name: "Login",
      //     component: Login,
      //   },
      // ],
    },
    {
      path: "/login",
      name: "Login",
      beforeEnter: LoginPermission,
      component: Login,
    },
    {
      path: "/terms-policy",
      name: "Terms and Policy",
      component: Termsandpolicy,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "*",
      name: "Home",
      component: Home,
    },
    {
      path: "/test",
      name: "Test",
      component: test,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/compserview",
      name: "Compserview",
      component: compserview,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/test2",
      name: "Test2",
      component: test2,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/otp",
      name: "Otp",
      beforeEnter: LoginPermission,
      component: Otp,
    },
    {
      path: "/registration",
      name: "Registration",
      component: Registration,
    },
    {
      path: "/home",
      name: "Home",
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/subscription",
      name: "Subscription",
      component: Subscription,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path:
        "/subscription-description/:subscriptionName/:subscriptionId/:categoryName/:categoryId",
      name: "Subscription Description",
      component: Subscription_Description,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path:
        "/subscription-video-list/:subscriptionName/:subscriptionId/:categoryName/:categoryId",
      name: "Subscription Video List",
      component: Subscription_Video_List,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/subscription-practice-test",
      name: "Subscription Practice Test",
      component: Subscription_Practice_Test,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path:
        "/subscription-mock-test-list/:subscriptionName/:subscriptionId/:userSubscriptionStatus/:categoryName/:categoryId",
      name: "Subscription Mock Test List",
      component: Subscription_Mock_Test_List,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/recruitment-list",
      name: "Recruitment List",
      component: Recruitment_List,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path:
        "/subscription-videos/:subscriptionId/:subtopicName/:subtopicId/:subscriptionName/:categoryName/:categoryId",
      name: "Subscription Videos",
      component: Subscription_Videos,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/questions/:type/:testId/:submitId",
      name: "Questions",
      beforeEnter: newTestPermission,
      component: Questions,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/result/:testId",
      name: "Result",
      component: Result,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/solution/:testId",
      name: "Solution",
      component: Solution,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/view-result",
      name: "View Result",
      component: View_Result,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/about-us",
      name: "About Us",
      component: About_Us,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/invite-earn",
      name: "Invite Earn",
      component: Invite_Earn,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/rate-us",
      name: "Rate Us",
      component: Rate_Us,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/category/:categoryName/:categoryId",
      name: "Placement Prep",
      component: Placement_Prep,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/company-specific-modules",
      name: "Company Specific Module",
      component: Company_Specific_Module,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/CSAT-Prep",
      name: "CSAT Prep",
      component: CSAT_Prep,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/discussion",
      name: "Discussion",
      component: Discussion,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/daily-challenge/:testType",
      name: "Daily Challenge",
      beforeEnter: hasDailyTestPermission,
      component: Daily_Challenge,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/test-details/:testType/:testId",
      name: "Subscription Mock Test Details",
      component: Subscription_Mock_Test_Details,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/practice-test-details/:subId/:subTopicId",
      name: "Practice Test Details",
      component: Practice_Test_Details,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/all-india-mock-test/:testType/:testId",
      name: "All India Mock Test",
      beforeEnter: hasAllIndiaPermission,
      component: All_India_Mock_Test,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/e-library",
      name: "E Library",
      beforeEnter: hasElibraryPermission,
      component: E_Library,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/live-sessions",
      name: "Live Sessions",
      component: Live_Sessions,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/notifications",
      name: "Notifications",
      component: Notifications,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path:
        "/practice-test-list/:subscriptionName/:subscriptionId/:categoryName/:categoryId",
      name: "Practice Test List",
      component: Practice_Test_List,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path:
        "/practice-tests/:subscriptionId/:topicName/:topicId/:subscriptionName/:categoryName/:categoryId",
      name: "Practice Tests",
      component: Practice_Test_List_Final,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/subscription-payment-pay/:data/:hash",
      name: "Subscription Payment Pay",
      component: subscription_Payment_Pay,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/discussion-threads/:discussionId",
      name: "Discussion Threads",
      component: Discussion_Threads,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/subscription/payment-status/:status",
      name: "Payment Status",
      component: payment_status,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/faq",
      name: "Faq",
      component: faq,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/help-and-support",
      name: "Help And Support",
      component: Help_And_Support,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/pdf-view/:pdf/:status",
      name: "Pdf View",
      component: Pdf_View,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const token = localStorage.getItem("token");
    if (token) {
      // User is authenticated, proceed to the route
      next();
    } else {
      // User is not authenticated, redirect to login
      next("/login");
    }
  } else {
    // Non-protected route, allow access
    next();
  }
});

export default router;

/*const routes = [

  {
    path: '/login',
    name: 'Login',
    component: Login,
  },


  {
    path: '/test',
    name: 'Test',
    component: test,
  },
  {
    path: '/compserview',
    name: 'Compserview',
    component: compserview,
  },
  {
    path: '/test2',
    name: 'Test2',
    component: test2,
  },
  {
    path: '/otp',
    name: 'Otp',
    component: Otp,
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: Subscription
  },
  {
    path: '/subscription-description/:subscriptionName/:subscriptionId/:categoryName/:categoryId',
    name: 'Subscription Description',
    component: Subscription_Description
  },
  {
    path: '/subscription-video-list/:subscriptionName/:subscriptionId/:categoryName/:categoryId',
    name: 'Subscription Video List',
    component: Subscription_Video_List
  },
  {
    path: '/subscription-practice-test',
    name: 'Subscription Practice Test',
    component: Subscription_Practice_Test
  },
  {
    path: '/subscription-mock-test-list/:subscriptionName/:subscriptionId/:userSubscriptionStatus/:categoryName/:categoryId',
    name: 'Subscription Mock Test List',
    component: Subscription_Mock_Test_List
  },
  {
    path: '/recruitment-list',
    name: 'Recruitment List',
    component: Recruitment_List
  },
  {
    path: '/subscription-videos/:subscriptionId/:subtopicName/:subtopicId/:subscriptionName/:categoryName/:categoryId',
    name: 'Subscription Videos',
    component: Subscription_Videos
  },
  {
    path: '/questions/:type/:testId/:submitId',
    name: 'Questions',
    beforeEnter: newTestPermission,
    component: Questions
  },
  {
    path: '/result/:testId',
    name: 'Result',
    component: Result
  },
  {
    path: '/solution/:testId',
    name: 'Solution',
    component: Solution
  },
  {
    path: '/view-result',
    name: 'View Result',
    component: View_Result
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: About_Us
  },
  {
    path: '/invite-earn',
    name: 'Invite Earn',
    component: Invite_Earn
  },
  {
    path: '/rate-us',
    name: 'Rate Us',
    component: Rate_Us
  },
  {
    path: '/category/:categoryName/:categoryId',
    name: 'Placement Prep',
    component: Placement_Prep
  },
  {
    path: '/company-specific-modules',
    name: 'Company Specific Module',
    component: Company_Specific_Module
  },
  {
    path: '/CSAT-Prep',
    name: 'CSAT Prep',
    component: CSAT_Prep
  },
  {
    path: '/discussion',
    name: 'Discussion',
    component: Discussion
  },
  {
    path: '/daily-challenge/:testType',
    name: 'Daily Challenge',
    beforeEnter: hasDailyTestPermission,
    component: Daily_Challenge
  },
  {
    path: '/test-details/:testType/:testId',
    name: 'Subscription Mock Test Details',
    component: Subscription_Mock_Test_Details
  },
  {
    path: '/practice-test-details/:subId/:subTopicId',
    name: 'Practice Test Details',
    component: Practice_Test_Details
  },
  {
    path: '/all-india-mock-test/:testType/:testId',
    name: 'All India Mock Test',
    beforeEnter: hasAllIndiaPermission,
    component: All_India_Mock_Test
  },
  {
    path: '/e-library',
    name: 'E Library',
    beforeEnter: hasElibraryPermission,
    component: E_Library
  },
  {
    path: '/live-sessions',
    name: 'Live Sessions',
    component: Live_Sessions
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications
  },
  {
    path: '/practice-test-list/:subscriptionName/:subscriptionId/:categoryName/:categoryId',
    name: 'Practice Test List',
    component: Practice_Test_List
  },
  {
    path: '/practice-tests/:subscriptionId/:topicName/:topicId/:subscriptionName/:categoryName/:categoryId',
    name: 'Practice Tests',
    component: Practice_Test_List_Final
  },
  {
    path: '/subscription-payment-pay/:data/:hash',
    name: 'Subscription Payment Pay',
    component: subscription_Payment_Pay
  },
  {
    path: '/discussion-threads/:discussionId',
    name: 'Discussion Threads',
    component: Discussion_Threads
  },
  {
    path: '/subscription/payment-status/:status',
    name: 'Payment Status',
    component: payment_status
  },
  {
    path: '/faq',
    name: 'Faq',
    component: faq
  },
  {
    path: '/help-and-support',
    name: 'Help And Support',
    component: Help_And_Support
  }, {
    path: '/pdf-view/:pdf/:status',
    name: 'Pdf View',
    component: Pdf_View
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router*/
