<template>
  <div>
    <section class="other-banner">
      <Header />
      <div class="banner-content"></div>
    </section>
    <div class="text-center" v-if="$route.params.status === 'success'">
      <p>Your Transation was success</p>
      <button class="btn-sub-trail me-2" @click="subscription()">
        Subscription
      </button>

      <p class="text-center" id="timer"></p>
    </div>

    <div class="text-center" v-if="$route.params.status === 'failure'">
      <p>Your Transation was Cancel or un sucess</p>
      <button class="btn-sub-trail me-2" @click="failure()">Home</button>

      <p class="text-center" id="timer"></p>
    </div>

    <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->

    <Footer />
    <!-- Subcrption Popup Starts -->
  </div>
  <!-- Subcrption Popup Ends -->
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
// import axios from "axios";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      subscriptionDetails: {},
      subscriptionSpecifications: [],
      paymentDetails: {},
      userSubscriptionStatus: "",
      loader: false,
    };
  },

  methods: {
    //Redirect Success

    async subscription() {
      this.$router.push({ name: "Subscription" });
    },
    async failure() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
