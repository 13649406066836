<template>
  <div>
    <section class="other-banner">
      <Header />
      <div class="banner-content">
        <div class="banner-caption">
          <h1>{{ $route.params.subtopicName }}</h1>
          <div class="banner-btn mt-1 text-dark">
            <!--           <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
            <a class="lst-a mx-1">Videos</a> -->
            <nav class="flex-sm-00-auto ml-sm-3" aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-alt">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </li>
                <li
                  v-if="$route.params.categoryName"
                  class="breadcrumb-item"
                  aria-current="page"
                >
                  <router-link :to="{ name: 'Placement Prep' }">{{
                    $route.params.categoryName
                  }}</router-link>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Subscription Description' }">{{
                    $route.params.subscriptionName
                  }}</router-link>
                </li>

                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Subscription Video List' }"
                    >Video List</router-link
                  >
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>

    <section class="plac-pre-prac-tst mt-4 my-2">
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <div
            style="position: relative; text-align: center; width:70%; margin-left:10%;"
            id="youtubeVideo"
            v-if="youtubeId"
            v-show="!show"
          >
            <div style="width:75%; margin-left:200px">
              <LazyYoutube
                ref="youtubeLazyVideo"
                :src="youtubeLink"
                aspect-ratio="16:9"
                thumbnail-quality="standard"
              />
            </div>
            <!-- <div style="position: absolute; top:0; left:0; width:100%; height:100%;"></div> -->
            <div
              style="position: absolute; top:0; left:0; width:75%; height:87%;margin-left:20%"
            ></div>
            <!-- <div
              style="position: absolute; bottom:0; right:0px; width:380px; height:50px;"
            ></div> -->
            <div
              style="position: absolute; bottom:0; right:0px; width:25%; height:10%;"
            ></div>
            <!-- v-show works with style="display:none;" -->

            <div
              class="buttons"
              style="position: absolute; bottom: -42px; left: 48%;"
            >
              <button
                class="play_btn"
                :class="{ play_btns: buttonCol == 1 }"
                @click="handleClick('playVideo', 'youtubeLazyVideo', 1)"
              >
                Play
              </button>
              <button
                class="pause_btn"
                :class="{ pause_btns: buttonCol == 2 }"
                @click="handleClick('pauseVideo', 'youtubeLazyVideo', 2)"
              >
                Pause
              </button>
              <button
                class="reset_btn"
                :class="{ reset_btns: buttonCol == 3 }"
                @click="handleClick('resetView', 'youtubeLazyVideo', 3)"
              >
                Reset
              </button>
            </div>
          </div>
          <section class="tl-lst-tst pt-0 video-tl-list">
            <div
              class="in-list-test text-secondary"
              v-for="(video, index) in videos"
              :key="index"
            >
              <div href="" class="list-test">
                <div class="d-flex">
                  <div class="lft-lst-tst flex-grow-1 pe-4">
                    <h5>{{ video.map_sub_videos.name }}</h5>
                    <p class="text-drak">
                      <b>{{ video.map_sub_videos.description }}</b>
                    </p>
                    <p
                      @click="
                        (youtubeId = video.map_sub_videos.youtube_id),
                          videosubmit(),
                          (show = 'show')
                      "
                      class="vw-btn"
                    >
                      Watch Videos
                    </p>
                    <div
                      class="modal fade video-pop-up"
                      id="vidoe-pop"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered m-auto">
                        <div class="modal-content bg-none">
                          <!--iframe width="560" height="315"
                                                src="https://www.youtube-nocookie.com/embed/B4GhLfnXcoQ" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></!--iframe> -->

                          <!-- <iframe width="100%" height="415"
                                                src="https://www.youtube-nocookie.com/embed/B4GhLfnXcoQ?controls=0"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { LazyYoutube } from "vue-lazytube";
import axios from "axios";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    LazyYoutube,
    //LazyVimeo,
  },
  data() {
    return {
      show: false,
      videos: [],
      youtubeId: "",
      youtubeLink: "https://www.youtube-nocookie.com/watch?v=" + this.youtubeId,
      buttonCol: 0,
    };
  },
  mounted() {
    //show: false,
    this.getVideos();
    // this.$refs['lazyVideo'].playVideo();
  },
  methods: {
    videosubmit() {
      (this.youtubeLink =
        "https://www.youtube-nocookie.com/embed/" + this.youtubeId),
        document.getElementById(`youtubeVideo`).show("show");
      console.log(this.youtubeLink);
    },
    handleClick(event, ref, col) {
      this.$refs[ref][event]();
      this.buttonCol = col;
    },
    handleSearch(e, platform) {
      if (platform === "youtube") this.youtubeLink = e.target.value;
      else this.vimeoLink = e.target.value;
    },
    async getVideos() {
      const formdata = new FormData();
      formdata.append("sub_id", this.$route.params.subscriptionId);
      formdata.append("sub_topic_id", this.$route.params.subtopicId);

      await axios
        .post(
          `${this.$store.getters.baseUrl}/subscription/sub_videos`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.videos = response.data.videos;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
<style>
h2 {
  margin-bottom: 0;
  margin-top: 2rem;
}
.input {
  padding: 5px;
  max-width: 720px;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 90%;
}

.buttons {
  margin-top: 25px;
}

.play_btn {
  background-color: dodgerblue;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: dodgerblue;
}

.play_btns {
  background-color: dodgerblue;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: dodgerblue;
  opacity: 0.5;
}

.pause_btn {
  background-color: green;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: green;
}

.pause_btns {
  background-color: green;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: green;
  opacity: 0.5;
}

.reset_btn {
  background-color: slategray;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: slategray;
}

.reset_btns {
  background-color: slategray;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: slategray;
  opacity: 0.5;
}
</style>
