<template>
  <div>
    <section class="other-banner">
      <Header />
      <div class="banner-content">
        <div class="banner-caption">
          <h1>Redirect to payment gateway dont Refresh</h1>

          <form
            type="hidden"
            action="https://secure.payu.in/_payment"
            method="post"
            id="payuForm"
          >
            <input
              type="hidden"
              name="key"
              v-model="this.$route.params.data.key"
            />
            <input
              type="hidden"
              name="hash"
              v-model="this.$route.params.hash"
            />
            <input
              type="hidden"
              name="txnid"
              v-model="this.$route.params.data.txnid"
            />
            <table>
              <tr>
                <td><b>Mandatory Parameters</b></td>
              </tr>
              <tr>
                <td>Amount:</td>
                <td>
                  <input
                    name="amount"
                    type="hidden"
                    v-model="this.$route.params.data.amount"
                  />
                </td>
                <td>First Name:</td>
                <td>
                  <input
                    type="hidden"
                    name="firstname"
                    id="firstname"
                    v-model="this.$route.params.data.firstname"
                  />
                </td>
                <td>
                  <input
                    type="hidden"
                    name="udf1"
                    id="udf1"
                    v-model="this.$route.params.data.udf1"
                  />
                </td>
                <td>
                  <input
                    type="hidden"
                    name="udf2"
                    id="udf2"
                    v-model="this.$route.params.data.udf2"
                  />
                </td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>
                  <input
                    type="hidden"
                    name="email"
                    id="email"
                    v-model="this.$route.params.data.email"
                  />
                </td>
                <td>Phone:</td>
                <td>
                  <input
                    type="hidden"
                    name="phone"
                    v-model="this.$route.params.data.phone"
                  />
                </td>
              </tr>

              <tr>
                <td>Product Info:</td>
                <td colspan="3">
                  <textarea
                    type="hidden"
                    name="productinfo"
                    v-model="this.$route.params.data.productinfo"
                  >
                  </textarea>
                </td>
              </tr>
              <tr>
                <td>Success URI:</td>
                <td colspan="3">
                  <input
                    type="hidden"
                    name="surl"
                    v-model="this.$route.params.data.surl"
                    size="64"
                  />
                </td>
              </tr>
              <tr>
                <td>Failure URI:</td>
                <td colspan="3">
                  <input
                    type="hidden"
                    name="furl"
                    v-model="this.$route.params.data.furl"
                    size="64"
                  />
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <input
                    type="hidden"
                    name="service_provider"
                    v-model="this.payu_paisa"
                    size="64"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="4"><input type="hidden" v-model="Submit" /></td>
              </tr>
            </table>
          </form>
        </div>
      </div>
      <Footer />
    </section>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      payu_paisa: "payu_paisa",
      Submit: "Submit",
    };
  },
  mounted: function() {
    this.method1(); //method1 will execute at pageload
  },
  methods: {
    method1: function() {
      console.log(this.$route.params.data);
      if (this.$route.params.hash == "" && this.$route.params.data.key == "") {
        return;
      }
      var payuForm = document.forms.payuForm;
      payuForm.submit();
    },
  },
};
</script>
