<template>
  <div>
    <section class="other-banner">
      <Header />
      <div class="banner-content">
        <div class="banner-caption">
          <!-- used `style="height: 100vh;"` because without it in the Firefox 89 and Chrome 91 (June 2021) the `vue-pdf-app` is not rendering on the page, just empty space without any errors (since `vue-pdf-app` does not have height and it is the top tag in the generated markup ) -->
          <!-- or you can just wrap `vue-pdf-app` in <div> tag and set height for it via CSS (like in `Script tag (unpkg)` example below) -->
          <div v-if="status_view == 1">
            <vue-pdf-app
              :config="config"
              style="height: 100vh;"
              :pdf="pdfView"
            >
            </vue-pdf-app>
          </div>
          <div v-else-if="status_view == 0">
            <vue-pdf-app
              :config="config"
              style="height: 100vh;"
              :pdf="pdfView"
            >
            </vue-pdf-app>
          </div> 
        <!--  <div className="my-3" id="IframeSiteVisitPanel" style="display: block;">
                                <iframe id="IframeSiteVisit" frameborder="0" marginheight="0" marginwidth="0" width="100%" scrolling="auto" height="550px" 
                                :src="pdfView">
                                </iframe>
                              </div>  -->
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";

export default {
  data() {
    return {
      pdfView: "",
      status_view: "",
      //pdfViewed: this.pdfView,
      // disable "Previous page" button
      config3: {
        toolbar: {
          toolbarViewerLeft: {
            previous: false,
          },
        },
      },

      // disable whole page navigation panel
      config2: {
        toolbar: {
          toolbarViewerLeft: false,
        },
      },

      // disable whole panel
      config: {
        toolbar: false,
      },
    };
  },
  mounted() {
    //show: false,
    this.getPdf();
    // this.$refs['lazyVideo'].playVideo();
  },
  methods: {
    async getPdf() {
      this.pdfView = this.$route.params.pdf;
      this.status_view = this.$route.params.status;
    },
  },

  components: {
    VuePdfApp,
    Header,
    Footer,
  },
};
</script>
