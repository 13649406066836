<template>
  <div>

    
    <section class="abts-page w-80 m-auto p-3">
      <!-- <div class="m-auto">
        <img style="width:280px;" src="../../src/assets/img/icons/rate-us.png" alt="">
    </div> -->
     <p class="p-welcom text-center"><span>Terms and conditions </span></p>
      <p>
       Welcome to Aptitude Buster website: <a href="">www.aptitudebuster.com</a> and / or Aptitude Buster Mobile App.</p>
       <p>
<p>
This privacy policy governs your use of the application ‘Aptitude Buster’ Mobile App ('Application'), <a href="">www.aptitudebuster.com </a>
('the Website') and the other associated applications, products, websites and services managed by the Company. Please read this privacy policy ('Policy') carefully before using the Application, Website, our services and products, along with the Terms of Use ('ToU') provided on the Application and the Website. Your use of the Website, Application, or services in connection with the Application, Website or products ('Services'), or registrations with us through any modes or usage of any products including through SD cards, tablets or other storage/transmitting device shall signify your acceptance of this Policy and your agreement to be legally bound by the same.</p>
<p>
If you do not agree with/accept any part thereof, you should discontinue using or accessing these services. Your use and/or continued use of the Website, Application or availing any of our products / services, as the case may be, amounts to express consent by you to the terms of this Terms of Use as well as other Aptitude Buster Policies. </p>
<p>

These Terms are being published in accordance with the provisions of the Information Technology Act, 2000 and the Information Technology (Intermediaries guidelines) Rules, 2011 thereunder, as amended from time to time.

</p>
<p>
By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are at the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this website, application or any of our products / services.

</p>
<p>
You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).

</p>
<p>

Each registration is for a single user only. You are not allowed to share your ID or give access to your account to anyone else.
      </p>
        <p class="p-welcom"><span>Payment - Terms and conditions </span></p>
   <h5 class="mb-3"> <b>
       (i) FREE TRIAL </b></h5>
        <p>
To encourage users to further our app usage, some of our courses have a few preview entities which can be watched without payment. In addition, a few practice tests can be taken before buying our subscription plans. The intent is to make interested and prospective learners gather user experience of ‘the app’ / ‘the website’. Aptitude Buster is under no obligation to deliver these features on a continuous basis and it may withdraw the content at its own will.
        </p>
 <h5 class="mb-3"> <b>
(ii) COURSE VALIDITY </b></h5>
       <p>
The user gets access to all the available entities for a definite period of time (marked as the validity of the course) on making a payment. Please note that for some courses, live classes comprise a significant part. Hence, content will be gradually unlocked as per the study plan provided along with the course. Please do note that the user will be allowed to access the course until the course validity. It is the sole responsibility of the user to utilize the contents of the course within the course validity. Aptitude Buster will not entertain any requests to alter/ extend the duration of a course for whatsoever reasons. However, the user can purchase again if he /she wishes to continue enjoying the benefits of the course. In such circumstance, the Company has the right to change modify, suspend, or discontinue and/or eliminate any aspect(s), features or functionality of the course or the Services as it deems fit at any time without notice.
       </p>
     <h5 class="mb-3"> <b>
(iii) PRICING </b></h5>
 <p>
Pricing of our courses and test series is subject to change over time and is under the sole discretion of Aptitude Buster. Any such change on the pricing, the decision of Aptitude Buster shall be final and it has no impact on any commitment / features on the past sales of the same product.
 </p>
  <h5 class="mb-3"> <b>
(iv) REFUNDS </b></h5>
<p>
Refunds: A user shall be entitled to a refund only if Aptitude Buster is unable to deliver his / her order. Please note that requests for refunds should be made to <a href="">support@aptitudebuster.com</a> within 7 days from the date of purchase. Any refund, if processed, will be done on a prorated basis, depending on the service already delivered by Aptitude Buster. Refunds will be done directly to the original payment mode within seven working days of finalisation of claims. The actual time it takes to reflect the money in user’s account might vary at his / her bank's end.
</p>
 <h5 class="mb-3"> <b>
(v) CANCELLATIONS</b></h5>
<p>
Cancellations: As a general rule, all sales made on Aptitude Buster are final and a user shall not be entitled to cancel his/ her order once he / she have received confirmation of the same. Aptitude Buster reserves the sole right to cancel any order as per its discretion in case (i) It is unable to deliver the order and/ or (ii) when any user tries to take advantage of its services which violates the Terms of Use.
</p>
<p>
Aptitude Buster will ensure that any communication on cancellation of an order or any applicable refund will be made within a reasonable period of time.
      </p>
       <p class="p-welcom"><span>User given information </span></p>
      <p>
       The Application/Website/Services/products obtains the information you provide when you download and register for the Application or Services or products. We may use the Information to contact you from time to time, to provide you with the Services, important information, required notices and marketing promotions. We will ask you when we need more information that personally identifies you or allows us to contact you. We will not differentiate between who is using the device to access the Application, Website or Services or products, so long as the log in/access credentials match with yours. In order to make the best use of the Application/Website/Services/products and enable your Information to be captured accurately on the Application/Website/Services/products, it is essential that you have logged in using your own credentials. You shall, at any time while using the Application/Services/products, also have an option to withdraw your consent given earlier to us to use such information. Such withdrawal of the consent is required to be sent in writing to us at the contact details provided in this Policy below. In such event, however, the Company fully reserves the right not to allow further usage of the Application or provide any Services/products thereunder to you. </p>
       <p>

In addition, the Application/products/Services may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers you use, and information about the way you use the Application/Services/products. As is true of most Mobile applications, we also collect other relevant information as per the permissions that you provide. We use an outside credit card processing company to bill you for goods and services. These companies do not retain, share, store or use personally identifiable information for any other purpose. </p>
<p>

We may use the collected Information to analyse trends, to conduct research, to administer the Application/Services and products, to learn about each user's learning patterns and movements around the Application/Services and products and to gather demographic information and usage behaviour about our user base as a whole. Aggregated and individual, anonymized and non-anonymized data may periodically be transmitted to external service providers to help us improve the Application, products and our Services.</p>
<p>

While using certain features on our website / app like the discussion forums and / or posting / sharing your personal information such as comments, messages, files, photos, such information will be available to all users, and will be in the public domain. All such sharing of information is done at your own risk. Kindly note if you disclose personal information on our forums, those may become publicly available. </p>
<p>

Using information such as study materials, content, services marks, trade names, trademarks and trade secrets including but not limited to the software, text, images, graphics, video, script and audio, contained in the Application, Website, Services and products are proprietary property of the Company (“Proprietary Information”). No such Proprietary Information may be reproduced, copied, downloaded, modified, uploaded, posted, republished, transmitted or distributed in any way without obtaining prior written permission from the Company and nothing on this Application or Website or Services shall be or products deemed to confer a license of or any other right, interest or title to or in any of the intellectual property rights belonging to the Company, to the User. You may own the medium on which the information, content or materials resides, but the Company shall at all times retain full and complete title to the information, content or materials and all intellectual property rights inserted by the Company on such medium. </p>
<p>

Aptitude Buster Pvt. Ltd is concerned about protecting the confidentiality of your Information. We provide physical, electronic, and procedural safeguards to protect Information we process and maintain. Yet be aware that, no security system can prevent all potential security breaches, though we endeavour to provide reasonable security for safeguarding such information. </p>
<p>

Your use of the services will comply with all applicable laws, rules and regulations. Any violation by You of the terms may result in immediate suspension or termination of Your Accounts apart from any legal remedy that the Company can avail. In such instances, the Company may also disclose Your Account Information if required by any Governmental or legal authority. Further, the Terms shall be subject to the exclusive jurisdiction of the competent courts located in Chennai and You hereby accede to and accept the jurisdiction of such courts. </p>
<p>
 
Aptitude Buster may block, restrict, disable, suspend or terminate your access to all or part of the free Services offered, at any time in Aptitude Buster’s sole discretion, without prior notice to you. All prices are subject to change without notice. Specifically, in case of prolonged inactivity, Aptitude Buster reserves the right to disable, deactivate or terminate a user's account. If you violate the Terms of Use, Aptitude Buster may at its sole discretion, block, restrict, disable, suspend or terminate the paid Services offered by it. </p>
<p>

The terms and conditions are bound to evolve as and when the company evolves. Hence, we advise you to review the terms and conditions regularly. Your use and/or continued use amounts to express consent by you to all the changes. The provisions of the Terms shall prevail, in the event of any discrepancy between the Terms and any other policies with respect to the Application or Website or Services or products. </p>

<p>

We make our best endeavors to provide You with an enriching experience. In case, You face any issues, please contact us at <a href="">support@aptitudebuster.com
</a>      </p>
    </section>

    <Footer />
  </div>
</template>

<script>
// import Header from "@/components/OnlyBlue.vue";
import Footer from "@/components/OnlyFooter.vue";
import backgroundUrl from "../../src/assets/img/banner.png";
export default {
  name: "Terms and Policy",
  components: {
    // Header,
    Footer,
  },
  data() {
    return {
      backgroundUrl,
    };
  },
};
</script>
<style>
.abts-page p {
    text-align: justify;
    font-size: 17px;
 
    margin-bottom: 12px;
}
</style>
