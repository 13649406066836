<template>
  <div>
    <section
      class="other-banner"
      style="
        background: url('../../src/assets/img/test/banner.jpg') no-repeat;
        background-position: center;
        min-height: auto;
      "
    >
      <Header />
      <!-- <div class="banner-content">
        <div class="banner-caption">
            <h1>Profile</h1>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Error quaerat alias beatae laboriosam nobis
                tempore
                harum eos architecto illo laborum iure deleniti sunt</p>
            <div class="banner-btn mt-1">
                <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
                <a class="lst-a mx-1">Profile</a>
            </div>
            <button class="btn-sub-trail mt-3">Edit</button>
        </div>
    </div> -->
    </section>
    <section class="d-flex justify-content-center p-3">
      <aside class="my-auto" style="width: 430px">
        <p style="color: #0530ba; font-size: large">
          <b>{{ testName }}</b>
        </p>
        <div class="design-descrip mb-3">
          <p style="font-size: large" v-if="daysLeft != ''">
            <b>{{ daysLeft }}</b> Days to go
          </p>
          <p style="font-size: large" v-else>Ongoing</p>
          <p style="font-size: large">
            {{ timeStart > 12 ? timeStart - 12 : timeStart }} :
            {{ timeStartMinutes }} {{ timeStart >= 12 ? "PM" : "AM" }} -
            {{ timeEnd > 12 ? timeEnd - 12 : timeEnd }} : {{ timeEndMinutes }}
            {{ timeEnd >= 12 ? "PM" : "AM" }}
          </p>
          <div class="float-right">
            <h4 class="m-auto" style="line-height: 30px">
              {{ months[month - 1] }}<br />{{ day }}
            </h4>
          </div>
        </div>
        <table class="table table-bordered text-center">
          <thead>
            <tr>
              <th class="tit-text" scope="col">Section</th>
              <th class="tit-text" scope="col">Questions</th>
              <th class="tit-text" scope="col">Duration (Mins)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(topic, index) in topicDetails" :key="index">
              <th scope="row">{{ topic.topic_name }}</th>
              <td>{{ topic.no_of_questions }}</td>
              <td>{{ topic.duration }}</td>
            </tr>
          </tbody>
        </table>
        <article class="detail-test-dur">
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">Duration</p>
            <p class="py-1">: {{ allIndiaMockTestDetails.duration }} mins</p>
          </div>
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">No. Of Questions</p>
            <p class="py-1">: {{ allIndiaMockTestDetails.no_of_questions }}</p>
          </div>
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">Available Form</p>
            <p class="py-1">: {{ allIndiaMockTestDetails.start_date_time }}</p>
          </div>
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">End By</p>
            <p class="py-1">: {{ allIndiaMockTestDetails.end_date_time }}</p>
          </div>
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">No. Of Attempts</p>
            <p class="py-1">: {{ allIndiaMockTestDetails.attempts }}</p>
          </div>
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">Each Question Mark</p>
            <p class="py-1">
              : {{ allIndiaMockTestDetails.each_question_mark }}
            </p>
          </div>
          <div class="d-flex justify-content-start">
            <p class="left-data-text py-1">Navigative Mark</p>
            <p class="py-1">: {{ allIndiaMockTestDetails.negative_mark }}</p>
          </div>
        </article>
        <router-link
          :to="{
            name: 'Questions',
            params: {
              type: $route.params.testType,
              testId: $route.params.testId,
              submitId: allIndiaMockTestDetails.id ? allIndiaMockTestDetails.id : 0,
            },
          }"
          :class="
            currentDateTime >= testStartDateTime
              ? 'a-radious mt-2 pe-auto'
              : 'a-radious mt-2 pe-none dull'
          "
        >
          Start Test
        </router-link>
      </aside>
      <aside class="my-auto mx-5">
        <img
          style="width: 380px"
          src="../../src/assets/img/test/test-img.png"
          alt=""
        />
      </aside>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      allIndiaMockTestDetails: {},
      testName: "",
      month: "",
      day: "",
      topicDetails: [],
      daysLeft: "",
      timeStart: 0,
      timeEnd: 0,
      currentDateTime: "",
      testStartDateTime: "",
      testEndDatetime: "",
      timeStartMinutes: "",
      timeEndMinutes: "",
    };
  },
  mounted() {
    this.getAllIndiaMockTest();
  },
  methods: {
    async getAllIndiaMockTest() {
      const formdata = new FormData();
      formdata.append("type", "all_india_mock_test");
      formdata.append("test_or_drive_id", localStorage.getItem("allIndiaId"));
      await axios
        .post(
          `${this.$store.getters.baseUrl}/mock-tests/test-detail`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.testName = localStorage.getItem("allIndiaName");
            this.allIndiaMockTestDetails = response.data.test_detail;
            this.topicDetails = response.data.topic_detail;
            this.month =
              this.allIndiaMockTestDetails.start_date_time.split("-")[1];
            for (var i = 0; i < this.month.length; i++) {
              if (this.month[0] == "0") {
                this.month = this.month[1];
              }
            }
            this.day = this.allIndiaMockTestDetails.start_date_time
              .split("-")[2]
              .split(" ")[0];
            console.log(new Date());
            console.log(
              new Date(String(this.allIndiaMockTestDetails.start_date_time))
            );
            if (
              new Date() <
                new Date(this.allIndiaMockTestDetails.start_date_time) &&
              new Date() < new Date(this.allIndiaMockTestDetails.end_date_time)
            ) {
              let currentDay = new Date();
              let testDay = new Date(
                String(this.allIndiaMockTestDetails.start_date_time)
              );
              const diffTime = Math.abs(testDay - currentDay);
              console.log(diffTime);
              const diffDays = Math.trunc(diffTime / (1000 * 60 * 60 * 24));
              this.daysLeft = diffDays;
            }
            this.timeStart = new Date(
              this.allIndiaMockTestDetails.start_date_time
            )
              .toLocaleTimeString()
              .split(":")[0];
            this.timeEnd = new Date(this.allIndiaMockTestDetails.end_date_time)
              .toLocaleTimeString()
              .split(":")[0];
            this.timeStart = Number(this.timeStart);
            this.timeEnd = Number(this.timeEnd);
            this.timeStartMinutes = new Date(
              this.allIndiaMockTestDetails.start_date_time
            )
              .toLocaleTimeString()
              .split(":")[1];
            this.timeEndMinutes = new Date(
              this.allIndiaMockTestDetails.end_date_time
            )
              .toLocaleTimeString()
              .split(":")[1];
            this.currentDateTime = new Date().getTime();
            this.testStartDateTime = new Date(
              this.allIndiaMockTestDetails.start_date_time
            ).getTime();
            this.testEndDatetime = new Date(
              this.allIndiaMockTestDetails.end_date_time
            ).getTime();
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>

<style scoped>
.dull {
  opacity: 0.5;
}
</style>