<template>
  <div>
    <Header />
    <section class="other-banner">
      <div class="banner-content">
        <div class="banner-caption">
          <h1>{{ $route.params.subscriptionName }}</h1>
          <div class="banner-btn mt-1 text-dark">
            <!--           <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
            <a class="lst-a mx-1">Placement Prep Practies Test</a> -->
            <nav class="flex-sm-00-auto ml-sm-3" aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-alt">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </li>
                <li
                  v-if="$route.params.categoryName"
                  class="breadcrumb-item"
                  aria-current="page"
                >
                  <router-link
                    :to="{
                      name: 'Placement Prep',
                      params: {
                        categoryId: $route.params.categoryId,
                        categoryName: $route.params.categoryName,
                      },
                    }"
                    >{{ $route.params.categoryName }}</router-link
                  >
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Subscription Description' }">{{
                    $route.params.subscriptionName
                  }}</router-link>
                </li>

                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Practice Test List' }"
                    >Practice Test List</router-link
                  >
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>

    <section class="plac-pre-prac-tst mt-4 my-2">
      <!-- Parent topics  -->
      <ul class="nav nav-tabs plac-prep-nav-link" id="myTab" role="tablist">
        <li
          class="nav-item"
          role="presentation"
          v-for="(parentTopic, index) in parentTopics"
          :key="index"
        >
          <button
            @click="(parentTopicId = parentTopic.id), getSubtopics()"
            class="nav-link"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            {{ parentTopic.topic_name }}
          </button>
        </li>
      </ul>
      <!-- End of parent topic -->

      <!-- video list -->
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <section class="tl-lst-tst pt-0 pb-2">
            <div
              href=""
              class="list-test"
              v-for="(subtopic, index) in subtopics"
              :key="index"
            >
              <div class="d-flex">
                <div class="lft-lst-tst flex-grow-1 pe-4">
                  <h5>{{ subtopic.topic.name }}</h5>
                  <p class="text-primary">{{ subtopic.topic.description }}</p>
                  <router-link
                    :to="{
                      name: 'Practice Tests',
                      params: {
                        subscriptionId: $route.params.subscriptionId,
                        subscriptionName: $route.params.subscriptionName,
                        topicName: subtopic.topic.name,
                        topicId: subtopic.id,
                        categoryName: $route.params.categoryName,
                        categoryId: $route.params.categoryId,
                      },
                    }"
                  >
                    <div class="d-flex">
                      <p
                        class="lock-vid"
                        v-if="
                          userSubscriptionStatus == 1 || subtopic.is_free == '1'
                        "
                      >
                        <i class="fas fa-angle-right"></i>
                      </p>
                    </div>
                  </router-link>
                  <div class="d-flex">
                    <a href=""></a>
                    <p
                      class="lock-vid"
                      v-if="
                        userSubscriptionStatus != 1 && subtopic.is_free == 0
                      "
                    >
                      <router-link :to="{ name: 'Subscription' }">
                        <i class="fas fa-lock"></i>
                      </router-link>
                    </p>
                  </div>
                </div>

                <div class="rt-comp-img d-grid sub-img">
                  <img :src="subtopic.topic.image" alt="" />
                </div>
              </div>
            </div>

            <!--     <router-link
              class="in-list-test"
              :class="
                subtopic.user_sub_status == 0
                  ? subtopic.is_free == 1
                    ? 'pe-auto'
                    : 'disablee pe-none'
                  : 'pe-auto'
              "
              :to="{
                name: 'Practice Tests',
                params: {
                  subscriptionId: $route.params.subscriptionId,
                  subscriptionName: $route.params.subscriptionName,
                  topicName: subtopic.topic.name,
                  topicId: subtopic.id,
                  categoryName: $route.params.categoryName,
                  categoryId: $route.params.categoryId,
                },
              }"
            >
              <div href="" class="list-test">
                <div class="d-flex">
                  <div class="lft-lst-tst flex-grow-1 pe-4">
                    <h5>{{ subtopic.topic.name }}</h5>
                    <p class="text-primary">{{ subtopic.topic.description }}</p>
                    <div class="d-flex">
                      <p class="lock-vid">
                        <i
                          :class="
                            userSubscriptionStatus == 1
                              ? 'fas fa-angle-right'
                              : subtopic.is_free == 0
                              ? 'fas fa-lock'
                              : 'fas fa-angle-right'
                          "
                        ></i>
                      </p>
                    </div>
                  </div>
                  <div class="rt-comp-img d-grid">
                    <img :src="subtopic.topic.image" alt="" />
                  </div>
                </div>
              </div>
            </router-link>
            -->
          </section>
        </div>
        <!-- End of video list -->
      </div>
    </section>
    <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      parentTopics: [],
      parentTopicId: "",
      subtopics: [],
      loader: false,
      userSubscriptionStatus: 0,
    };
  },
  mounted: async function() {
    await this.getParentTopics();
    this.getSubtopics();
  },
  methods: {
    async getParentTopics() {
      this.loader = true;
      const formdata = new FormData();
      formdata.append("sub_id", this.$route.params.subscriptionId);
      formdata.append("sub_content", "practicetest");

      await axios
        .post(
          `${this.$store.getters.baseUrl}/subscription/parent_topics`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data.status == "success") {
            this.parentTopics = response.data.topics;
            this.parentTopicId = this.parentTopics[0].id;
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getSubtopics() {
      this.loader = true;
      console.log("Parent Topic" + this.parentTopicId);
      const formdata = new FormData();
      formdata.append("sub_id", this.$route.params.subscriptionId);
      formdata.append("sub_content", "practicetest");
      formdata.append("mapped_topic_id", this.parentTopicId);

      await axios
        .post(
          `${this.$store.getters.baseUrl}/subscription/sub_topics`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.subtopics = response.data.sub_topics;
            this.userSubscriptionStatus = response.data.user_sub_status;
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
