<template>
  <div>
    <Header />
    <section
      class="other-banner"
      style="background-position: center"
      :style="{ backgroundImage: `url(${backgroundUrl})` }"
    >
      <div class="banner-content">
        <div class="banner-caption">
          <h1>About us</h1>
          <div class="banner-btn mt-1">
            <!--
            <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
            <a class="lst-a mx-1">About us</a>
            -->
            <nav class="flex-sm-00-auto ml-sm-3" aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-alt">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'About Us' }">About Us</router-link>
                </li>
              </ol>
            </nav>
          </div>
          <!-- <button class="btn-sub-trail mt-3">Edit</button> -->
        </div>
      </div>
    </section>
    <section class="abt-page w-80 m-auto p-3">
     
     <!-- <p>
        Aptitude Buster has been the only institute which offers the
        conceptional videos to the students, where they can watch it infinite
        times!! Our ultra HD concept videos illustrate each topic on
        Quantitative Aptitude, Verbal Aptitude, Reasoning and Technical aptitude
        with utmost clarity. These Concept videos have additional operational
        features like discussion forum and live chat to clarify any doubt
        arising while understanding the concept.
      </p>
      <p>
        In Addition, Daily Live sessions, Practice Tests, Study Material and
        Practice worksheets & Previous Years (2011-2020) UPSC CSAT Question
        papers, this app will definitely make a candidate to crack any
        competitive exams and Campus placements effortlessly.
      </p>
      <p>
        Our chief strength lies in providing multiple approaches to solve
        aptitude problems, specifically the approaches which help a student to
        solve questions accurately and quickly. The most remarkable quality of
        our training inputs is to map the question patterns of competitive exams
        most appropriately and make them reflect in our tests.
      </p>
      <p>
        Our topnotch faculty provide excellent service to the students with the
        latest training modules that mould and strengthen them to efficiently
        compete. Each staff member has individually helped a lot of young minds
        to craft their career. Not everyone can afford the luxury of learning
        but we, the Aptitude Buster team, have created a learning platform
        online to reach the students across the country at ease.
      </p>
      <p>
        To put it in a nutshell, Aptitude Buster is a leading virtual
        representation training centre which is committed to provide par
        excellence training and service to the students’ fraternity.
      </p> -->
      <p>
        Welcome to Aptitude Buster – where learning meets innovation, and success is not just a destination but a journey crafted with excellence.
        We pride ourselves as the trailblazers in educational innovation, redefining the landscape with our unique approach to aptitude training.
        </p>
        <p>
          Imagine a place where the boundaries of traditional learning are shattered. At Aptitude Buster, we revolutionize the learning experience by offering conceptual videos that transcend the ordinary. Our ultra HD concept videos on Quantitative Aptitude, Verbal Aptitude, Reasoning, and Technical Aptitude are not just lessons; they're an immersive journey into the core of understanding. And here's the magic – you can watch them infinitely! Yes, you heard it right. We believe in learning without limits.
          </p>
        <p>
          But that's not all. Our concept videos come with a built-in community, a discussion forum, and live chat features. Because learning is not just about understanding concepts; it's about thriving in a collaborative space where doubts dissolve, and brilliance emerges.
        </p>
        <p>
          What sets us apart is not just the quality of our content, but the commitment to your success. Daily live sessions, practice tests, study materials, and practice worksheets are just a glimpse of the arsenal we provide. And for those aspiring for UPSC, we've got you covered with question papers from 2011-2020, ensuring you are not just prepared but stay ahead of your competitors.
        </p>
        <p>
          Our strength lies in versatility. We don't just teach; we empower. Multiple approaches to aptitude problems, honed to perfection, guide you to accuracy and speed. Our tests are not just assessments; they are mirrors reflecting the very soul of competitive exams.
        </p>
        <p>
          Meet our top-notch faculty – the architects of success. With the latest training modules, they don't just teach; they sculpt. Each staff member has been a beacon, guiding countless young minds towards their career goals. Because at Aptitude Buster, we believe in making education accessible. Our online platform ensures that learning is not a luxury but a right, reaching students across the nation effortlessly.
        </p>
        <p>
          In a nutshell, Aptitude Buster is not just a training center; it's a virtual symphony of learning, committed to providing par excellence training and service to the student fraternity. Join us, and let's sculpt success together. Your journey begins here.
        </p>
    </section>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import backgroundUrl from "../../src/assets/img/banner.png";
export default {
  name: "Profile",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      backgroundUrl,
    };
  },
};
</script>
