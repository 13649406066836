<template>
  <div>
    <section class="other-banner">
      <Header />
      <div class="banner-content">
        <div class="banner-caption">
          <h1>{{ $route.params.subscriptionName }}</h1>

          <h4 class="mt-2 text-dark">
            <span class="text-dark">₹</span>{{ subscriptionDetails.price }}
          </h4>
          <div class="banner-btn mt-1 text-dark">
            <!--           <a href="placement-prep.php" class="lst-a me-1 text-primary">Home</a
            >/
            <a class="lst-a mx-1" href="placement-prep.php"
              >Company Specific Modules</a
            >/
            <a class="lst-a mx-1">Wipro tech</a>
            -->
            <nav class="flex-sm-00-auto ml-sm-3" aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-alt">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </li>
                <li
                  v-if="$route.params.categoryId"
                  class="breadcrumb-item"
                  aria-current="page"
                >
                  <router-link
                    :to="{
                      name: 'Placement Prep',
                      params: {
                        categoryId: $route.params.categoryId,
                        categoryName: $route.params.categoryName,
                      },
                    }"
                    >{{ $route.params.categoryName }}</router-link
                  >
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Subscription Description' }">{{
                    $route.params.subscriptionName
                  }}</router-link>
                </li>
              </ol>
            </nav>
          </div>
          <!-- <div class="my-3">
                <button data-bs-toggle="modal" data-bs-target="#subs-cription"
                    class="btn-sub-trail me-2">Subscription</button>
                <button class="btn-sub-trail">Trail</button>
            </div> -->
        </div>
      </div>
    </section>
    <section class="design-descrip d-flex w-80 mt-4">
      <div class="flex-grow-1 subs-desc">
        <p>
          <i class="fas fa-pencil-ruler"></i
          >{{ subscriptionDetails.description }}
        </p>
      </div>
      <div v-if="userSubscriptionStatus == 0" class="d-grid">
        <h4 class="m-auto">
          <span>₹</span><br />{{ subscriptionDetails.price }}
        </h4>
      </div>
    </section>
    <section class="w-80 btm-prep-det">
      <div
        class="mt-2"
        v-for="(specification, index) in subscriptionSpecifications"
        :key="index"
      >
        <p>
          <i class="far fa-check-circle"></i>{{ specification.specification }}
        </p>
      </div>

      <div class="my-3">
        <button
          v-if="userSubscriptionStatus == 0"
          @click="getPaymentDetails()"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#subs-cription"
          class="btn-sub-trail me-2"
          style="min-width: 120px"
        >
          Subscribe
        </button>
        <button
          v-if="userSubscriptionStatus == 0"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#trail-det"
          class="btn-sub-trail"
          style="min-width: 120px"
        >
          Trial
        </button>

        <button
          v-if="userSubscriptionStatus == 1"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#trail-det"
          class="btn-sub-trail"
          style="min-width: 120px"
        >
          Continue
        </button>
      </div>
    </section>
    <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->

    <Footer />
    <!-- Subcrption Popup Starts -->
    <div
      class="modal fade"
      id="subs-cription"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Subscription
            </button>

            <div class="enq-form-index col-12 col-lg-12">
              <h3 class="fw-bold text-uppercase fs-5">Subscription Details</h3>
              <div class="lin-btm ms-2 mb-3 mt-2" style="width: 125px"></div>
              <div class="d-flex justify-content-between mb-2">
                <p>Amount(₹)</p>

                <p>{{ paymentDetails.amount }}.00</p>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <p>Redeem Points</p>
                <p>
                  {{
                    paymentDetails.redeem_points
                      ? paymentDetails.redeem_points
                      : "0.00"
                  }}
                </p>
              </div>
              <div class="d-flex justify-content-between mb-2 pb-1 sub-amt-pop">
                <p>Discount ( {{ paymentDetails.discount }} %)</p>
                <p>{{ paymentDetails.discount_amount }}.00</p>
              </div>
              <div class="d-flex justify-content-between mt-2 pb-1">
                <p><b>Total(₹)</b></p>
                <p>
                  <b>{{ paymentDetails.total }}.00</b>
                </p>
              </div>
              <div class="d-flex justify-content-between mb-2 sub-amt-pop">
                <p>GST - 18%</p>
                <p>{{ paymentDetails.gst }}.00</p>
              </div>
              <div class="d-flex justify-content-between mb-2 pb-1 sub-amt-pop">
                <p><b>Grand Total(₹)</b></p>
                <p>
                  <b>{{ paymentDetails.grand_total }}.00</b>
                </p>
              </div>
              <button
                class="btn-sub-trail me-2"
                @click="getSubscriptionPayment()"
              >
                Proceed to Pay
              </button>

              <!-- <form action="">
                        <div class=" row">
                            <div class="col-6 col-md-12 col-lg-6 mob-full">
                                <div class="form-group mb-4">
                                    <label for="">Enter Name</label>
                                    <input type="text" placeholder="Name">
                                </div>
                            </div>
                            <div class="col-6 col-md-12 col-lg-6 mob-full">
                                <div class="form-group mb-4">
                                    <label for="">Enter Your Mobile Name</label>
                                    <input type="number" placeholder="Mobile No">
                                </div>
                            </div>
                            <div class="col-12 mob-full">
                                <div class="form-group mb-4">
                                    <label for="">Enter Your Email (option)</label>
                                    <input type="email" placeholder="email">
                                </div>
                            </div>
                            <div class="col-6 col-md-12 col-lg-6 mob-full">
                                <div class="form-group mb-4">
                                    <label for="">Select Service</label>
                                    <select name="" id="">
                                        <option class="" value="">E-wate</option>
                                        <option value="">Scap E-waste</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 mb-0 mob-full">
                                <div class="form-group text-area-inpt">
                                    <label for="">Enter Message</label>
                                    <textarea name="" id="" cols="30" rows="6"></textarea>
                                    <button class="sbmt-btn">submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Subcrption Popup Ends -->
    <!-- trail-det (COURSE ITINERARY) Pop Start Here -->
    <div
      class="modal fade p-5"
      id="trail-det"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" style="width: 350px">
        <div class="modal-content">
          <div class="modal-body">
            <button
              id="closeModal"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div class="enq-form-index col-12 col-lg-12">
              <h3 class="fw-bold text-uppercase fs-5">course itinerary</h3>
              <br />
              <div class="mt-3 mb-2">
                <router-link
                  :to="{
                    name: 'Subscription Video List',
                    params: {
                      subscriptionName: $route.params.subscriptionName,
                      subscriptionId: $route.params.subscriptionId,
                      categoryName: $route.params.categoryName,
                      categoryId: $route.params.categoryId,
                    },
                  }"
                >
                  <a class="btn-sub-trail mt-2 me-2 px-2" @click="closeModal()"
                    >Videos</a
                  >
                </router-link>
                <router-link
                  :to="{
                    name: 'Practice Test List',
                    params: {
                      subscriptionName: $route.params.subscriptionName,
                      subscriptionId: $route.params.subscriptionId,
                      categoryName: $route.params.categoryName,
                      categoryId: $route.params.categoryId,
                    },
                  }"
                >
                  <a class="btn-sub-trail mt-2 me-2 px-2" @click="closeModal()"
                    >Practice Tests</a
                  >
                </router-link>

                <router-link
                  :to="{
                    name: 'Subscription Mock Test List',
                    params: {
                      subscriptionName: $route.params.subscriptionName,
                      subscriptionId: $route.params.subscriptionId,
                      categoryName: $route.params.categoryName,
                      categoryId: $route.params.categoryId,
                      userSubscriptionStatus: userSubscriptionStatus,
                    },
                  }"
                >
                  <a class="btn-sub-trail mt-2 me-2 px-2" @click="closeModal()"
                    >Mock Tests</a
                  >
                </router-link>
              </div>
              <!-- <button class="btn-sub-trail mt-2">Mock Tests</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Trail Pop Ends Here -->
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      subscriptionDetails: {},
      subscriptionSpecifications: [],
      paymentDetails: {},
      userSubscriptionStatus: "",
      loader: false,
    };
  },
  created: async function() {
    await this.getSubscriptionDetails();
  },
  methods: {
    async getPaymentDetails() {
      //console.log("I am Clicked");
      const formdata = new FormData();
      formdata.append("sub_id", this.subscriptionDetails.id);

      await axios
        .post(
          `${this.$store.getters.baseUrl}/sub_payment/amount_detail`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.data.status == "success") {
            this.paymentDetails = response.data.sub_amount_detail;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    async getSubscriptionDetails() {
      this.loader = true;
      const formdata = new FormData();
      formdata.append("sub_id", this.$route.params.subscriptionId);

      await axios
        .post(
          `${this.$store.getters.baseUrl}/subscription/sub_details`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.subscriptionDetails = response.data.sub_details;
            this.subscriptionSpecifications = this.subscriptionDetails.subscription_specification;
            this.userSubscriptionStatus = response.data.user_sub_status;
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    //Payment post
    async getSubscriptionPayment() {
      this.loader = true;
      const formdata = new FormData();
      formdata.append("sub_id[]", this.$route.params.subscriptionId);
      formdata.append("redeem_points", this.paymentDetails.redeem_points);
      formdata.append("discount", this.paymentDetails.discount);
      formdata.append("gst_amount", this.paymentDetails.gst_amount);
      formdata.append("gst_percent", this.paymentDetails.gst_percent);
      formdata.append("total_amount", this.paymentDetails.grand_total);
      formdata.append("amount", this.paymentDetails.amount);

      await axios
        .post(
          `${this.$store.getters.baseUrl}/subscription/payment_details`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
        
          if (response.data.status == "success") {
            this.$router.push({
              name: "Subscription Payment Pay",
              params: {
                data: response.data.data,
                hash: response.data.hash,
              },
            }),
               console.log(response.data.data);
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    closeModal() {
      document.getElementById("closeModal").click();
    },
  },
};
</script>
