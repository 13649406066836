<template>
  <div>
    <section class="other-banner" style="
          background: url('assets/img/test/banner.jpg') no-repeat;
          background-position: center;
          min-height: auto;
        ">
      <Header />
    </section>
    <section class="d-flex justify-content-center p-0 mx-0 flex-wrap">
        <div class=" col-12
              d-flex
              justify-content-end
              align-items-center
              px-2
              py-1
              mb-2
            ">
          <button class="btn-skip" @click="skipSection()"
            :style="topicIndex == topics.length - 1 ? 'visibility: hidden' : ''">
            Skip Section
          </button>
          <div class="text-center d-flex flex-row-reverse align-item-center" v-if="$route.params.type !== 'subscription_mock_test'">
           <!-- <button v-if="$route.params.type == 'practice-test'" class="pass-play " @click="pausePlayTimer()">
              <i class="fas fa-pause" id="pas-play"></i>
            </button>

            <button v-else class="pass-play ">
              <i class="fas fa-pause" id="pas-play"></i>
            </button> -->
            <p class="text-center" id="timers"></p>
          </div>
          <div class="text-center d-flex flex-row-reverse align-item-center" v-if="$route.params.type === 'subscription_mock_test'">
            
             <!--<button class="pass-play  " @click="pausePlayTimer()">
            <i class="fas fa-pause" id="pas-play"> </i>
          </button> -->
          <p class="text-center" id="timers"></p>
          </div>
          <!-- <a :style="topicIndex != topics.length - 1 ? 'visibility: hidden' : ''" data-bs-toggle="modal"
            data-bs-target="#trail-det" class="btn-sbmt mx-2" id="endTest"
            @click="checkMinimumLines(topicIndex, questionIndex)">End Test</a> -->
            <a :style="topicIndex != topics.length - 1 ? 'visibility: hidden' : ''" class="btn-sbmt mx-2" id="endTest"
            @click="popUp()">End Test</a>
          <!-- <button class="btn-sbmt">End Test</button> -->
        </div>
        <aside class="p-3 col-md-8">
        <article class="question-img" v-for="(topic, index) in topics" :key="index">
          <div v-if="topicIndex == index">
            {{ time == null ? timers(topic.duration, topic.id) : "" }}
            <h4 class="text-center">
              <b>{{ topic.topic_name }}</b>
            </h4>
            <br />
            <div v-for="(question, index1) in topic.questions" :key="index1" v-show="questionIndex == index1" class="row justify-content-between">
              <div :class=" question.question_type != 'coding' ? 'row col-md-12 p-2 py-3' : 'row col-md-5 p-2 py-3 quest-card'">
                <p class="mb-3 px-3  col-md-12">
                  <span>Q.No <b>{{ question.qno }}</b> of
                    <b>{{ totalQuestions }}</b>
                    <a class="btn-rmk" @click="remark(topic.id, index1, question.qno)">REMARK
                    </a>
                  </span>
                  <br /><br />
                  <span v-if="question.group_question != null" :id="`group${question.qno}`" style="display: block"><b
                      v-html="question.group_question.question"></b> <br />
                    <br v-if="question.group_question.image" /><img :src="question.group_question.image" style="width: 50%"
                      alt="" />
                    <br v-if="question.group_question.image" />
                    <br v-if="question.group_question.image" /></span>
                  <span v-html="question.question"></span>
                </p>
                <div class="col-md-12">
                  <div class="questions-img" style="display: block;">
                    <img :src="question.image" style="width: 100%" alt="" />
                  </div>
                </div>
                <div class="col-md-12" style="padding: 0">
                  <div class="questions-read">
                    <button class="read-more mx-2" style="float: right" v-if="question.group_question != null"
                      :id="`readMore${question.qno}`" @click="readMore(question.qno)">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div class="radi-box" v-if="question.question_type == 'objective'">
                <ul class="row">
                  <li class="w-50" v-for="(option, index2) in question.options" :key="index2">
                    <input type="radio" :id="`${question.qno}${index2}`" :name="`${question.qno}`"
                      :checked="question.userAnswer == index2 ? true : false" @click="
                        optionClick(topic.id, index1, index2, question.qno),
                        updateUserAnswer(
                          option,
                          index,
                          index1,
                          question.question_type
                        )
                      " />
                    <label class="btn-cek blu-lb" :for="`${question.qno}${index2}`">
                      <span>{{
                        index2 == 0
                        ? "A"
                        : index2 == 1
                          ? "B"
                          : index2 == 2
                            ? "C"
                            : index2 == 3
                              ? "D"
                              : "E"
                      }}</span>{{ option.option }}</label>
                    <div class="check blu-ck">
                      <div class="inside"></div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-md-7 form-group p-3 quest-card" v-else-if="question.question_type == 'coding'">
                <div id="app">
                  <span>Language: </span>
                  <select :id="`coding-language${question.qno}`" v-model="question.language">
                    <option value="c">C</option>
                    <option value="cpp">C++</option>
                    <option value="java">Java</option>
                    <option value="python3">Python 3.x</option>

                  </select><br>
                  <span> Source Code: </span>
                  <prism-editor class="my-editor" v-model="question.source_code" :highlight="highlighter"
                    autoStyleLineNumbers autoCompletion: true spellcheck="true" autocomplete="on" autocorrect="on"
                    @init="editorInit" languages="[c,cpp,java,python]" theme="monokai" width="100%" height="400px"
                    @input="codingUpdate(question.qno, topic.id, index, index1, question.source_code, question.language)"
                    :id="`coding-source_code${question.qno}`" :value="question.source_code">
                  </prism-editor>
                  <div class="btns-desing my-3 d-flex">
                    <button class="btn-sbmt" @click="codeExecute(question.language, question.source_code, index1)">Run
                      Code</button>
                    <button class="btn-sbmt" @click="clearCode(index, index1)">Clear Code</button>
                  </div>
                  <div v-if="code_execute_output[index1]">
                    <h4 class="ques-heading">Output :</h4>
                    <p>{{ code_execute_output[index1] }}</p>
                  </div>
                  <!-- <textarea style="width:100%" v-model="question.source_code"  :id="`coding-source_code${question.qno}`" @input="codingUpdate(question.qno, topic.id, index, index1, question.source_code, question.language)"> </textarea>
                 <div id="aceEditor"> -->
                  <!--<AceEditor 
                  v-model="question.source_code"
                  @init="editorInit" 
                  lang="[[javascript],[c],[java][python][cpp]]" 
                  theme="monokai" 
                  width="100%" 
                  height="200px"
                  :options="{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      fontSize: 14,
                      highlightActiveLine: true,
                      enableSnippets: false,
                      showLineNumbers: false,
                      tabSize: 2,
                      showPrintMargin: false,
                      showGutter: false,
                  }"
                  :commands="[
                      {
                          name: 'save',
                          bindKey: { win: 'Ctrl-s', mac: 'Command-s' },
                          exec: dataSumit,
                          readOnly: true,
                      },
                  ]"
                v-focus="codingUpdate(question.qno, topic.id, index, index1, question.source_code, question.language)"
                :id="`coding-source_code${question.qno}`"
                  /> -->
                  <!-- <prism-editor class="my-editor" 
                  v-model="question.source_code"
                  :highlight="highlighter"
                  autoStyleLineNumbers                  
                  autoCompletion: true
                  spellcheck="true" 
                  autocomplete="on" 
                  autocorrect="on"
                  @init="editorInit" 
                  languages="[c,cpp,java,python]" 
                  theme="monokai" 
                  width="100%" 
                  height="400px"
                  @input="codingUpdate(question.qno, topic.id, index, index1, question.source_code, question.language)"
                  :id="`coding-source_code${question.qno}`">
                </prism-editor>
     -->
                </div>

              </div>
              <div class="form-group w-50" v-else>
                <input type="text" class="form-control" placeholder="Enter your answer here..."
                  @keyup="fillupUpdate(question.qno, topic.id, index, index1)" :id="`fillup${question.qno}`"
                  :value="question.userAnswer" />
              </div>
            </div>
          </div>
        </article>

        <div class="d-flex my-3 justify-content-between">
          <button v-if="questionIndex == 0" class="btn-radious" :style="
            topicIndex == 0 && questionIndex == 0 ? 'visibility: hidden' : ''
          " @click="noBack()">
            Previous
          </button>

          <button class="btn-radious" v-if="questionIndex != 0" :style="
            topicIndex == 0 && questionIndex == 0 ? 'visibility: hidden' : ''
          " @click="previous()">
            Previous
          </button>
          <button class="btn-radious mx-2" style="float: right"
            @click="checkMinimumLines(topicIndex, questionIndex, 'next')" :style="
              topicIndex == topics.length - 1 &&
                questionIndex == topics[topics.length - 1].questions.length - 1
                ? 'visibility: hidden'
                : ''
            ">
            Next
          </button>
        </div>
      </aside>
      <aside class="ques-ptn col-md-4" >
        <h4 class="mb-2">Question Palette</h4>
        <div class="ref-clr d-flex">
          <!-- <p class="bg-gn">Correct</p>
            <p class="bg-rd">Incorrect</p> -->
          <p class="bg-blu">Attempt</p>
          <p class="bg-def">Unattempted</p>
          <p class="bg-yel">Remark</p>
        </div>
        <div class="fst-qes-pag" v-for="(topic, index) in topics" :key="index">
          <h5 class="mb-1">{{ topic.topic_name }}</h5>
          <div :class="
            index != topicIndex ? 'qes-pag-p-tl pe-none' : 'qes-pag-p-tl'
          " :style="index != topicIndex ? 'opacity: 0.5' : ''" v-for="(question, index1) in topic.questions"
            :key="index1" @click="questionPallete(index1)">
            <p :class="
              question.userAnswer
                ? 'bg-blu'
                : question.source_code
                  ? 'bg-blu'
                  : question.remarks
                    ? 'bg-yel'
                    : 'bg-def'
            " :id="`palette${question.qno}`">
              {{ question.qno }}
            </p>
          </div>
        </div>
      </aside>
    </section>
     <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
    <Footer />

    <div class="modal fade p-5" id="trail-det" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true" ref="vueModal">
      <div class="modal-dialog modal-dialog-centered" style="width: 300px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Submit Test</h5>
            <button style="visibility: hidden" id="modalClose" type="button" class="btn-close" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <dl>
              <div>
                <dt class="red"></dt>
                <dd>Answered</dd>
                <span class="mb-0" style="float: right" id="answered"></span>
              </div>
              <div>
                <dt class="yellow"></dt>
                <dd>Unattempted</dd>
                <span class="mb-0" style="float: right" id="unattempted"></span>
              </div>
              <div>
                <dt class="rem"></dt>
                <dd>Remark</dd>
                <span class="mb-0" style="float: right" id="remarks"></span>
              </div>
              <div>
                <hr />
                <dd>Total Questions</dd>
                <span class="mb-0" style="float: right">{{
                  totalQuestions
                }}</span>
                <hr />
              </div>
            </dl>

            <div class="enq-form-index col-12 col-lg-12">
              <p class="mt-3">
                Clicking Submit will submit the test and not allow you to review
                or change your answers
              </p>
              <div class="mt-1 justify-content-center d-flex">
                <button id="resume" class="btn-sub-trail px-3 mt-2 me-2 px-2" @click="resumeClick()">
                  Resume
                </button>
                <button class="btn-sub-trail px-3 mt-2 me-2 px-2" @click="submitTest()">
                  Submit
                </button>
              </div>
              <!-- <button class="btn-sub-trail mt-2">Mock Tests</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.5.0.js"></script>

    <script src="https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.13/ace.js" type="text/javascript" charset="utf-8"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.13/mode-php.min.js" type="text/javascript" charset="utf-8"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.13/mode-python.min.js" type="text/javascript" charset="utf-8"></script>
    <script>
        var editor = aceEditor.edit("aceEditor");
        var code = editor.getValue();
        editor.setTheme("ace/theme/monokai");
        editor.session.setMode("ace/mode/javascript");
        editor.setOptions({
            minLines: 2
        });
    </script>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import vue from "vue";
// import AceEditor from 'ace-editor-vue';
// import runkit from 'vue-runkit';
// import VueCodeEditor from 'vue2-code-editor';
// import CodeEditor from 'simple-code-editor';
// import AceEditor from 'vuejs-ace-editor';
import { PrismEditor } from 'vue-prism-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css';import 'vue-prism-editor/dist/prismeditor.min.css';

// import VueAceEdit from "vue-ace-edit"
// Vue.use(VueAceEdit)
// import VueTinymce from 'vue2-tinymcy-editor'
//import "highlight.min.js";
//import "code_editor.prod.js";
   
// import CodeEditor from 'simple-code-editor';

export default {
  components: {
    Header,
    Footer,
    PrismEditor,
    // VueCodeEditor,
    // runkit, 
    // VueTinymce,
    // CodeEditor,
    // AceEditor,
    // VueAceEdit,
  },
  data() {
    return {
      durationOfFirstTopic: 0,
      topics: [],
      db: null,
      dbVersion: 1,
      objectStore: null,
      no: 0,
      topicIndex: 0,
      questionIndex: 0,
      time: null,
      refreshDuration: 0,
      durationNew: 0,
      response_Duration: 0,
      totalQuestions: 0,
      submitAPI: 0,
      value:'',
      source_code:'',
      language:'',
      code_execute_output:[],
      loader: false,
      first_submit: true,
      modelOpen:false,
      topicId:0,
      timerObjectFinal:{}
    };
  },
  mounted: async function() {
    if (
      (await window.indexedDB.databases())
        .map((db) => db.name)
        .includes("QuestionsDB")
    ) {
      await this.refresh();
      this.reRender();
    } else {
      await this.getDB();
      this.reRender();
    }

  },
  watch: {
    topics: function() {
      console.log(window);
      console.log("data changed");
      this.$nextTick().then(() => {
        this.reRender();
      });
    },
    questionIndex: function() {
      console.log(window);
      //console.log("data changed");
      this.$nextTick().then(() => {
        this.reRender();
      });
    },
  },
  methods: {

     highlighter(source_code) {
        return highlight(source_code, languages.js); // languages.<insert language> to return html with markup
      },
    dataSumit() {
            //code here
        },
        editorInit: function () {
            require('brace/ext/language_tools') //language extension prerequsite...
            require('brace/mode/html')                
            require('brace/mode/javascript')    //language
            require('brace/mode/less')
            require('brace/theme/monokai')
            require('brace/snippets/javascript') //snippet
        },
    async reRender() {
      //console.log(window);
      if (window.MathJax) {
        console.log("rendering mathjax");
        await window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () =>
          console.log("done")
        );
      }
    },
    pausePlayTimer() {
      let playpauseButton = document.getElementById("pas-play");
      playpauseButton.className =
        playpauseButton.className == "fas fa-pause"
          ? "fas fa-play"
          : "fas fa-pause";
      if (playpauseButton.className == "fas fa-play") {
        clearInterval(this.time);
        this.updateStatus();
      } else {
        this.refresh();
      }
    },

    clearCode(tindex,qindex){

         this.$set(this.topics[tindex].questions[qindex], 'source_code',"");
          this.$set(this.code_execute_output, qindex,"")
    },

    checkMinimumLines(tindex,qindex,type = null){

      if(this.topics[tindex].questions[qindex].question_type == "coding"){

        var minimum_lines = this.topics[tindex].questions[qindex].minimum_lines; 

        var source_code = this.topics[tindex].questions[qindex].source_code;

        if(source_code){
              var lines = this.topics[tindex].questions[qindex].source_code.split(/\r\n|\r|\n/).length;
        }else{

             var lines = 0;
        }

          console.log(source_code);

        if(type == "next"){

            if(minimum_lines != 0 && source_code){

            if(lines < minimum_lines){

              this.$swal("Error", "The Minimum Lines of code should be"+minimum_lines, "warning");
            }else{

              this.next();
            }

          }else{

            this.next();
          }

        }else{

          if(minimum_lines != 0 && source_code){

          if(lines < minimum_lines){

            this.$swal("Error", "The Minimum Lines of code should be"+minimum_lines, "warning");
          }else{

            this.modalData();
          }

          }else{

            this.modalData();
          }

        }

      }else{

          if(type == "next"){

            this.next();

          }else{

            this.modalData();

          }
      }

      
    },

    async codeExecute(language,source_code,index){

     /* var program = {
          script : source_code,
          language: language,
          clientId: "363588a320f130b34b439f0071601448",
          clientSecret:"4456a363d701608bb2ec5efba6013f172b4566eabe46a7ca458436ea20982032"
      };

      axios.post('https://api.jdoodle.com/v1/execute', {
          jsonData: program
        },{
              headers: {
                'Content-Type': 'application/json'
              },
            })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });*/


      const formdata = new FormData();
      formdata.append("source_code", source_code);
      formdata.append("language", language);

      await axios
        .post(
          `${this.$store.getters.baseUrl}/execute-coding`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.$set(this.code_execute_output, index, response.data.message)
            console.log(this.code_execute_output[index]);
            //this.code_execute_output.$set(index,  response.data.message) 
             //this.code_execute_output[index] = response.data.message;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });


    },
    async updateUserAnswer(option, topicInx, questionInx, questionType) {
      let tx = this.db.transaction("questions", "readonly");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.get("finalObject");

      data.onsuccess = async (eve) => {
        let tx1 = this.db.transaction("questions", "readwrite");
        tx1.oncomplete = (ev) => {
          console.log(ev);
        };

        tx1.onerror = (err) => {
          console.log(err);
        };
        let dataToUpdate = eve.target.result;
        if (questionType == "objective") {
          dataToUpdate.topic[topicInx].questions[questionInx].option_id =
            option.id;
          dataToUpdate.topic[topicInx].questions[questionInx].user_answer =
            option.option;
          // dataToUpdate.topic[topicInx].questions[questionInx].remarks = null;
        }
        if (questionType == "coding") {
          dataToUpdate.topic[topicInx].questions[questionInx].option_id = "";
          dataToUpdate.topic[topicInx].questions[questionInx].source_code = "";
          dataToUpdate.topic[topicInx].questions[questionInx].language = "";
        }
        if (questionType == "fill_in_blank") {
          dataToUpdate.topic[topicInx].questions[questionInx].option_id = "";
          dataToUpdate.topic[topicInx].questions[questionInx].user_answer = "";
        }
        let dbData1 = await tx1.objectStore("questions");
        let data1 = await dbData1.put(dataToUpdate);

        data1.onsuccess = (ev) => {
          console.log("user answer updated successfully", ev);
        };

        data1.onerror = (err) => {
          console.log("error updating the user answer", err);
        };
      };

      data.onerror = (err) => {
        console.log(err);
      };
    },
    async optionClick(topicId, questionIndex, optionIndex, qno) {
      let tx = this.db.transaction("questions", "readonly");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.get(topicId);

      data.onsuccess = async (eve) => {
        let tx1 = this.db.transaction("questions", "readwrite");
        tx1.oncomplete = (ev) => {
          console.log(ev);
        };

        tx1.onerror = (err) => {
          console.log(err);
        };
        let dataToUpdate = eve.target.result;
        dataToUpdate.questions[questionIndex].userAnswer = optionIndex;
        dataToUpdate.questions[questionIndex].remarks = null;

        let dbData1 = await tx1.objectStore("questions");
        let data1 = await dbData1.put(dataToUpdate);

        data1.onsuccess = (ev) => {
          console.log("user answer updated successfully", ev);
        };

        data1.onerror = (err) => {
          console.log("error updating the user answer", err);
        };
        document.getElementById(`palette${qno}`).classList.remove("bg-def");
        document.getElementById(`palette${qno}`).classList.remove("bg-yel");
        document.getElementById(`palette${qno}`).classList.add("bg-blu");
      };

      data.onerror = (err) => {
        console.log("error updating the user answer", err);
      };
    },

    async remark(topicId, questionIndex, qno) {
      let tx = this.db.transaction("questions", "readonly");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.get(topicId);

      data.onsuccess = async (eve) => {
        let tx1 = this.db.transaction("questions", "readwrite");
        tx1.oncomplete = (ev) => {
          console.log(ev);
        };

        tx1.onerror = (err) => {
          console.log(err);
        };
        let dataToUpdate = eve.target.result;
        dataToUpdate.questions[questionIndex].userAnswer = null;
        dataToUpdate.questions[questionIndex].remarks = 1;
        let dbData1 = await tx1.objectStore("questions");
        let data1 = await dbData1.put(dataToUpdate);

        data1.onsuccess = (ev) => {
          console.log("user remarks updated successfully", ev);
        };

        data1.onerror = (err) => {
          console.log("error updating the user answer", err);
        };
        document.getElementById(`palette${qno}`).classList.remove("bg-def");
        document.getElementById(`palette${qno}`).classList.remove("bg-blu");
        document.getElementById(`palette${qno}`).classList.add("bg-yel");
      };

      data.onerror = (err) => {
        console.log("error updating the user answer", err);
      };
    },
    async refresh() {
      let DBOpenReq = indexedDB.open("QuestionsDB", this.dbVersion);

      DBOpenReq.addEventListener("error", (err) => {
        console.log(err);
      });

      DBOpenReq.addEventListener("success", async (ev) => {
        this.db = ev.target.result;
        console.log("success", this.db);
        let tx = this.db.transaction("questions", "readonly");
        tx.oncomplete = (ev) => {
          console.log(ev);
        };

        tx.onerror = (err) => {
          console.log(err);
        };

        let dbData = await tx.objectStore("questions");
        let data = await dbData.getAll();

        data.onsuccess = (ev) => {
          if (this.topics.length == 0) {
            ev.target.result.forEach((data) => {
              if (data.id != "currentStatus" && data.id != "finalObject"&& data.id != "timerObject") {
                this.topics.push(data);
              }
            });
          }

          // Total questions updating

          this.totalQuestions == 0
            ? this.topics.forEach(
                (topic) =>
                  (this.totalQuestions =
                    this.totalQuestions + topic.no_of_questions)
              )
            : "";

          clearInterval(this.time);
          let status = ev.target.result[ev.target.result.length - 2];
          console.log('status.currentDuration', status.currentDuration);
          this.timers(status.currentDuration / 60);
          // this.timers(12 / 60);
          this.topicIndex = status.currentTopic;
          this.questionIndex = status.currentQuestion;
        };

        data.onerror = (err) => {
          console.log("error updating the status", err);
        };
      });
    },

    // Db Intialize starts
    async getDB() {
      let DBOpenReq = indexedDB.open("QuestionsDB", this.dbVersion);

      DBOpenReq.addEventListener("error", (err) => {
        console.log(err);
      });
      //If Db Created Success it moves to getdata
      DBOpenReq.addEventListener("success", (ev) => {
        this.db = ev.target.result;
        console.log("success", this.db);
        this.getData();
      });
      DBOpenReq.addEventListener("upgradeneeded", async (ev) => {
        this.db = ev.target.result;
        let oldVersion = ev.oldVersion;
        let newVersion = ev.newVersion || this.db.version;
        console.log("DB updated from version ", oldVersion, "to", newVersion);
        if (!this.db.objectStoreNames.contains("questions")) {
          this.objectStore = await this.db.createObjectStore("questions", {
            keyPath: "id",
          });
        }
        const formdata = new FormData();
        if (this.$route.params.type == "daily_test") {
          formdata.append("type", "daily_test");
        }
        if (this.$route.params.type == "subscription_mock_test") {
          formdata.append("type", "subscription_mock_test");
          formdata.append("test_or_drive_id", this.$route.params.testId);
        }
        if (this.$route.params.type == "ongoing") {
          formdata.append("type", "ongoing");
          formdata.append("test_or_drive_id", this.$route.params.testId);
        }
        if (this.$route.params.type == "practice-test") {
          formdata.append("test_id", this.$route.params.testId);
        }
        if (this.$route.params.type == "all_india_mock_test") {
          formdata.append("type", "all_india_mock_test");
          formdata.append("test_or_drive_id", this.$route.params.testId);
        }
        await axios
          .post(
            this.$route.params.type != "practice-test"
              ? `${this.$store.getters.baseUrl}/mock-tests/make-test`
              : `${this.$store.getters.baseUrl}/subscription/test_questions`,
            formdata,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then(async (response) => {
            //Response is store using transation in the name of question with persmission to read anad write
            console.log(response);
            if (response.data.status == "success") {
              let tx = await this.db.transaction("questions", "readwrite");
              tx.oncomplete = (ev) => {
                console.log("transaction completed", ev);
              };
              tx.onerror = (err) => {
                console.log("transaction error", err);
              };

              response.data.topic_questions.forEach((data) => {
                data.questions = data.questions.map((question) => {
                  this.no++;
                  return {
                    ...question,
                    qno: this.no,
                    userAnswer: null,
                    remarks: null,
                  };
                });
                let request = tx.objectStore("questions").add(data);
                request.onsuccess = (ev) => {
                  console.log("successfully added an object", ev);
                };
                request.onerror = (err) => {
                  console.log("Error while adding object", err);
                };
              });
              this.no = 0;

              let request = tx.objectStore("questions").add({
                id: "currentStatus",
                currentTopic: this.topicIndex,
                currentQuestion: this.questionIndex,
                currentDuration: 0,
              });
              request.onsuccess = (ev) => {
                console.log("successfully added an object", ev);
              };
              request.onerror = (err) => {
                console.log("Error while adding object", err);
              };

              let finalTopicArray = [];
              for (var i = 0; i < response.data.topic_questions.length; i++) {
                finalTopicArray.push({
                  topic_id: response.data.topic_questions[i].topic_id,
                  no_of_questions:
                    response.data.topic_questions[i].no_of_questions,
                  questions: (response.data.topic_questions[
                    i
                  ].questions = response.data.topic_questions[i].questions.map(
                    (question) => {
                      return {
                        question_id: question.id,
                        question_type: question.question_type,
                        option_id: "",
                        user_answer: "",
                      };
                    }
                  )),
                });
              }
              var today = new Date();

              var date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate();

              var time =
                today.getHours() +
                ":" +
                today.getMinutes() +
                ":" +
                today.getSeconds();

              var dateTime = date + " " + time;
              let finalObject = {
                id: "finalObject",
                test_id: this.$route.params.submitId,
                start_date_time: dateTime,
                end_date_time: "",
                testExpiry: localStorage.getItem("testExpiry"),
                topic: finalTopicArray,
               // w_attempt:0,
              };

              let request1 = tx.objectStore("questions").add(finalObject);
              request1.onsuccess = (ev) => {
                console.log("successfully added an object", ev);
              };
              request1.onerror = (err) => {
                console.log("Error while adding object", err);
              };

              let obj ={}
              response.data.topic_questions.map((item, ind)=>{
                obj[item?.id]={
                    response_id:item?.id,
                    duration:item?.duration,
                    topic_id:item?.topic_id, 
                    timer: 0,
                  
                }
              })

              let timerObject = {
                id:'timerObject',
                ...obj
              }

              let request2 = tx.objectStore("questions").add(timerObject);
              request2.onsuccess = (ev) => {
                console.log("successfully added an object timer", ev);
              };
              request2.onerror = (err) => {
                console.log("Error while adding object timer", err);
              };

              this.getData();
            } else {
              await this.db.close();
              var req = indexedDB.deleteDatabase("QuestionsDB");
              req.onsuccess = function(e) {
                console.log("Deleted database successfully", e);
              };
              req.onblocked = function(e) {
                console.log("blocked", e);
              };
              req.onerror = function(e) {
                console.log("Couldn't delete database", e);
                return;
              };
              this.submitAPI = 1;
              this.$router.push({ name: "Home" });
              window.location.reload();
              this.$swal("Error", response.data.message, "warning");
            }
          })
          .catch(async (error) => {
            await this.db.close();
            var req = indexedDB.deleteDatabase("QuestionsDB");
            req.onsuccess = function(e) {
              console.log("Deleted database successfully", e);
            };
            req.onblocked = function(e) {
              console.log("blocked", e);
            };
            req.onerror = function(e) {
              console.log("Couldn't delete database", e);
              return;
            };
            this.submitAPI = 1;
            this.$router.push({ name: "Home" });
            window.location.reload();
            this.$swal("Error", error.response.data.message, "error");
          });
      });
    },
    // DB get function db transation means passing the data to the transation
    async getData() {
      let tx = this.db.transaction("questions", "readonly");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.getAll();
      data.onsuccess = (ev) => {
        if (this.topics.length == 0) {
          ev.target.result.forEach((data) => {
            if (data.id != "currentStatus" && data.id != "finalObject"&& data.id != "timerObject") {
              this.topics.push(data);
            }
          });
        }
        this.totalQuestions == 0
          ? this.topics.forEach(
              (topic) =>
                (this.totalQuestions =
                  this.totalQuestions + topic.no_of_questions)
            )
          : "";
      };
      data.onerror = (err) => {
        console.log(err);
      };
    },

    next() {
      
      if (
        this.questionIndex ==
        this.topics[this.topicIndex].questions.length - 1
      ) {
        this.$swal({
          title: "Are you sure?",
          icon: "warning",
          text:
            "Clicking Yes will take you to the next section and will not allow you to come back to this section again. Do you want to proceed ?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCancelButton: true,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            clearInterval(this.time);
            this.time = null;
            this.topicIndex = this.topicIndex + 1;
            this.questionIndex = 0;
            this.updateStatus();
          }
        });
      } else {
        this.questionIndex = this.questionIndex + 1;
        this.updateStatus();
      }

    },

    previous() {
      this.questionIndex = this.questionIndex - 1;
      this.updateStatus();
    },

    noBack() {
      this.$swal(
        "Warning",
        "You can't go back to the previous section",
        "warning"
      );
    },

    questionPallete(index1) {
      this.questionIndex = index1;
      this.updateStatus();
    },

    skipSection() {
      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        text:
          "Clicking Yes will skip this section and will not allow you to come back to this section again. Do you want to proceed ?",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          clearInterval(this.time);
          this.time = null;
          this.topicIndex = this.topicIndex + 1;
          this.questionIndex = 0;
          this.updateStatus();
        }
      });
    },

    async fillupUpdate(qno, topicId, topicInd, questionInd) {
      let tx = this.db.transaction("questions", "readonly");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.get(topicId);

      data.onsuccess = async (eve) => {
        let tx1 = this.db.transaction("questions", "readwrite");
        tx1.oncomplete = (ev) => {
          console.log(ev);
        };

        tx1.onerror = (err) => {
          console.log(err);
        };
        let fillup = document.getElementById(`fillup${qno}`).value;
        let dataToUpdate = eve.target.result;
        dataToUpdate.questions[questionInd].userAnswer = fillup;

        let dbData1 = await tx1.objectStore("questions");
        let data1 = await dbData1.put(dataToUpdate);

        data1.onsuccess = async (ev) => {
          console.log("user answer updated successfully", ev);
          let fillup = document.getElementById(`fillup${qno}`).value;
          this.topics[topicInd].questions[questionInd].userAnswer = fillup;

          let tx = this.db.transaction("questions", "readonly");
          tx.oncomplete = (ev) => {
            console.log(ev);
          };

          tx.onerror = (err) => {
            console.log(err);
          };

          let dbData = await tx.objectStore("questions");
          let data = await dbData.get("finalObject");

          data.onsuccess = async (eve) => {
            let tx1 = this.db.transaction("questions", "readwrite");
            tx1.oncomplete = (ev) => {
              console.log(ev);
            };

            tx1.onerror = (err) => {
              console.log(err);
            };
            let fillup = document.getElementById(`fillup${qno}`).value;
            let dataToUpdate = eve.target.result;
            dataToUpdate.topic[topicInd].questions[
              questionInd
            ].user_answer = fillup;

            let dbData1 = await tx1.objectStore("questions");
            let data1 = await dbData1.put(dataToUpdate);

            data1.onsuccess = (ev) => {
              console.log("Fillup updated successfully", ev);
            };

            data1.onerror = (err) => {
              console.log("Error while updating fillup", err);
            };
          };

          data1.onerror = (err) => {
            console.log("error updating the user answer", err);
          };
        };
      };

      data.onerror = (err) => {
        console.log("error updating the user answer", err);
      };
    },



    

    async codingUpdate(qno, topicId, topicInd, questionInd, source_code, language) {

      let tx = this.db.transaction("questions", "readonly");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.get(topicId);

      data.onsuccess = async (eve) => {
        let tx1 = this.db.transaction("questions", "readwrite");
        tx1.oncomplete = (ev) => {
          console.log(ev);
        };

        tx1.onerror = (err) => {
          console.log(err);
        };
        let dataToUpdate = eve.target.result;
        dataToUpdate.questions[questionInd].language = language;
        dataToUpdate.questions[questionInd].source_code = source_code;

        let dbData1 = await tx1.objectStore("questions");
        let data1 = await dbData1.put(dataToUpdate);

        data1.onsuccess = async (ev) => {
          console.log("user answer updated successfully", ev);
          this.topics[topicInd].questions[questionInd].language = language;
          this.topics[topicInd].questions[questionInd].source_code = source_code;

          let tx = this.db.transaction("questions", "readonly");
          tx.oncomplete = (ev) => {
            console.log(ev);
          };

          tx.onerror = (err) => {
            console.log(err);
          };

          let dbData = await tx.objectStore("questions");
          let data = await dbData.get("finalObject");

          data.onsuccess = async (eve) => {
            let tx1 = this.db.transaction("questions", "readwrite");
            tx1.oncomplete = (ev) => {
              console.log(ev);
            };

            tx1.onerror = (err) => {
              console.log(err);
            };
            let dataToUpdate = eve.target.result;
            dataToUpdate.topic[topicInd].questions[
              questionInd
            ].language = language;
            dataToUpdate.topic[topicInd].questions[
              questionInd
            ].source_code = source_code;

            let dbData1 = await tx1.objectStore("questions");
            let data1 = await dbData1.put(dataToUpdate);

            data1.onsuccess = (ev) => {
              console.log("Coding updated successfully", ev);
            };

            data1.onerror = (err) => {
              console.log("Error while updating Coding", err);
            };
          };

          data1.onerror = (err) => {
            console.log("error updating the user answer", err);
          };
        };
      };

      data.onerror = (err) => {
        console.log("error updating the user answer", err);
      };
      
    },

   /* async codingUpdate(qno, topicId, topicInd, questionInd, source_code, language) {
     
      let tx = this.db.transaction("questions", "readonly");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.get(topicId);

      data.onsuccess = async (eve) => {
        let tx1 = this.db.transaction("questions", "readwrite");
        tx1.oncomplete = (ev) => {
          console.log(ev);
        };

        tx1.onerror = (err) => {
          console.log(err);
        };
      
        let coding_language = language;
        let dataToUpdate = eve.target.result;
        dataToUpdate.questions[questionInd].language = coding_language;
        let coding_source_code = source_code;
        let dataToUpdatesc = eve.target.result;
       dataToUpdatesc.questions[questionInd].source_code = coding_source_code;
     
        console.log(source_code);
        console.log(language);
        console.log(coding_source_code);
        console.log(coding_language);

        let dbData1 = await tx1.objectStore("questions");
        let data1 = await dbData1.put(dataToUpdate);
        let data2 = await dbData1.put(dataToUpdatesc);

        data1.onsuccess = async (ev) => {
          console.log("user answer updated successfully", ev);
          let coding_language = language;
          this.topics[topicInd].questions[questionInd].language = coding_language;

          let tx = this.db.transaction("questions", "readonly");
          tx.oncomplete = (ev) => {
            console.log(ev);
          };

          tx.onerror = (err) => {
            console.log(err);
          };

          let dbData = await tx.objectStore("questions");
          let data = await dbData.get("finalObject");

          data.onsuccess = async (eve) => {
            let tx1 = this.db.transaction("questions", "readwrite");
            tx1.oncomplete = (ev) => {
              console.log(ev);
            };

            tx1.onerror = (err) => {
              console.log(err);
            };
            let coding_language = language;
            let dataToUpdate = eve.target.result;
            dataToUpdate.topic[topicInd].questions[
              questionInd
            ].language = coding_language;

            let dbData1 = await tx1.objectStore("questions");
            let data1 = await dbData1.put(dataToUpdate);

            data1.onsuccess = (ev) => {
              console.log("coding updated successfully", ev);
            };

            data1.onerror = (err) => {
              console.log("Error while updating coding", err);
            };
          };

          data1.onerror = (err) => {
            console.log("error updating the user answer", err);
          };
        };
        data2.onsuccess = async (ev) => {
          console.log("user answer updated successfully", ev);
          let coding_source_code = source_code;
          // let language = language;
          this.topics[topicInd].questions[questionInd].source_code = coding_source_code;
            // this.topics[topicInd].questions[questionInd].language = language;

          let tx = this.db.transaction("questions", "readonly");
          tx.oncomplete = (ev) => {
            console.log(ev);
          };

          tx.onerror = (err) => {
            console.log(err);
          };

          let dbData = await tx.objectStore("questions");
          let data = await dbData.get("finalObject");

          data.onsuccess = async (eve) => {
            let tx1 = this.db.transaction("questions", "readwrite");
            tx1.oncomplete = (ev) => {
              console.log(ev);
            };

            tx1.onerror = (err) => {
              console.log(err);
            };
            let coding_source_code = source_code;
            let dataToUpdatesc = eve.target.result;
            dataToUpdatesc.topic[topicInd].questions[
              questionInd
            ].source_code = coding_source_code;

            let dbData1 = await tx1.objectStore("questions");
            let data1 = await dbData1.put(dataToUpdatesc);

            data1.onsuccess = (ev) => {
              console.log("coding updated successfully", ev);
            };

            data1.onerror = (err) => {
              console.log("Error while updating coding", err);
            };
          };

          data1.onerror = (err) => {
            console.log("error updating the user answer", err);
          };
        };
      };

      data.onerror = (err) => {
        console.log("error updating the user answer", err);
      };
      
      console.log("check");
      console.log(this.topics[topicInd].questions[questionInd]);

    }, */




    async updateStatus() {
      let tx = this.db.transaction("questions", "readwrite");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };
      let dataToUpdate = {
        id: "currentStatus",
        currentTopic: this.topicIndex,
        currentQuestion: this.questionIndex,
        currentDuration: this.refreshDuration,
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.put(dataToUpdate);



      data.onsuccess = (ev) => {
        console.log("status updated successfully", ev);
      };

      data.onerror = (ev) => {
        console.log("error updating the status", ev);
      };

      let times = await dbData.get("timerObject");

      let lastObj = {}

      times.onsuccess = async (ev) => {
            let final = ev.target.result;
            let newObj = {...final[this.topicId]}
            if(newObj){
              let temp ={
                ...newObj,
                timer:this.refreshDuration
              }
              lastObj = {...final, [this.topicId]:temp}
            }
            console.log('lastObj', lastObj);
            this.timerObjectFinal = lastObj
            console.log('final', final);
        }
        
      times.onerror = (ev) => {
        console.log("error updating the status times", ev);
      };

      let timerObject = {
         ...lastObj
       }

       console.log('lastObjlastObj', lastObj);

       console.log('lastObj2', this.timerObjectFinal);
       console.log('timerObject', timerObject);

     let dataTimer = await dbData.put(this.timerObjectFinal);

     dataTimer.onsuccess = (ev) => {
        console.log("status updated successfully dataTimer", ev);
      };

      dataTimer.onerror = (ev) => {
        console.log("error updating the status dataTimer", ev);
      };
    },

    timers(duration, topicId) {
      if (duration) {
        // this.response_Duration = duration
        // console.log('duration----', topicId);
        this.topicId = topicId
        var timeleft = duration * 60;
        this.time = setInterval(() => {
          timeleft = timeleft - 1;
          this.refreshDuration = timeleft == -1 ? 1 : timeleft;
          this.updateStatus();
          let timeFormat = `${
            Math.floor(timeleft / 60) < 10
              ? `0${Math.floor(timeleft / 60)}`
              : `${Math.floor(timeleft / 60)}`
          } : ${
            Math.floor(timeleft % 60) < 10
              ? `0${Math.floor(timeleft % 60)}`
              : `${Math.floor(timeleft % 60)}`
          }`;
          if (timeleft == -1) {
            if (this.topicIndex != this.topics.length - 1) {
              clearInterval(this.time);
              this.time = null;
              this.topicIndex = this.topicIndex + 1;
              this.questionIndex = 0;
              this.updateStatus();
            } else {
              this.updateStatus();
              clearInterval(this.time);
              this.submitTest();
            }
          } else document.getElementById("timers").innerHTML = timeFormat;
        }, 1000);
      }
    },


    popUp(){
      this.$swal({
        title: 'Are you sure?',
        text: "Submit button will end the test. If you like to end it, press Submit",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          // this.first_submit = false
          // this.submitTest()
          $(this.$refs.vueModal).modal('show');
          checkMinimumLines(topicIndex, questionIndex)
  }else{
    this.resumeClick()
  }
})
    },

    readMore(qno) {
      let groupQuestion = document.getElementById(`group${qno}`);
      groupQuestion.style.display =
        groupQuestion.style.display == "none" ? "block" : "none";

      let readMore = document.getElementById(`readMore${qno}`);
      readMore.innerHTML =
        groupQuestion.style.display == "none" ? "read more" : "read less";
    },

    resumeClick() {
      document.getElementById("modalClose").click();
    },
    async modalData() {
      let tx = this.db.transaction("questions", "readonly");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.getAll();

      data.onsuccess = (ev) => {
        console.log("status updated successfully", ev);
        var unattempted = 0;
        var answered = 0;
        var remarks = 0;
        ev.target.result.forEach((topic) => {
          if (topic.id != "currentStatus" && topic.id != "finalObject") {
            topic.questions.forEach((question) => {
              if ((question.userAnswer == null && question.source_code == null) && question.remarks == null) {
                unattempted = unattempted + 1;
              }else if (question.remarks == 1) {
                remarks = remarks + 1;
              } else {
                answered = answered + 1;
              }
            });
          }
        });
        console.log(remarks);
        document.getElementById("answered").innerHTML = answered;
        document.getElementById("unattempted").innerHTML = unattempted;
        document.getElementById("remarks").innerHTML = remarks;
      };

      data.onerror = (ev) => {
        console.log("error updating the status", ev);
      };
    },

    async submitTest() {
      // if(this.first_submit == true){
      //   this.first_submit = false;
      //  return this.$swal("Warning","Submit button will end the test. If you like to end it, press Submit","warning");
      // }
      let timelefter = 0
      this.loader = true;
      document.getElementById("modalClose").click();

      let tx = this.db.transaction("questions", "readonly");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.get("finalObject");

      data.onsuccess = async (eve) => {
        let tx1 = this.db.transaction("questions", "readwrite");
        tx1.oncomplete = (ev) => {
          console.log(ev);
        };

        tx1.onerror = (err) => {
          console.log(err);
        };

        var today = new Date();

        var date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();

        var time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();

        var dateTime = date + " " + time;
        let dataToUpdate = eve.target.result;
        dataToUpdate.end_date_time = dateTime;

        let dbData1 = await tx1.objectStore("questions");
        console.log(dbData1);
        let data1 = await dbData1.put(dataToUpdate);
        console.log(dbData1);

        data1.onsuccess = async (ev) => {
          console.log(ev);

          let tx = this.db.transaction("questions", "readonly");
          tx.oncomplete = (ev) => {
            console.log(ev);
          };

          tx.onerror = (err) => {
            console.log(err);
          };


          let dbData = await tx.objectStore("questions");
          let idData = await dbData.getAll();
          let dataTimer = await dbData.get("timerObject");
          // let durationTime = await dbData.get("currentStatus")
          let data = await dbData.get("finalObject");

          dataTimer.onsuccess = async (ev) => {
            let temp =  ev.target.result
            let data = Object.values(temp)
            data.splice(data?.length - 1, 1)
            let originalDuration = data.reduce((acc,cur) => acc + cur.duration, 0) * 60 
            let currentDuration = data.reduce((acc,cur) => acc + cur.timer, 0);
            let timeleft = originalDuration -  currentDuration
            var hours = Math.floor(timeleft/3600);
            (hours >= 1) ? timeleft = timeleft - (hours*3600) : hours = '00';
            var min = Math.floor(timeleft/60);
            (min >= 1) ? timeleft = timeleft - (min*60) : min = '00';
            (timeleft < 1) ? timeleft='00' : void 0;
            (min.toString().length == 1) ? min = '0'+min : void 0;    
            (timeleft.toString().length == 1) ? timeleft = '0'+timeleft : void 0;    
            let timeFormat = Math.floor(hours)+' hr,'+Math.floor(min)+'min,'+Math.floor(timeleft)+'sec'

            // console.log('timeFormat',timeFormat);
            
            this.durationNew = timeFormat
          }

       
          idData.onsuccess = async (ev) => {
          ev.target.result.forEach((data) => {
         if (data.id != "currentStatus" && data.id != "finalObject" && data.id != "timerObject") {
           console.log('datasssss', data);
           this.response_Duration = data.duration
          }
           });
          }

          // durationTime.onsuccess = async (ev) => {

          //   let originalDuration = this.response_Duration * 60 

          //   let currentDuration = ev.target.result.currentDuration
            
          //   let timeleft = originalDuration - currentDuration

          //   // console.log('rlesss',this.response_Duration);
          //   // console.log('timeleft', timeleft);
            
          //   var hours = Math.floor(timeleft/3600);
          //   (hours >= 1) ? timeleft = timeleft - (hours*3600) : hours = '00';
          //   var min = Math.floor(timeleft/60);
          //   (min >= 1) ? timeleft = timeleft - (min*60) : min = '00';
          //   (timeleft < 1) ? timeleft='00' : void 0;

          //   (min.toString().length == 1) ? min = '0'+min : void 0;    
          //   (timeleft.toString().length == 1) ? timeleft = '0'+timeleft : void 0;    

          //   // console.log(hours+' hr:'+min+'min:'+timeleft+'sec')

          //   let timeFormat = Math.floor(hours)+' hr,'+Math.floor(min)+'min,'+Math.floor(timeleft)+'sec'

          //   // this.durationNew = timeFormat
          // }


          // console.log('durationNew',this.durationNew);

          const formdata = new FormData();

          data.onsuccess = async (ev) => {
            let final = ev.target.result;
            console.log(this.language);
            console.log(final.topic);


            
            // formdata.append("language",'php' );
            formdata.append("test_id", final.test_id);
            formdata.append("start_date_time", final.start_date_time);
            formdata.append("end_date_time", final.end_date_time);
            formdata.append("duration", this.durationNew);
            for (var i = 0; i < final.topic.length; i++) {
             // let source_code = source_code;
              formdata.append(
                "topic[" + i + "][topic_id]",
                final.topic[i].topic_id
              );
              formdata.append(
                "topic[" + i + "][no_of_questions]",
                final.topic[i].no_of_questions
              );
              for (var j = 0; j < final.topic[i].questions.length; j++) {
                formdata.append(
                  "topic[" + i + "][questions][" + j + "][question_id]",
                  final.topic[i].questions[j].question_id
                );
                formdata.append(
                  "topic[" + i + "][questions][" + j + "][question_type]",
                  final.topic[i].questions[j].question_type
                );
                formdata.append(
                  "topic[" + i + "][questions][" + j + "][option_id]",
                  final.topic[i].questions[j].option_id
                );
                formdata.append(
                  "topic[" + i + "][questions][" + j + "][user_answer]",
                  final.topic[i].questions[j].user_answer
                );
                formdata.append(
                  "topic[" + i + "][questions][" + j + "][language]",
                  final.topic[i].questions[j].language
                );
                formdata.append(
                  "topic[" + i + "][questions][" + j + "][source_code]",
                  final.topic[i].questions[j].source_code
                );
                if(final.topic[i].questions[j].source_code != null){
                  formdata.append(
                    "topic[" + i + "][questions][" + j + "][user_answer]",
                    final.topic[i].questions[j].source_code
                  );
                }
              }
              console.log(formdata);
            console.log(final.topic);
            }
            await axios
              .post(
                `${this.$store.getters.baseUrl}/mock-tests/submit-test`,
                formdata,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then(async (response) => {
                console.log('response', response);
                if (response.data.status == "success") {
                  clearInterval(this.time);
                  await this.db.close();
                  var req = indexedDB.deleteDatabase("QuestionsDB");
                  req.onsuccess = function(e) {
                    console.log("Deleted database successfully", e);
                  };
                  req.onblocked = async function(e) {
                    await this.db.close();
                    console.log("blocked", e);
                  };
                  req.onerror = function(e) {
                    console.log("Couldn't delete database", e);
                    return;
                  };
                  if (response.data.test_status == 3) {
                    this.submitAPI = 1;
                    this.loader = false;
                    this.$router.push({ name: "Home" });
                    window.location.reload();
                    this.$swal("Success", response.data.message, "success");
                  } else {
                     this.loader = false;
                    this.submitAPI = 1;
                    this.$router.push({ name: "Home" });
                    this.$swal({
                      title: "Success",
                      icon: "success",
                      text: response.data.message,
                      confirmButtonText: "View Result",
                      cancelButtonText: "Ok",
                      showCancelButton: true,
                      reverseButtons: true,
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.submitAPI = 1;
                        this.$router.push({
                          name: "Result",
                          params: { testId: response.data.user_test_id },
                        });
                      } else {
                        this.submitAPI = 1;
                        this.$route.name != "Home"
                          ? this.$router.push({ name: "Home" })
                          : "";
                        window.location.reload();
                      }
                    });
                  }
                } else {
                  console.log('responseErr');
                   this.loader = false;
                  this.$swal("Error", response.data.message, "warning");
                }
              })
              .catch((error) => {
                 this.loader = false;
                console.log(error);
                this.$swal("Error", error.response.data.message, "error");
              });
          };
        dataTimer.onerror = (err) => {
           console.log(err);
         };
        };

        data1.onerror = (err) => {
          console.log(err);
        };

        data.onerror = (err) => {
        console.log(err);
      }; 
       
      };


    },
  },

  /*beforeRouteLeave(to, from, next) {
    if (this.$route.params.type != "practice-test") {
      if (to.name == "Home") {
        next();
      } else {
        this.$swal(
          "Warning",
          "Please complete the test before moving to other screens",
          "warning"
        );
        next(false);
      }
    } else {
      if (this.submitAPI == 1) {
        if (to.name == "Home") {
          next();
        }
      } else {
        this.$swal({
          title: "Pause Test",
          icon: "warning",
          text: "You shall pause the test and continue at your convenience",
          confirmButtonText: "Pause & Exit",
          cancelButtonText: "Continue Test",
          showCancelButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.isConfirmed) {
            let playpauseButton = document.getElementById("pas-play");
            playpauseButton.className = "fas fa-play";
            clearInterval(this.time);
            next();
          }
          if (result.isDismissed) {
            let playpauseButton = document.getElementById("pas-play");
            playpauseButton.className = "fas fa-pause";
            this.refresh();
          }
        });
      }
    }
  }, */

  beforeRouteLeave(to, from, next) {
      if (this.submitAPI == 1) {
        if (to.name == "Home") {
          next();
        }
      } else {
        // this.$swal({
        //   title: "Test Submited",
        //   icon: "warning",
        //   // text: "You shall Submit the test Now !",
        //   text: "Your Test has been Auto Submitted!",
        //   showConfirmButton: false,
        //   // confirmButtonText: "Submit",
        //   cancelButtonText: "Continue Test",
        //   showCancelButton: false,
        //   reverseButtons: true,
        //   allowOutsideClick: false,
        //   allowEscapeKey: false,
        //   timer:2000
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     this.first_submit = false;
        //     // this.submitTest();
        //   }
        //   if (result.isDismissed) {
        //     let playpauseButton = document.getElementById("pas-play");
        //     playpauseButton.className = "fas fa-pause";
        //     this.refresh();
        //   } 
        // });
        this.first_submit = false
        vue.swal({
              title: "Test Submited",
              icon: "warning",
              text: 'Your Test has been Auto Submitted!',
              showCancelButton: false,
              reverseButtons: false,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              timer: 1500,
            }).then((res)=>{
              this.submitTest();
            })
      }
  },

  beforeDestroy() {
    console.log("checking");
    if (this.time) clearInterval(this.time);
  },

};
</script>

<style scoped>
.read-more {
  font-size: 15px;
  color: #fff;
  background: linear-gradient(#0530ba, #021b79);
  border-radius: 60px;
  border: 2px solid #0530ba;
  transition: 0.2s all linear;
  text-transform: uppercase;
  font-weight: 600;
  /* display: inherit; */
  text-align: center;
  padding: 6px 15px;
  height: fit-content;
}

.read-more:hover {
  transition: 0.2s all linear;
  border: 2px solid #0530ba;
  background: #e2e5ff;
  color: #0530ba;
}

dl dt {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  border-radius: 50%;
  border: 2px solid #0530ba !important;
}

dl dd {
  display: inline-block;
  margin: 0px 10px;
  padding-bottom: 0;
  vertical-align: middle;
}

dl dt.red {
  background: #0530ba;
}

dl dt.yellow {
  background: #fff;
  border-color: #0530ba;
}

dl dt.rem {
  background: #c98c30;
  border-color: #0530ba;
}

.question-img {
  display: block !important;
}

/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}

/* .prism-editor-wrapper .prism-editor__container {
    height: 200px !important;
} */
</style>