var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('section',{staticClass:"other-banner"},[_c('div',{staticClass:"banner-content"},[_c('div',{staticClass:"banner-caption"},[_c('h1',[_vm._v(_vm._s(_vm.$route.params.subscriptionName))]),_c('div',{staticClass:"banner-btn mt-1 text-dark"},[_c('nav',{staticClass:"flex-sm-00-auto ml-sm-3",attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb breadcrumb-alt"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_vm._v("Home")])],1),(_vm.$route.params.categoryName)?_c('li',{staticClass:"breadcrumb-item",attrs:{"aria-current":"page"}},[_c('router-link',{attrs:{"to":{
                    name: 'Placement Prep',
                    params: {
                      categoryId: _vm.$route.params.categoryId,
                      categoryName: _vm.$route.params.categoryName,
                    },
                  }}},[_vm._v(_vm._s(_vm.$route.params.categoryName))])],1):_vm._e(),_c('li',{staticClass:"breadcrumb-item",attrs:{"aria-current":"page"}},[_c('router-link',{attrs:{"to":{ name: 'Subscription Description' }}},[_vm._v(_vm._s(_vm.$route.params.subscriptionName))])],1),_c('li',{staticClass:"breadcrumb-item",attrs:{"aria-current":"page"}},[_c('router-link',{attrs:{"to":{ name: 'Practice Test List' }}},[_vm._v("Practice Test List")])],1)])])])])])]),_c('section',{staticClass:"plac-pre-prac-tst mt-4 my-2"},[_c('ul',{staticClass:"nav nav-tabs plac-prep-nav-link",attrs:{"id":"myTab","role":"tablist"}},_vm._l((_vm.parentTopics),function(parentTopic,index){return _c('li',{key:index,staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{staticClass:"nav-link",attrs:{"id":"home-tab","data-bs-toggle":"tab","data-bs-target":"#home","type":"button","role":"tab","aria-controls":"home","aria-selected":"true"},on:{"click":function($event){(_vm.parentTopicId = parentTopic.id), _vm.getSubtopics()}}},[_vm._v(" "+_vm._s(parentTopic.topic_name)+" ")])])}),0),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"home","role":"tabpanel","aria-labelledby":"home-tab"}},[_c('section',{staticClass:"tl-lst-tst pt-0 pb-2"},_vm._l((_vm.subtopics),function(subtopic,index){return _c('div',{key:index,staticClass:"list-test",attrs:{"href":""}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"lft-lst-tst flex-grow-1 pe-4"},[_c('h5',[_vm._v(_vm._s(subtopic.topic.name))]),_c('p',{staticClass:"text-primary"},[_vm._v(_vm._s(subtopic.topic.description))]),_c('router-link',{attrs:{"to":{
                    name: 'Practice Tests',
                    params: {
                      subscriptionId: _vm.$route.params.subscriptionId,
                      subscriptionName: _vm.$route.params.subscriptionName,
                      topicName: subtopic.topic.name,
                      topicId: subtopic.id,
                      categoryName: _vm.$route.params.categoryName,
                      categoryId: _vm.$route.params.categoryId,
                    },
                  }}},[_c('div',{staticClass:"d-flex"},[(
                        _vm.userSubscriptionStatus == 1 || subtopic.is_free == '1'
                      )?_c('p',{staticClass:"lock-vid"},[_c('i',{staticClass:"fas fa-angle-right"})]):_vm._e()])]),_c('div',{staticClass:"d-flex"},[_c('a',{attrs:{"href":""}}),(
                      _vm.userSubscriptionStatus != 1 && subtopic.is_free == 0
                    )?_c('p',{staticClass:"lock-vid"},[_c('router-link',{attrs:{"to":{ name: 'Subscription' }}},[_c('i',{staticClass:"fas fa-lock"})])],1):_vm._e()])],1),_c('div',{staticClass:"rt-comp-img d-grid sub-img"},[_c('img',{attrs:{"src":subtopic.topic.image,"alt":""}})])])])}),0)])])]),_c('b-overlay',{staticClass:"position-fixed",attrs:{"show":_vm.loader,"no-wrap":"","blur":"2px","opacity":"0.60"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('i',{staticClass:"fa fa-spinner fa-pulse fa-4x fa-fw text-primary"})]},proxy:true}])}),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }