<template>
  <div>
    <section class="other-banner">
      <Header />
      <div class="banner-content">
        <div class="banner-caption py-3">
          <h1>Company Specific Modules</h1>
          <div class="banner-btn mt-1">
            <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
            <a class="lst-a mx-1">Company Specific Modules</a>
          </div>
          <button class="btn-sub-trail mt-3">Buy Combo</button>
        </div>
      </div>
    </section>
    <section class="tl-lst-tst">
      <div class="in-list-test">
        <a href="placement-prep-description.php" class="list-test text-dark">
          <div class="d-flex">
            <div class="lft-lst-tst flex-grow-1 pe-4">
              <h5>Wipro Company Papers</h5>
              <h4><sapan>₹</sapan>652</h4>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sunt
                in, aspernatur eveniet neque
              </p>
            </div>
            <div class="rt-comp-img d-grid">
              <img src="../../src/assets/img/test/2.png" alt="" />
            </div>
          </div>
          <router-link
            :to="{ name: 'Subscription Description' }"
            class="viw-dwn h6 py-2"
            style="width: fit-content"
            ><b>Try Test</b></router-link
          >
        </a>
        <!-- <a href="placement-prep-description.php" class="try-a-test">Try Test</a> -->
      </div>
      <div class="in-list-test">
        <a href="placement-prep-description.php" class="list-test text-dark">
          <div class="d-flex">
            <div class="lft-lst-tst flex-grow-1 pe-4">
              <h5>Wipro Company Papers</h5>
              <h4><sapan>₹</sapan>652</h4>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sunt
                in, aspernatur eveniet neque
              </p>
            </div>
            <div class="rt-comp-img d-grid">
              <img src="../../src/assets/img/test/1.png" alt="" />
            </div>
          </div>
          <router-link :to="{ name: 'Subscription Description' }" class="viw-dwn h6 py-2" style="width: fit-content">
            <b>Try Test</b>
          </router-link>
        </a>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Profile",
  components: {
    Header,
    Footer,
  },
};
</script>