<template>
  <div>
    <section class="other-banner">
      <Header />
      <div class="banner-content">
        <div class="banner-caption">
          <h1 style="margin-left:12%;">Videos</h1>
          <div
            style="position: relative; text-align: center; width: 70%; margin-left: 12%;"
            id="youtubeVideo"
            v-if="youtubeLink"
            v-show="!show"
          >
            <LazyYoutube
              ref="youtubeLazyVideo"
              :src="youtubeLink"
              aspect-ratio="16:9"
              thumbnail-quality="standard"
              allowfullscreen="0"
            />
            <div
              style="position: absolute; top:0; left:0; width:100%; height:88.2%;"
            ></div>
            <div
              style="position: absolute; bottom:0; right:0px; width:25%; height:10%;"
            ></div>
            <!-- v-show works with style="display:none;" -->

            <div
              class="buttons"
              style="position: absolute; bottom: -45px; left: 37%;"
            >
              <button
                class="play_btn"
                :class="{ play_btns: buttonCol == 1 }"
                @click="handleClick('playVideo', 'youtubeLazyVideo', 1)"
              >
                Play
              </button>
              <button
                class="pause_btn"
                :class="{ pause_btns: buttonCol == 2 }"
                @click="handleClick('pauseVideo', 'youtubeLazyVideo', 2)"
              >
                Pause
              </button>
              <button
                class="reset_btn"
                :class="{ reset_btns: buttonCol == 3 }"
                @click="handleClick('resetView', 'youtubeLazyVideo', 3)"
              >
                Reset
              </button>
            </div>
          </div>
          <div class="banner-btn mt-1 text-dark">
            <!--           <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
            <a class="lst-a mx-1">Videos</a> -->

            <nav class="flex-sm-00-auto ml-sm-3" aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-alt" style="margin-left:12%;">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Live Sessions' }"
                    >Live Sessions</router-link
                  >
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>

    <section class="tl-lst-tst pt-0">
      <div class="in-list-test text-secondary">
        <div
          data-bs-toggle="modal"
          data-bs-target="#vidoe-pop"
          class="list-test cursor-pointer mb-4"
          v-for="(session, index) in liveSessions"
          :key="index"
        >
          <div class="d-flex">
            <div class="lft-lst-tst flex-grow-1 pe-4">
              <h5>{{ session.name }}</h5>
              <p v-if="session.is_live == 1" class="live-p">Live</p>
              <p v-else class="record-p">Recorded</p>
              <!-- <div
                class="modal fade video-pop-up"
                id="vidoe-pop"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                   <div class="modal-dialog modal-dialog-centered m-auto">
                    <div class="modal-content bg-none">
                    <iframe
                      width="640"
                      height="415"
                      :src="
                        baseYoutubeUrl +
                          session.youtube_id +
                          '?autoplay=1&version=3&enablejsapi=1&showinfo=0&rel=0&showinfo=0&disablekb=1&iv_load_policy=3&modestbranding=1&controls=1'
                      "
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                      style="pointer-events:none"
                    ></iframe>
                  </div>
                </div>
                 
              </div> -->
            </div>
            <div class="m-auto play-i-live" @click="video(session.video_url)">
              <i class="fas fa-play"></i>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { LazyYoutube } from "vue-lazytube";
import axios from "axios";
export default {
  name: "Profile",
  components: {
    Header,
    Footer,
    LazyYoutube,
  },
  data() {
    return {
      liveSessions: [],
      baseYoutubeUrl: "https://www.youtube-nocookie.com/embed/",
      loader: false,
      show: false,
      youtubeLink: "",
      youtubeId: "",
      buttonCol: 0,
    };
  },
  mounted() {
    this.getLiveSessions();
  },
  methods: {
    video(video_url) {
      (this.youtubeLink = video_url), console.log("Hi" + this.youtubeLink);
      document.getElementById(`youtubeVideo`).show("show");
      this.buttonCol = 0;
    },
    handleClick(event, ref, col) {
      this.$refs[ref][event]();
      this.buttonCol = col;
    },

    //   if (type == "play") {
    //     console.log("checking");

    //     this.cyoutubeVideo[0].contentWindow.postMessage(
    //       '{"event":"command","func":"' + "playVideo" + '","args":""}',
    //       "*"
    //     );
    //   } else if (type == "pause") {
    //     document
    //       .getElementById(`c-youtubeVideo`)[0]
    //       .contentWindow.postMessage(
    //         '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
    //         "*"
    //       );
    //   } else {
    //     document
    //       .getElementById(`c-youtubeVideo`)
    //       .contentWindow.postMessage(
    //         '{"event":"command","func":"' + "stopVideo" + '","args":""}',
    //         "*"
    //       );
    //   }
    // },
    async getLiveSessions() {
      this.loader = true;
      await axios
        .get(
          `${this.$store.getters.baseUrl}/live-sessions`,

          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.liveSessions = response.data.sessions;
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>

<style>
.play_btn {
  background-color: dodgerblue;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: dodgerblue;
}

.play_btns {
  background-color: dodgerblue;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: dodgerblue;
  opacity: 0.5;
}

.pause_btn {
  background-color: green;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: green;
}

.pause_btns {
  background-color: green;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: green;
  opacity: 0.5;
}

.reset_btn {
  background-color: slategray;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: slategray;
}

.reset_btns {
  background-color: slategray;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  border: slategray;
  opacity: 0.5;
}
</style>
