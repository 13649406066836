<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script src="/path/highlight.min.js"></script>
<script src="/path/code_editor.prod.js"></script>
<script>
export default {
  name: "App",
};
</script>
