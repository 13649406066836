<template>
  <div>
    <Header />
    <section class="other-banner">
      <div class="banner-content">
        <div class="banner-caption">
          <h1>My Subscription</h1>
          <div class="banner-btn mt-1 text-dark">
            <router-link :to="{ name: 'Home' }">
              <a class="lst-a me-1 text-primary">Home</a>
            </router-link>
            /
            <a class="lst-a mx-1"> My Subscription</a>
          </div>
        </div>
      </div>
    </section>

    <section class="plac-pre-prac-tst my-2">
      <div class="container m-auto">
        <ul class="nav nav-tabs plac-prep-nav-link" id="myTab" role="tablist">
          <!-- Active Button Starts -->
          <li class="nav-item" role="presentation">
            <button
              @click="getActiveSubscriptions()"
              class="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Active
            </button>
          </li>
          <!-- Active Button Ends  -->
          <!-- History Button Starts  -->
          <li class="nav-item" role="presentation">
            <button
              @click="getInactiveSubscriptions()"
              class="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              History
            </button>
          </li>
          <!-- History Button Ends Here  -->
        </ul>
        <!-- Active Tab Starts Here -->
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div class="row subs-page">
              <div class="col-lg-7 col-md-7">
                <div class="package-subscription">
                  <div class="row">
                    <div
                      class="col-md-6 p-2"
                      v-for="(subscription, index) in activeSubscriptions"
                      :key="index"
                    >
                      <div class="price-det">
                        <h4>{{ subscription.sub.name }}</h4>
                        <ul>
                          <li>
                            <a
                              ><i class="fas fa-pencil-ruler"></i
                              >{{ subscription.sub.description }}</a
                            >
                          </li>
                        </ul>
                      </div>
                      <div class="price-list">
                        <h4>₹ {{ subscription.sub.price }}</h4>
                        <p>Validity {{ subscription.sub.validity }} days</p>
                        <router-link
                          :to="{
                            name: 'Subscription Description',
                            params: {
                              subscriptionId: subscription.sub.id,
                              subscriptionName: subscription.sub.name,
                              categoryName: $route.params.categoryName,
                              categoryId: $route.params.categoryId,
                            },
                          }"
                          class="mt-2"
                        >
                          View Details
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="subscription-image">
                  <img
                    src="../../src/assets/img/subscription.png"
                    alt="subscription"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Active Tab End Here -->
          <!-- History Tab Start Here -->

          <div
            class="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div class="row">
              <div
                class="col-md-4 p-2"
                v-for="(subscription, index) in inactiveSubscriptions"
                :key="index"
              >
                <div class="price-det">
                  <h4>{{ subscription.sub.name }}</h4>
                  <ul>
                    <li>
                      <a
                        ><i class="fas fa-pencil-ruler"></i
                        >{{ subscription.sub.description }}</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="price-list">
                  <h4>₹ {{ subscription.sub.price }}</h4>
                  <p>Validity {{ subscription.sub.validity }} days</p>
                  <button>View Details</button>
                </div>
              </div>
            </div>
          </div>
          <!-- History Tab End -->
        </div>
      </div>
    </section>
    <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      activeSubscriptions: [],
      inactiveSubscriptions: [],
      loader: false,
    };
  },
  created() {
    this.getActiveSubscriptions();
  },
  methods: {
    async getActiveSubscriptions() {
      this.loader = true;
      await axios
        .get(`${this.$store.getters.baseUrl}/user_Subscriptions`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.activeSubscriptions = response.data.user_subscription;
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          // console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getInactiveSubscriptions() {
      await axios
        .get(`${this.$store.getters.baseUrl}/user_History`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.inactiveSubscriptions = response.data.user_subscription;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          //console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
