var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"other-banner"},[_c('Header'),_vm._m(0)],1),_c('section',{staticClass:"plac-pre-prac-tst mt-4 my-2"},[_c('ul',{staticClass:"nav nav-tabs plac-prep-nav-link",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{staticClass:"nav-link",attrs:{"id":"home-tab1","data-bs-toggle":"tab","data-bs-target":"#home","type":"button","role":"tab","aria-controls":"home","aria-selected":"true"},on:{"click":function($event){(_vm.type = 'ongoing'), _vm.getRecruitments()}}},[_vm._v(" Ongoing ")])]),_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{staticClass:"nav-link",attrs:{"id":"home-tab","data-bs-toggle":"tab","data-bs-target":"#home","type":"button","role":"tab","aria-controls":"home","aria-selected":"true"},on:{"click":function($event){(_vm.type = 'upcoming'), _vm.getRecruitments()}}},[_vm._v(" Upcoming ")])])])]),_c('section',{staticClass:"tl-lst-tst"},_vm._l((_vm.type == 'ongoing'
        ? _vm.recruitments
        : _vm.recruitmentsUpcoming),function(recruitment,index){return _c('div',{key:index,staticClass:"in-list-test"},[_c('div',{staticClass:"list-test"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"lft-lst-tst flex-grow-1 pe-4"},[_c('h5',[_vm._v(_vm._s(recruitment.name))]),(_vm.type == 'upcoming')?_c('p',{},[_vm._v(" "+_vm._s(recruitment.created_at)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex"},[(_vm.type == 'ongoing')?_c('router-link',{staticClass:"mt-2",attrs:{"id":"ongoing","to":{
                  name: 'Subscription Mock Test Details',
                  params: { testId: recruitment.id, testType: 'ongoing' },
                }}},[_c('p',{staticClass:"viw-dwn h6 py-2 mt-2",staticStyle:{"width":"fit-content"}},[_c('b',[_vm._v("View Details")])])]):_vm._e(),(_vm.type == 'upcoming')?_c('router-link',{staticClass:"mt-2",attrs:{"to":{
                  name: 'Subscription Description',
                  params: {
                    subscriptionName: recruitment.subscription.name,
                    subscriptionId: recruitment.subscription.id,
                  },
                }}},[_c('p',{staticClass:"viw-dwn h6 py-2 mt-2",staticStyle:{"width":"fit-content"}},[_c('b',[_vm._v("View Details")])])]):_vm._e()],1)]),_c('div',{staticClass:"rt-comp-img d-grid"},[_c('img',{attrs:{"src":recruitment.image,"alt":""}})])])])])}),0),_c('b-overlay',{staticClass:"position-fixed",attrs:{"show":_vm.loader,"no-wrap":"","blur":"2px","opacity":"0.60"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('i',{staticClass:"fa fa-spinner fa-pulse fa-4x fa-fw text-primary"})]},proxy:true}])}),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-content"},[_c('div',{staticClass:"banner-caption py-3"},[_c('h1',[_vm._v("Recruitment Drive Tests")]),_c('div',{staticClass:"banner-btn mt-1 text-dark"})])])
}]

export { render, staticRenderFns }