<template>
<div>
<section class="other-banner"
    style="background: url('../../src/assets/img/test/banner.jpg') no-repeat;background-position: center;min-height:auto;">
    <Header />
    <!-- <div class="banner-content">
        <div class="banner-caption">
            <h1>Profile</h1>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Error quaerat alias beatae laboriosam nobis
                tempore
                harum eos architecto illo laborum iure deleniti sunt</p>
            <div class="banner-btn mt-1">
                <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
                <a class="lst-a mx-1">Profile</a>
            </div>
            <button class="btn-sub-trail mt-3">Edit</button>
        </div>
    </div> -->
</section>
<section class="d-flex justify-content-center p-3">
    <aside class="my-auto" style="width: 430px;">
        <table class="table table-bordered text-center">
            <thead>
                <tr>
                    <th class="tit-text" scope="col">Section</th>
                    <th class="tit-text" scope="col">Questions</th>
                    <th class="tit-text" scope="col">Duration (Min)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">Q.A</th>
                    <td>50</td>
                    <td>1:30 Hrs</td>
                </tr>
                <tr>
                    <th scope="row">Verbal</th>
                    <td>40</td>
                    <td>1:15 Hrs</td>
                </tr>
            </tbody>
        </table>
        <article class="detail-test-dur">
            <div class="d-flex justify-content-start">
                <p class="left-data-text py-1">Duration</p>
                <p class="py-1">: 40 Mins</p>
            </div>
            <div class="d-flex justify-content-start">
                <p class="left-data-text py-1">No. of Questions</p>
                <p class="py-1">: 40 Mins</p>
            </div>
            <div class="d-flex justify-content-start">
                <p class="left-data-text py-1">Available Form</p>
                <p class="py-1">: - </p>
            </div>
            <div class="d-flex justify-content-start">
                <p class="left-data-text py-1">End By</p>
                <p class="py-1">: - </p>
            </div>
            <div class="d-flex justify-content-start">
                <p class="left-data-text py-1">Navigative Marking</p>
                <p class="py-1">: 05 </p>
            </div>
        </article>
        <router-link :to="{ name: 'Questions' }" class="a-radious mt-2">
          Start Test
        </router-link>
    </aside>
    <aside class="my-auto mx-5">
        <img style="width: 380px;" src="../../src/assets/img/test/test-img.png" alt="">
    </aside>
</section>
<Footer />
</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
    components: {
        Header,
        Footer
    }
}
</script>