<template>
  <div>
    <section class="other-banner">
      <Header />
      <div class="banner-content">
        <div class="banner-caption">
          <h1>Test Result</h1>
          <div class="banner-btn mt-1 text-dark">
            <!--   <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
            <a class="lst-a mx-1">Test Result</a>
            -->
            <nav class="flex-sm-00-auto ml-sm-3" aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-alt">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link>E-Libray</router-link>
                </li>
              </ol>
            </nav>
          </div>
          <!-- <button class="btn-sub-trail mt-3">Edit</button> -->
        </div>
      </div>
    </section>

    <section class="plac-pre-prac-tst mt-4 my-2">
      <!-- Parent topics  -->
      <ul class="nav nav-tabs plac-prep-nav-link" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            @click="(testType = 'practice_test'), getresult()"
            class="nav-link"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            Practice Tests
          </button>
        </li>

        <li class="nav-item" role="presentation">
          <button
            @click="(testType = 'subscription_mock_test'), getresult()"
            class="nav-link"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            Mock Tests
          </button>
        </li>

        <li class="nav-item" role="presentation">
          <button
            @click="(testType = 'daily_test'), getresult()"
            class="nav-link"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            Daily Challenges
          </button>
        </li>

        <li class="nav-item" role="presentation">
          <button
            @click="(testType = 'all_india_mock_test'), getresult()"
            class="nav-link"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            All India Mock Tests
          </button>
        </li>

      </ul>
      <!-- End of parent topic -->
    </section>

    <section class="tl-lst-tst text-center d-flex d-test-arrow">
      <button
        class="nav-item"
        style="height: 50px;"
        @click="
          (month = month == 0 ? 11 : month - 1),
            (year = month == 11 ? year - 1 : year),
            getresult()
        "
      >
        <i class="fas fa-angle-left"></i>
      </button>
      <h3 class="lft-lst-tst flex-grow-1 ps-4">
        {{ months[month] }} - {{ year }}
      </h3>
      <button
        class="nav-item"
        style="height: 50px;"
        @click="
          (month = month == 11 ? 0 : month + 1),
            (year = month == 0 ? year + 1 : year),
            getresult()
        "
      >
        <i class="fas fa-angle-right"></i>
      </button>
    </section>

    <section class="tl-lst-tst">
      <div v-if="results">
        <div
          class="in-list-test"
          v-for="(result, index) in results"
          :key="index"
        >
          <router-link
            :to="{ name: 'Result', params: { testId: result.id } }"
            class="list-test"
          >
            <a>
              <div class="d-flex">
                <div class="lft-lst-tst flex-grow-1 ps-4">
                  <h5>{{ result.name }}</h5>
                  <p class="mt-1 text-primary mt-3">
                    Rank: {{ result.rank ? result.rank : "Not Available" }}
                  </p>
                </div>
                <div class="d">
                  <h3 class="text-dark">{{ result.pass_percentage }}%</h3>
                  <p class="mt-2">
                    {{ result.attended_date }}
                  </p>
                </div>
              </div>
            </a>
          </router-link>
          <router-link
            :to="{ name: 'Result', params: { testId: result.id } }"
            class="try-a-test"
          >
            <a>View Report</a>
          </router-link>
        </div>
      </div>
      <div v-else>
        <p>No Data Found</p>
      </div>
    </section>
    <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      results: [],
      testType: "daily_test",
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      loader: false,
    };
  },
  mounted() {
    this.getresult();
  },
  methods: {
    async getresult() {
      this.loader = true;
      console.log(this.testType);
      console.log(this.month);
      console.log(this.year);

      const formdata = new FormData();
      formdata.append("type", this.testType);
      formdata.append("year", this.year);
      formdata.append("month", this.month + 1);

      await axios
        .post(
          `${this.$store.getters.baseUrl}/mock-tests/test-results`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.results = response.data.results;
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
<style scoped>
.d-test-arrow button {
  background: #f5f5f5;
  border: 3px solid #0328a2;
  padding: 10px 30px;
  border-radius: 100px;
  transition: all 0.6s ease;
}
.d-test-arrow button:hover {
  background: #0328a2;
  transition: all 0.6s ease;
}
.d-test-arrow button:hover i {
  color: #f5f5f5;
}
</style>
