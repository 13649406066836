<template>
  <footer>
    <div class="w-sm-90">
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3">
          <h4>Aptitude Buster</h4>
        </div>
        <div class="col-12 col-md-4 col-lg-3"></div>
        <div class="col-12 col-md-4 col-lg-3"></div>
        <div class="col-12 col-md-12 col-lg-3 m-auto text-center">
          <a href="/"><img src="../../src/assets/img/ab-logo.png" alt=""/></a>

          <p>All Rights Reserved 2021.</p>
          <h5>Aptitude Buster Private Limited</h5>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
window.oncontextmenu = function() {
  console.log("Right Click Disabled");
  return false;
};
</script>
