<template>
  <div>
    <Header />
    <section
      class="other-banner"
      style="background-position: center; min-height: auto"
      :style="{ backgroundImage: `url(${backgroundUrl})` }"
    >
      <!-- <div class="banner-content">
        <div class="banner-caption">
            <h1>Profile</h1>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Error quaerat alias beatae laboriosam nobis
                tempore
                harum eos architecto illo laborum iure deleniti sunt</p>
            <div class="banner-btn mt-1">
                <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
                <a class="lst-a mx-1">Profile</a>
            </div>
            <button class="btn-sub-trail mt-3">Edit</button>
        </div>
    </div> -->
    </section>
    <section class="d-flex prof-page">
      <div class="left-prof-pag">
        <div class="prof-name text-center pb-5">
          <img
            :src="user.image"
            alt=""
            style="border-radius: 75px; width: 75px; height: 75px"
          />
          <label class="cameraButton">
            <i class="fas fa-camera"></i>
            <input
              type="file"
              accept="image/*;capture=camera"
              @change="imageEdit($event)"
            />
          </label>
          <h4>{{ profileName }}</h4>
          <button class="edit-btn" @click="edit = true">
            <i class="fas fa-pen-nib"></i>
          </button>
        </div>
        <div class="detail-prof-lst mt-4">
          <ul>
             <router-link :to="{ name: 'Profile' }">
            <li><a class="active prof-lft-lst">Profile</a></li>
            </router-link>
            <router-link :to="{ name: 'Subscription' }">
              <li>
                <a class="prof-lft-lst">Subscription</a>
              </li>
            </router-link>
            <router-link :to="{ name: 'About Us' }">
              <li><a class="prof-lft-lst">About us</a></li>
            </router-link>
           <!-- <router-link :to="{ name: 'Invite Earn' }">
              <li>
                <a class="prof-lft-lst"> Invite & Earn</a>
              </li>
            </router-link> -->
            <router-link :to="{ name: 'Faq' }">
              <li>
                <a class="prof-lft-lst">FAQ</a>
              </li>
            </router-link>
            <router-link :to="{ name: 'Help And Support' }">
              <li>
                <a class="prof-lft-lst">Help And Support</a>
              </li>
            </router-link>

            <!--<router-link :to="{ name: 'Rate Us' }">
              <li><a class="prof-lft-lst">Rate Us</a></li>
            </router-link> -->
           <!-- <li><a class="prof-lft-lst" href="#">Share</a></li> -->
          </ul>
        </div>
        <button class="logout-pag" @click="logout()">
          <i class="fas fa-power-off me-2"></i>Logout
        </button>
      </div>
      <!-- Menu Ends  -->
      <div class="rit-prof px-2 py-3 flex-grow-1">
        <div class="row">
          <div class="col-6">
            <div class="p-3">
              <div class="ind-rank-det ps-0">
                <h3 class="mb-0">Help And Support</h3>
              </div>
              <div class="col-12">
                <div class="form-cont">
                  <label for="">Name</label>
                  <p v-if="!edit">{{ user ? user.name : "" }}</p>
                  <input v-else v-model="name" />
                </div>
              </div>
              <div class="col-12"></div>
              <div class="col-12">
                <div class="form-cont">
                  <label for="">Mobile Number</label>
                  <p>{{ user ? user.mobile : "" }}</p>
                </div>
              </div>
            </div>
            <button class="submit-pag ms-3" @click="helpAndSupport()">
              Submit<i class="fas fa-arrow-right ms-2"></i>
            </button>
          </div>
          <div class="col-6">
            <img
              src="../../src/assets/img/daily-task.png"
              alt="daily-task"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import backgroundUrl from "../../src/assets/img/banner.png";
import axios from "axios";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      profileName: localStorage.getItem("userDetails")
        ? JSON.parse(localStorage.getItem("userDetails")).name
        : "",
      user: {},
      backgroundUrl,
      edit: false,
      name: "",
      email: "",
      emailEmpty: false,
      nameEmpty: false,
    };
  },
  mounted() {
    this.getUserData();
  },
  methods: {
    async imageEdit(event) {
      let image = event.target.files[0];
      const formdata = new FormData();
      formdata.append("image", image);

      await axios
        .post(`${this.$store.getters.baseUrl}/edit_pic`, formdata, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.editUserDetails();
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    //Help And Support
    async helpAndSupport() {
      const formdata = new FormData();
      formdata.append("mobile", this.user.mobile);
      formdata.append("name", this.user.name);

      await axios
        .post(`${this.$store.getters.baseUrl}/help_support`, formdata, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.$swal("Noted", response.data.message, "Success");
          }
        })

        .catch((error) => {
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    //Help And Support Closed

    async editUserDetails() {
      const formdata = new FormData();
      formdata.append("mobile", this.user.mobile);

      if (this.email != this.user.email) {
        if (this.email == "") {
          this.emailEmpty = true;
          return;
        } else formdata.append("email", this.email);
      } else formdata.append("email", this.user.email);

      if (this.name != this.user.name) {
        if (this.name == "") {
          this.nameEmpty = true;
          return;
        } else formdata.append("name", this.name);
      } else formdata.append("name", this.user.name);

      formdata.append("state_id", this.user.state_id);

      await axios
        .post(`${this.$store.getters.baseUrl}/edit_user`, formdata, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.edit = false;
            localStorage.setItem(
              "userDetails",
              JSON.stringify(response.data.user)
            );
            this.getUserData();
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    getUserData() {
      this.user = JSON.parse(localStorage.getItem("userDetails"));
      this.name = this.user.name;
      this.email = this.user.email;
    },
    logout() {
      localStorage.clear();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
label.cameraButton i {
  border: 2px solid #fff;
  color: #fff;
  background: linear-gradient(#0530ba, #021b79);
  transition: 0.2s all linear;
  width: 35px;
  height: 35px;
  line-height: 30px;
  border-radius: 50px;
  position: absolute;
  top: 30%;
  right: 28%;
}

/* Look like a clicked/depressed button */
label.cameraButton:active {
  border-color: #ccc #eee #eee #ccc;
}

/* This is the part that actually hides the 'Choose file' text box for camera inputs */
label.cameraButton input[accept*="camera"] {
  display: none;
}
</style>
