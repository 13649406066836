<template>
  <div>
    <section
      class="other-banner"
      style="background-position: center; min-height: auto"
      :style="{ backgroundImage: `url(${backgroundUrl})` }"
    >
      <Header />
    </section>
    <section class="d-flex prof-page">
      <div class="left-prof-pag">
        <div class="prof-name text-center pb-5">
          <img src="../../src/assets/img/ab-logo.png" alt="" />
          <h4>{{ profileName }}</h4>
          <button class="edit-btn"><i class="fas fa-pen-nib"></i></button>
        </div>
        <div class="detail-prof-lst mt-4">
          <ul>
            <router-link :to="{ name: 'Profile' }">
              <li>
                <a class="active prof-lft-lst">Profile</a>
              </li>
            </router-link>

            <router-link :to="{ name: 'Home' }">
              <li>
                <a class="prof-lft-lst">Home</a>
              </li>
            </router-link>
            <router-link :to="{ name: 'Subscription' }">
              <li>
                <a class="prof-lft-lst">Subscription</a>
              </li>
            </router-link>
            <router-link :to="{ name: 'About Us' }">
              <li><a class="prof-lft-lst">About us</a></li>
            </router-link>
            <router-link :to="{ name: 'Invite Earn' }">
              <li>
                <a class="prof-lft-lst"> Invite & Earn</a>
              </li>
            </router-link>
            <router-link :to="{ name: 'Faq' }">
              <li>
                <a class="prof-lft-lst">FAQ</a>
              </li>
            </router-link>
            <router-link :to="{ name: 'Help And Support' }">
              <li>
                <a class="prof-lft-lst">Help And Support</a>
              </li>
            </router-link>

            <router-link :to="{ name: 'Rate Us' }">
              <li><a class="prof-lft-lst">Rate Us</a></li>
            </router-link>
          </ul>
        </div>
        <button class="logout-pag" @click="logout()">
          <i class="fas fa-power-off me-2"></i>Logout
        </button>
      </div>
      <div class="rit-prof inv-earn-page px-3 flex-grow-1">
        <div class="row">
          <div class="col-6">
            <img
              class=""
              style="width: 380px"
              src="../../src/assets/img/icons/rate-us.png"
              alt=""
            />
          </div>
          <div class="col-6">
            <div class="ind-rank-det p-0 mb-0">
              <h3 class="mb-0">Rate Us</h3>
            </div>
            <div class="ind-rank-det ps-0">
              <h5 class="mb-0">
                <b
                  >Share your referral code <br />
                  987654321</b
                >
              </h5>
            </div>
            <div class="d-flex">
              <a
                :href="
                  'https://play.google.com/store/apps/details?id=com.enrichandexcell.aptitudebuster&referrer=' +
                    mobile
                "
                class="play-shar me-3"
              >
                <i class="fab fa-google-play"></i> Play store
              </a>
              <!-- <a href="#" class="play-shar">
                <i class="fab fa-google"></i> Google
              </a> -->
            </div>
            <div class="mt-3 acc-red">
              <p><span>Accured Points </span>: 4 Points</p>
              <p><span>Redeemed Points </span>: 1 Points</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import backgroundUrl from "../../src/assets/img/banner.png";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      profileName: localStorage.getItem("userDetails")
        ? JSON.parse(localStorage.getItem("userDetails")).name
        : "",
      mobile: localStorage.getItem("userDetails")
        ? JSON.parse(localStorage.getItem("userDetails")).mobile
        : "",
      user: {},
      backgroundUrl,
    };
  },
  mounted() {
    this.getUserData();
  },
  methods: {
    getUserData() {
      this.user = JSON.parse(localStorage.getItem("userDetails"));
    },
    logout() {
      localStorage.clear();
      sessionStorage.clear();
      indexedDB.deleteDatabase("QuestionsDB");
      this.$router.push({ name: "Login" });
      window.location.reload();
    },
  },
};
</script>
