<template>
  <div>
    <div ref="youtubePlayer"></div>
    <button @click="playVideo">Play</button>
    <button @click="pauseVideo">Pause</button>
    <button @click="playPause">play/Pause</button>
    <button @click="muteUnmute">Mute/Unmute</button>
    <button @click="mute">Mute</button>
    <button @click="unmute">Unmute</button>
    <button @click="end">end</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      player: null,
      isPlaying: false,
      mutes: false,
    };
  },
  mounted() {
    // Load the YouTube Iframe API script
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Initialize the player when the API is ready
    window.onYouTubeIframeAPIReady = this.initPlayer;
  },
  methods: {
    initPlayer() {
      this.player = new window.YT.Player(this.$refs.youtubePlayer, {
        videoId: "ytQ5CYE1VZw",
        events: {
          // Optional: Add event listeners or other configurations
          onStateChange: this.onPlayerStateChange,
        },
      });
    },
    playPause() {
      this.player.getPlayerState() === 1
        ? this.player.pauseVideo()
        : this.player.playVideo();
    },

    playVideo() {
      this.player.playVideo();
      this.isPlaying = true;
    },

    pauseVideo() {
      this.player.pauseVideo();
      this.isPlaying = false;
    },

    mute() {
      this.player.mute();
      this.mutes = true;
    },

    unmute() {
      this.player.unMute();
      this.mutes = false;
    },

    muteUnmute() {
      this.player.isMuted() ? this.player.unMute() : this.player.mute();
    },
    onPlayerStateChange(event) {
      // Handle player state change events if needed
      console.log("Player State Change:", event.data);
    },
  },
};
</script>

<style scoped>
/* Add your custom styles if needed */
</style>
