import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Styles
import "bootstrap";
import "core-js";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/all.css";
import "./assets/css/lightbox.css";
import "./assets/css/animate.css";
import "./assets/css/font.css";
import "./assets/css/animation-frame.css";
// import "/path/highlight.min.js";
// import "/path/code_editor.prod.js";

import BootstrapVue from "bootstrap-vue";
import { BBreadcrumb } from "bootstrap-vue";
Vue.component("b-breadcrumb", BBreadcrumb);
// Sweet Alert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

//import { directive as vueRawHTML } from '@pixolith/vue-raw-html';
import { directive as vueRawHTML } from "@pixolith/vue-raw-html/dist/vue-raw-html.module";
Vue.directive("raw-html", vueRawHTML);

Vue.use(VueSweetalert2);
Vue.use(BootstrapVue);
// Axios interceptors
import axios from "axios";
axios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);
var currentUrl = window.location.pathname;
console.log(currentUrl);

const errorHandler = (error) => {
  if (error.response.status === 401) {
    alert("already login in another device");
    localStorage.clear();
    router.push({ name: "Login" });
    window.location.href("Login");
  } else if (error.response.status === 500) {
    alert("Some Error Has Been occured, please Try Again Later...!");
    // router.go(-1);
  } else {
    router.push({ currentUrl });
  }
  return;
};

const responseHandler = (response) => {
  return Promise.resolve(response);
};

/*let siteTitle = 0;

//user leaves the tab
window.addEventListener('blur', () => {
  console.log("leaves");
  document.title = siteTitle;
});

//user enters the tab
window.addEventListener('focus', () => {
  console.log("enters");
  siteTitle += 1;
  document.title = siteTitle;
});

if(siteTitle >= 3) {
  this.$swal(
    "Warning",
    "You Have Changed Tab more than 3 times !",
    "warning"
  );  
}*/

// End of Axios interceptors

// history.pushState(null, null, location.href);
// window.onpopstate = function() {
//   history.go(1);
// };

// FOR RELOAD
// window.addEventListener("beforeunload", function(e) {
//   e.preventDefault();
//   e.returnValue = "";
// });

global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
