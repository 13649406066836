<template>
  <div>
    <section class="other-banner">
      <Header />
      <div class="banner-content">
        <div class="banner-caption">
          <h1>Discussion</h1>
          <div class="banner-btn mt-1">
            <!--          <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
            <a class="lst-a mx-1">Discussion</a> -->
            <nav class="flex-sm-00-auto ml-sm-3" aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-alt">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Discussion' }"
                    >Discussion Details</router-link
                  >
                </li>
              </ol>
            </nav>
          </div>
          <!-- <h5 class="mt-2">Unread- <b>25</b></h5> -->
          <!-- <button class="btn-sub-trail mt-3">Edit</button> -->
        </div>
      </div>
    </section>

      <section class="plac-pre-prac-tst my-2 mt-3">

         <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <section class="tl-lst-tst pt-1">
            <div
            >
              <div class="list-test">
                <div class="d-flex">
                  <div class="lft-notif comment_sec d-grid">
                    <img :src="discussion.user.image" alt="" />
                  </div>
                  <div class="lft-lst-tst flex-grow-1 ps-4">
                    <h5>{{ discussion.user.name }}</h5>
                    <p>
                      {{ discussion.query }}
                    </p>
                    <p>
                      {{ discussion.description }}
                    </p>
                    <div>
                      <img :src="discussion.image" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>    
    </section>
    
    <section class="plac-pre-prac-tst my-2 mt-3">
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <section class="tl-lst-tst pt-1">
            <div
              v-for="(comment, index) in comments"
              :key="index"
            >
              <div class="list-test">
                <div class="d-flex">
                  <div class="lft-notif comment_sec d-grid">
                    <img :src="comment.threadable.image" alt="" />
                  </div>
                  <div class="lft-lst-tst flex-grow-1 ps-4">
                    <h5>{{ comment.threadable.name }}</h5>
                    <p>
                      {{ comment.reply }}
                    </p>
                     <div>
                      <img :src="comment.image" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>

    <button
      class="chat-icon"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal1"
    >
      <i class="fas fa-comments"></i>
    </button>

    <!-- discuss modal -->
    <div
      class="modal fade"
      id="exampleModal1"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered post-ques">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h5 class="modal-title" id="exampleModalLabel">Post Reply</h5>
            <button
              type="button"
              class="btn-close"
              id="closeModal1"
              @click="closeModal1()"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
    
            <textarea
              name=""
              id=""
              cols="55"
              rows="3"
              aria-roledescription=""
              v-model="thread_description"
              placeholder="Reply..."
            ></textarea>
            <div style="color: green" v-if="imageStatus1 == true">
              Image uploaded successfully
            </div>
          </div>
          <div class="modal-footer border-0">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="closeModal1()"
            >
              Cancel
            </button>
            <label class="cameraButton">
              Upload Image
              <input
                type="file"
                accept="image/*;capture=camera"
                @change="imagePost1($event)"
              />
            </label>
            <button type="button" id="post" class="btn" @click="addPostDiscussion()">
              Post
            </button>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  name: "Profile",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      discussion:"",
      comments: [],
      thread_image: [],
      thread_description:"",
      imageStatus1: false,
      loader: false,
    };
  },

  mounted: async function() {
    await this.getDiscussion();
  },
  methods: {
      async addPostDiscussion() {
      const formdata = new FormData();
      formdata.append("discussion_id", this.discussion.id);
      if (this.imageStatus1 == true) {
        formdata.append("image", this.thread_image);
      }
      formdata.append("reply", this.thread_description);
      await axios
        .post(`${this.$store.getters.baseUrl}/discussions/threads`, formdata, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.$swal("Success", "Replied successfully", "success");
            document.getElementById("closeModal1").click();
            this.getDiscussions();
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
     closeModal1() {
      (this.thread_description = ""), (this.thread_image = []);
    },
     imagePost1(event) {
      this.imageStatus1 = false;
      this.thread_image = event.target.files[0];
      this.thread_image ? (this.imageStatus1 = true) : (this.imageStatus1 = false);
    },
    async getDiscussion() {
      this.loader = true;
      const formdata = new FormData();
      formdata.append("discussion_id",this.$route.params.discussionId);
      await axios
        .post(`${this.$store.getters.baseUrl}/discussions/threads/lists`, formdata, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.discussion = response.data.discussion;
            this.comments = response.data.comments;
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>

<style scoped>
#post {
  background-color: #0530ba;
  color: #fff;
}

#post:hover {
  background-color: #021b79;
}

textarea {
  resize: none;
  border-radius: 5px;
  border-color: #cfd2dc;
}

::placeholder {
  color: grey;
  opacity: 1; /* Firefox */
}

label.cameraButton {
  display: inline-block;

  /* Styles to make it look like a button */
  padding: 0.375rem 0.75rem;
  border: 1px solid transparent;
  color: #fff;
  background-color: #0530ba;
  right: 30px;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  user-select: none;
}

label.cameraButton:hover {
  background-color: #021b79;
}

/* Look like a clicked/depressed button */
label.cameraButton:active {
  border-color: #ccc #eee #eee #ccc;
}

/* This is the part that actually hides the 'Choose file' text box for camera inputs */
label.cameraButton input[accept*="camera"] {
  display: none;
}
</style>
