<template>
  <div>
    <section class="other-banner">
      <Header />
      <div class="banner-content">
        <div class="banner-caption py-3">
          <h1>e-Library</h1>
          <div class="banner-btn mt-1 text-dark">
            <nav class="flex-sm-00-auto ml-sm-3" aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-alt">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'E Library' }"
                    >E-Libray</router-link
                  >
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>

    <section class="plac-pre-prac-tst mt-4 my-2">
      <!-- Parent topics  -->
      <ul class="nav nav-tabs plac-prep-nav-link" id="myTab" role="tablist">
        <li
          class="nav-item"
          role="presentation"
          v-for="(parentTopic, index) in e_libraryTopics"
          :key="index"
        >
          <button
            @click="(parentTopicId = parentTopic.id), e_LibraryDetails()"
            class="nav-link"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            {{ parentTopic.name }}
          </button>
        </li>
      </ul>
      <!-- End of parent topic -->
    </section>

    <section class="tl-lst-tst">
      <div
        class="in-list-test"
        v-for="(library, index) in e_library_details"
        :key="index"
      >
        <div class="list-test">
          <div class="d-flex">
            <div class="lft-lst-tst flex-grow-1 pe-4">
              <h5>{{ library.name }}</h5>
              <p class="mb-3">{{ library.description }}</p>
              <div class="d-flex">
                <router-link
                  v-if="library.view == 1"
                  :to="{
                    name: 'Pdf View',
                    params: {
                      pdf: library.document,
                      status: 0,
                    },
                  }"
                >
                  <a> <i class="fas fa-eye"></i> View </a>
                </router-link>
                <!--    <a
                  v-if="library.download == 1"
                  class="viw-dwn"
                  target="_blank"
                  :href="library.document"
                  ><i class="fas fa-download"></i> Download</a> -->
                <router-link
                  v-if="library.download == 1"
                  :to="{
                    name: 'Pdf View',
                    params: {
                      pdf: library.document,
                      status: 1,
                    },
                  }"
                >
                  <a><i class="fas fa-download"></i> Download </a>
                </router-link>
              </div>
            </div>
            <div class="rt-comp-img d-grid">
              <img :src="library.image" alt="" toolbar="0" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  name: "Profile",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      e_libraryTopics: [],
      parentTopicId: "",
      e_library_details: [],
      loader: false,
    };
  },
  mounted: async function() {
    await this.getE_LibraryTopics();
    this.e_LibraryDetails();
  },
  methods: {
    async downloadItem(url, label) {
      await axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

    async getE_LibraryTopics() {
      this.loader = true;
      await axios
        .get(`${this.$store.getters.baseUrl}/libraries`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.e_libraryTopics = response.data.categories;
            this.parentTopicId = this.e_libraryTopics[0].id;
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async e_LibraryDetails() {
      this.loader = true;
      const formdata = new FormData();
      formdata.append("id", this.parentTopicId);

      await axios
        .post(`${this.$store.getters.baseUrl}/libraries/details`, formdata, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.e_library_details = response.data.contents;
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
