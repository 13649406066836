var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"other-banner",staticStyle:{"background":"url('../../src/assets/img/test/banner.jpg') no-repeat","background-position":"center","min-height":"auto"}},[_c('Header')],1),_c('section',{staticClass:"d-flex justify-content-center p-0 mx-0",staticStyle:{"min-height":"535px"}},[(_vm.resultQuestions.length > 0)?_c('aside',{staticClass:"p-3 w-100"},[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"justify-content-center ref-clr d-flex mb-3"},[(_vm.resultQuestions[_vm.index].result == 1)?_c('p',{staticClass:"bg-gn"},[_vm._v(" Correct ")]):_vm._e(),(_vm.resultQuestions[_vm.index].result == 2)?_c('p',{staticClass:"bg-rd"},[_vm._v(" Incorrect ")]):_vm._e(),(_vm.resultQuestions[_vm.index].result == 3)?_c('p',{staticClass:"bg-def"},[_vm._v(" Unattempted ")]):_vm._e()]),_c('div',{staticClass:"text-center"},[_c('h4',[_c('b',[_vm._v(_vm._s(_vm.resultQuestions[_vm.index].topicName))])])]),_c('span',{staticClass:"float-left"},[_vm._v("Q.No "),_c('b',[_vm._v(_vm._s(_vm.index + 1))]),_vm._v(" of "+_vm._s(_vm.resultQuestions.length))])]),_c('article',{staticClass:"question-img"},[(_vm.resultQuestions[_vm.index].questions.group_question != null)?_c('p',{staticClass:"mb-1",staticStyle:{"display":"none"},attrs:{"id":"groupQuestion"}},[_c('b',{domProps:{"innerHTML":_vm._s(
              `${_vm.resultQuestions[_vm.index].questions.group_question.question}`
            )}})]):_vm._e(),(_vm.resultQuestions[_vm.index].questions.group_question != null)?_c('img',{staticClass:"mb-1",staticStyle:{"width":"50%","display":"none"},attrs:{"id":"groupImage","src":_vm.resultQuestions[_vm.index].questions.group_question.image,"alt":""}}):_vm._e(),_c('p',{staticClass:"mb-1",domProps:{"innerHTML":_vm._s(`${_vm.resultQuestions[_vm.index].questions.question}`)}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"questions-img"},[_c('img',{staticClass:"mb-1",staticStyle:{"width":"100%"},attrs:{"src":_vm.resultQuestions[_vm.index].questions.image,"alt":""}})])]),_c('div',{staticClass:"col-md-6",staticStyle:{"padding":"0"}},[_c('div',{staticClass:"questions-read"},[(_vm.resultQuestions[_vm.index].questions.group_question != null)?_c('button',{staticClass:"btn-radious mx-2",staticStyle:{"float":"right"},attrs:{"id":"readMore"},on:{"click":function($event){return _vm.readMore()}}},[_vm._v(" Read More ")]):_vm._e()])])]),(_vm.resultQuestions[_vm.index].questions.question_type == 'objective')?_c('div',{staticClass:"radi-box"},[_c('ul',{staticClass:"row"},_vm._l((_vm.resultQuestions[_vm.index].questions
                .options),function(option,index1){return _c('li',{key:index1,staticClass:"w-50"},[_c('input',{attrs:{"type":"radio"},domProps:{"checked":_vm.resultQuestions[_vm.index].answer == option.option ||
                  _vm.resultQuestions[_vm.index].questions.answer == option.option
                    ? true
                    : false}}),_c('label',{staticClass:"btn-cek"},[_c('span',[_vm._v(_vm._s(index1 == 0 ? "A" : index1 == 1 ? "B" : index1 == 2 ? "C" : index1 == 3 ? "D" : "E"))]),_vm._v(_vm._s(option.option))]),_c('div',{class:_vm.resultQuestions[_vm.index].questions.answer == option.option
                    ? 'check gn-ck'
                    : 'check rd-ck'})])}),0)]):_vm._e(),(
            _vm.resultQuestions[_vm.index].questions.question_type == 'fill_in_blank'
          )?_c('div',[_c('label',[_c('span',[_vm._v("Your Answer: "),_c('b',[_vm._v(_vm._s(_vm.resultQuestions[_vm.index].answer ? _vm.resultQuestions[_vm.index].answer : "Not Available"))])])]),_c('br'),_c('label',[_c('span',[_vm._v("Correct Answer: "),_c('b',[_vm._v(_vm._s(_vm.resultQuestions[_vm.index].questions.answer))])])])]):_vm._e(),(
            _vm.resultQuestions[_vm.index].questions.solutions.length > 0 &&
              _vm.resultQuestions[_vm.index].questions.question_type != 'coding'
          )?_c('div',[_c('br'),_vm._v(" Explanations: "),_vm._l((_vm.resultQuestions[_vm.index].questions
              .solutions),function(solution,index3){return _c('div',{key:index3,staticClass:"mt-2"},[(
                _vm.resultQuestions[_vm.index].questions.solutions[index3].type ==
                  'description'
              )?_c('p',[_c('b',{domProps:{"innerHTML":_vm._s(
                  `${_vm.resultQuestions[_vm.index].questions.solutions[index3].description}`
                )}})]):_vm._e(),(_vm.resultQuestions[_vm.index].questions.solutions[index3].image)?_c('img',{staticClass:"mt-2",staticStyle:{"width":"50%"},attrs:{"src":_vm.resultQuestions[_vm.index].questions.solutions[index3].image,"alt":""}}):_vm._e(),(
                _vm.resultQuestions[_vm.index].questions.solutions[index3]
                  .video_url != null
              )?_c('p',{staticClass:"vw-btn explan-more",on:{"click":function($event){(_vm.youtubeId =
                  _vm.resultQuestions[_vm.index].questions.solutions[index3]
                    .video_url),
                  _vm.videosubmit(),
                  (_vm.show = 'show')}}},[_vm._v(" Watch Videos ")]):_vm._e()])})],2):_vm._e(),(_vm.resultQuestions[_vm.index].questions.question_type == 'coding')?_c('div',{staticClass:"solution-page"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"pills":"","card":""}},[_c('b-tab',{attrs:{"title":"Solution Code","active":""}},[_c('b-card-text',[_c('div',{staticClass:"solutin-bg"},_vm._l((_vm.resultQuestions[_vm.index]
                        .questions.solutions),function(solution,index3){return _c('div',{key:index3,staticClass:"mt-2"},[(
                          _vm.resultQuestions[_vm.index].questions.solutions[index3]
                            .type == 'description'
                        )?_c('h6',[_vm._v(" Explanations: ")]):_vm._e(),_c('b',{domProps:{"innerHTML":_vm._s(
                          `${_vm.resultQuestions[_vm.index].questions.solutions[index3].description}`
                        )}})])}),0)])],1),_c('b-tab',{attrs:{"title":"Your Code"}},[_c('b-card-text',[_c('div',{staticClass:"solutin-bg"},[_c('span',{domProps:{"innerHTML":_vm._s(`${_vm.resultQuestions[_vm.index].source_code}`)}})])])],1),_c('b-tab',{attrs:{"title":"Solution Videos"}},[_c('b-card-text',_vm._l((_vm.resultQuestions[_vm.index]
                      .questions.solutions),function(solution,index3){return _c('div',{key:index3},[(
                        _vm.resultQuestions[_vm.index].questions.solutions[index3]
                          .image
                      )?_c('img',{staticClass:"mt-2",staticStyle:{"width":"50%"},attrs:{"src":_vm.resultQuestions[_vm.index].questions.solutions[index3]
                          .image,"alt":""}}):_vm._e(),(
                        _vm.resultQuestions[_vm.index].questions.solutions[index3]
                          .video_url != null
                      )?_c('p',{staticClass:"vw-btn explan-more",on:{"click":function($event){(_vm.youtubeId =
                          _vm.resultQuestions[_vm.index].questions.solutions[index3]
                            .video_url),
                          _vm.videosubmit(),
                          (_vm.show = 'show')}}},[_vm._v(" Watch Videos ")]):_vm._e()])}),0)],1)],1)],1)],1):_vm._e(),(_vm.youtubeId)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.show),expression:"!show"}],staticStyle:{"position":"relative"},attrs:{"id":"youtubeVideo"}},[_c('LazyYoutube',{ref:"youtubeLazyVideo",attrs:{"src":_vm.youtubeId,"aspect-ratio":"16:9","thumbnail-quality":"standard"}}),_c('div',{staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"90%"}}),_c('div',{staticStyle:{"position":"absolute","bottom":"0","right":"0px","width":"250px","height":"45px"}}),_c('div',{staticClass:"buttons",staticStyle:{"position":"absolute","bottom":"-44px","left":"0px","display":"flex"}},[_c('button',{staticClass:"play_btn",class:{ play_btns: _vm.buttonCol == 1 },on:{"click":function($event){return _vm.handleClick('playVideo', 'youtubeLazyVideo', 1)}}},[_vm._v(" Play ")]),_c('button',{staticClass:"pause_btn",class:{ pause_btns: _vm.buttonCol == 2 },on:{"click":function($event){return _vm.handleClick('pauseVideo', 'youtubeLazyVideo', 2)}}},[_vm._v(" Pause ")]),_c('button',{staticClass:"reset_btn",class:{ reset_btns: _vm.buttonCol == 3 },on:{"click":function($event){return _vm.handleClick('resetView', 'youtubeLazyVideo', 3)}}},[_vm._v(" Reset ")])])],1):_vm._e(),_c('br'),_c('div',{staticClass:"my-3 justify-content-between"},[(!_vm.index == 0)?_c('button',{staticClass:"btn-radious",staticStyle:{"float":"left"},on:{"focus":function($event){return _vm.reRender()},"click":function($event){(_vm.index = _vm.index - 1),
                _vm.reRender(),
                _vm.resultQuestions[_vm.index].questions.group_question != null
                  ? _vm.groupUpdate()
                  : ''}}},[_vm._v(" Previous ")]):_vm._e(),(_vm.youtubeId)?_c('div',{attrs:{"id":"youtubeVideo"}},[(_vm.index != _vm.resultQuestions.length - 1)?_c('button',{staticClass:"btn-radious mx-2",staticStyle:{"float":"right"},on:{"focus":function($event){return _vm.reRender()},"click":function($event){_vm.handleClick('stopVideo', 'youtubeLazyVideo'),
                  (_vm.index = _vm.index + 1),
                  _vm.reRender(),
                  _vm.resultQuestions[_vm.index].questions.group_question != null
                    ? _vm.groupUpdate()
                    : ''}}},[_vm._v(" Next ")]):_vm._e()]):_c('div',[(_vm.index != _vm.resultQuestions.length - 1)?_c('button',{staticClass:"btn-radious mx-2",staticStyle:{"float":"right"},on:{"focus":function($event){return _vm.reRender()},"click":function($event){// handleClick('stopVideo', 'youtubeLazyVideo'),
                (_vm.index = _vm.index + 1),
                  _vm.reRender(),
                  _vm.resultQuestions[_vm.index].questions.group_question != null
                    ? _vm.groupUpdate()
                    : ''}}},[_vm._v(" Next ")]):_vm._e()])])])]):_vm._e(),(_vm.resultQuestions.length > 0)?_c('aside',{staticClass:"ques-ptn",staticStyle:{"width":"450px"}},[_c('h4',{staticClass:"mb-2"},[_vm._v("Question Palette")]),_vm._m(0),_vm._l((_vm.palette),function(topic,index2){return _c('div',{key:index2,staticClass:"fst-qes-pag"},[_c('h5',{staticClass:"mb-1"},[_vm._v(_vm._s(topic.topic_name))]),_vm._l((_vm.resultQuestions),function(question,index1){return _c('div',{key:index1,staticClass:"qes-pag-p-tl"},[(
              question.result == 1 && question.topicName == topic.topic_name
            )?_c('p',{staticClass:"bg-gn",on:{"click":function($event){_vm.index = index1}}},[_vm._v(" "+_vm._s(index1 + 1)+" ")]):_vm._e(),(
              question.result == 3 && question.topicName == topic.topic_name
            )?_c('p',{staticClass:"bg-def",on:{"click":function($event){_vm.index = index1}}},[_vm._v(" "+_vm._s(index1 + 1)+" ")]):_vm._e(),(
              question.result == 2 && question.topicName == topic.topic_name
            )?_c('p',{staticClass:"bg-rd",on:{"click":function($event){_vm.index = index1}}},[_vm._v(" "+_vm._s(index1 + 1)+" ")]):_vm._e()])})],2)})],2):_vm._e()]),_c('b-overlay',{staticClass:"position-fixed",attrs:{"show":_vm.loader,"no-wrap":"","blur":"2px","opacity":"0.60"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('i',{staticClass:"fa fa-spinner fa-pulse fa-4x fa-fw text-primary"})]},proxy:true}])}),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ref-clr d-flex"},[_c('p',{staticClass:"bg-gn"},[_vm._v("Correct")]),_c('p',{staticClass:"bg-rd"},[_vm._v("Incorrect")]),_c('p',{staticClass:"bg-def"},[_vm._v("Unattempted")])])
}]

export { render, staticRenderFns }