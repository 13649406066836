var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"head-page"},[_c('div',{staticClass:"top-header d-flex justify-content-end px-3 py-2"},[_c('p',{staticClass:"me-3"},[_c('router-link',{attrs:{"to":{ name: 'Profile' }}},[_c('a',{attrs:{"href":"profile.php"}},[_c('img',{staticClass:"me-1",attrs:{"src":require("../../src/assets/img/icons/user.png")}}),_vm._v(_vm._s(_vm.name))])])],1),_c('p',{staticClass:"notif"},[_c('router-link',{attrs:{"to":{ name: 'Notifications' }}},[_c('img',{staticClass:"me-1",attrs:{"src":require("../../src/assets/img/icons/notification.png")}})])],1)]),_c('nav',{staticClass:"navbar navbar-expand-xl navbar-light p-0"},[(
        _vm.$route.name == 'Questions' && _vm.$route.params.type == 'practice-test'
      )?_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_c('a',{staticClass:"navbar-brand"},[_c('img',{attrs:{"src":require("../../src/assets/img/main-logo.png"),"alt":""}})])]):_c('router-link',{attrs:{"to":{
        name:
          _vm.$route.name == 'Questions' && _vm.$route.params.type != 'practice-test'
            ? ''
            : 'Home',
      }}},[_c('a',{staticClass:"navbar-brand",on:{"click":function($event){_vm.$route.name == 'Questions' && _vm.$route.params.type != 'practice-test'
            ? _vm.noBack()
            : ''}}},[_c('img',{attrs:{"src":require("../../src/assets/img/main-logo.png"),"alt":""}})])]),_vm._m(0),_c('div',{staticClass:"navbar-collapse nav-bar-coll d-lg-block",attrs:{"id":"nav-bar-coll"}},[_c('ul',{staticClass:"navbar-nav ms-auto nav-bar"},[(
            _vm.$route.name == 'Questions' &&
              _vm.$route.params.type == 'practice-test'
          )?_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"aria-current":"page"}},[_vm._v("Home")])])]):_c('router-link',{attrs:{"to":{
            name:
              _vm.$route.name == 'Questions' &&
              _vm.$route.params.type != 'practice-test'
                ? ''
                : 'Home',
          }}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"aria-current":"page"},on:{"click":function($event){_vm.$route.name == 'Questions' &&
                _vm.$route.params.type != 'practice-test'
                  ? _vm.noBack()
                  : ''}}},[_vm._v(" Home")])])]),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'Discussion' },"href":"discussion.php"}},[_vm._v("Discussion")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
              name: 'Daily Challenge',
              params: { testType: 'daily_test' },
            }}},[_vm._v("Daily Challenge")])],1)],1)]),_c('b-overlay',{staticClass:"position-fixed",attrs:{"show":_vm.loader,"no-wrap":"","blur":"2px","opacity":"0.60"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('i',{staticClass:"fa fa-spinner fa-pulse fa-4x fa-fw text-primary"})]},proxy:true}])})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navbarNav","onclick":"menuBar()","aria-controls":"navbarNav","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('div',{staticClass:"bor-der",attrs:{"id":"bar-cont1"}}),_c('div',{staticClass:"bor-der",staticStyle:{"width":"15px"},attrs:{"id":"bar-cont2"}}),_c('div',{staticClass:"bor-der",attrs:{"id":"bar-cont3"}})])
}]

export { render, staticRenderFns }