<template>
  <div class="d-sm-flex lo-g-in-page">
    <div class="col-12 col-sm-6 col-md-7 col-lg-8 p-0">
      <div class="lft-log-in d-grid">
        <img
          class="m-auto"
          src="../../src/assets/img/login/login-illus-1.svg"
          alt=""
        />
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-5 col-lg-4 p-0 tl-rit-login">
      <div class="main-log-in">
        <div class="rt-log-in d-grid">
          <div class="rt-cont">
            <img
              class="ab-logo mb-3"
              src="../../src/assets/img/ab-logo.png"
              alt=""
            />
            <h5 class="p-welcom"><span>OTP</span></h5>
            <div class="material-form-design">
              <div class="group-material">
                <input
                  type="number"
                  v-model="otp"
                  @keyup.enter="verifyOtp()"
                  @focus="otpEmpty = false"
                  required
                />
                <span class="highlight"></span>
                <span class="bar"></span>
                <div style="color: red" v-if="otpEmpty">OTP is required</div>
                <div style="color: red" v-if="otpExpired">
                  OTP has been expired
                </div>
                <label>Enter OTP</label>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <p
                id="resend"
                style="opacity: 0; cursor: pointer"
                @click="resendOtp()"
              >
                Resend
              </p>
              <!-- <p id="countdown"></p> -->
              <p v-if="timerCount == 0">
                {{ "00:0" + timerCount }}
              </p>
              <p v-else-if="timerCount < 10">
                {{ "00:0" + timerCount }}
              </p>
              <p v-else>
                {{ "00:" + timerCount }}
              </p>
            </div>
            <p class="mobile-verf">
              Enter the verfication code send to <br /><span>{{
                mobileNumber
              }}</span>
            </p>
          </div>
        </div>
        <div class="btn-btn-login">
          <button class="btn-btn" @click="verifyOtp()">Continue</button>
        </div>
      </div>
    </div>
    <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
  </div>
</template>

<script>
import axios from "axios";
import vue from "vue";
export default {
  name: "Otp",
  mounted() {
    this.fetchmobilenumber();
    // this.timer();
    this.timerCount = 30;
    /*window.addEventListener('keyup', event => {
      if (event.keyCode === 13) { 
        this.verifyOtp()
      }
    });*/
  },
  data() {
    return {
      otp: "",
      mobileNumber: "",
      otpEmpty: false,
      otpExpired: false,
      downloadTimer: null,
      loader: false,
      timerCount: "",
    };
  },
  methods: {
    async verifyOtp() {
      console.log("event-enter");
      this.loader = true;
      if (this.otp == "") {
        this.otpEmpty = true;
        this.loader = false;
        return;
      } else this.otpEmpty = false;

      // if (document.getElementById("countdown").innerHTML == "00:00") {
      if (this.timerCount == 0) {
        this.otpExpired = true;
        this.loader = false;
        return;
      } else this.otpExpired = false;
      const formdata = new FormData();
      formdata.append("mobile", this.mobileNumber);
      formdata.append("otp", this.otp);
      await axios
        .post(`${this.$store.getters.baseUrl}/otp_verification`, formdata)
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            response.data.registered_status == 1
              ? this.$router.push({ name: "Home" })
              : this.$router.push({ name: "Registration" });
            if (response.data.registered_status == 1) {
              let userDetails = response.data.user;
              let token = response.data.token;
              localStorage.setItem("userDetails", JSON.stringify(userDetails));
              localStorage.setItem("token", token);
              localStorage.setItem("ebox", response.data?.user?.is_ebox);
            }
            this.otp = "";
          } else {
            this.$swal("Error", response.data.message, "warning");
            this.loader = false;
            this.otp = "";
          }
        })
        .catch((error) => {
          console.log(error);
          this.loader = false;
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    async resendOtp() {
      console.log("eee");
      this.otpExpired = false;
      // this.timer();
      this.timerCount = 30;
      const formdata = new FormData();
      formdata.append("mobile", this.mobileNumber);
      await axios
        .post(`${this.$store.getters.baseUrl}/login`, formdata)
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            // alert(response.data.user.otp);
            vue.swal({
              title: "success",
              icon: "success",
              text: response.data.message,
              showCancelButton: false,
              reverseButtons: false,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              timer: 1500,
            });
            // this.$swal("Success", response.data.message, "success");
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
    timer() {
      var timeleft = 30;

      this.downloadTimer = setInterval(function() {
        if (timeleft <= 0) {
          console.log("timeleft", timeleft);
          clearInterval(this.downloadTimer);
          document.getElementById("countdown").innerHTML = "00:00";
          // document.getElementById("resend").style.opacity = 1;
          // document.getElementById("resend").style.visibility = "visible";
        } else {
          console.log("timelefssst", timeleft);
          this.otpExpired = false;
          // document.getElementById("resend").style.opacity = 0;
          // document.getElementById("resend").style.visibility = "hidden";
          document.getElementById("countdown").innerHTML =
            timeleft >= 10 ? "00:" + timeleft : "00:0" + timeleft;
        }
        timeleft -= 1;
      }, 1000);
    },

    fetchmobilenumber() {
      this.mobileNumber = localStorage.getItem("mobile");
    },
  },

  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
          this.otpExpired = false;
          document.getElementById("resend").style.opacity = 0;
          document.getElementById("resend").style.visibility = "hidden";
        } else {
          document.getElementById("resend").style.opacity = 1;
          document.getElementById("resend").style.visibility = "visible";
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.downloadTimer) clearInterval(this.downloadTimer);
    next();
  },
};
</script>
