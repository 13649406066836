var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('section',{staticClass:"other-banner"},[_c('div',{staticClass:"banner-content"},[_c('div',{staticClass:"banner-caption"},[_c('h1',[_vm._v(_vm._s(_vm.$route.params.topicName))]),_c('div',{staticClass:"banner-btn mt-1 text-dark"},[_c('nav',{staticClass:"flex-sm-00-auto ml-sm-3",attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb breadcrumb-alt"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_vm._v("Home")])],1),(_vm.$route.params.categoryName)?_c('li',{staticClass:"breadcrumb-item",attrs:{"aria-current":"page"}},[_c('router-link',{attrs:{"to":{
                    name: 'Placement Prep',
                    params: {
                      categoryId: _vm.$route.params.categoryId,
                      categoryName: _vm.$route.params.categoryName,
                    },
                  }}},[_vm._v(_vm._s(_vm.$route.params.categoryName))])],1):_vm._e(),_c('li',{staticClass:"breadcrumb-item",attrs:{"aria-current":"page"}},[_c('router-link',{attrs:{"to":{ name: 'Subscription Description' }}},[_vm._v(_vm._s(_vm.$route.params.subscriptionName))])],1),_c('li',{staticClass:"breadcrumb-item",attrs:{"aria-current":"page"}},[_c('router-link',{attrs:{"to":{ name: 'Practice Test List' }}},[_vm._v("Practice Test List")])],1),_c('li',{staticClass:"breadcrumb-item",attrs:{"aria-current":"page"}},[_c('router-link',{attrs:{"to":{ name: 'Practice Tests' }}},[_vm._v(_vm._s(_vm.$route.params.topicName))])],1)])])])])])]),_c('section',{staticClass:"plac-pre-prac-tst mt-4 my-2"},[_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"home","role":"tabpanel","aria-labelledby":"home-tab"}},[(_vm.subtopics.length > 0)?_c('div',_vm._l((_vm.subtopics),function(subtopic,index){return _c('section',{key:index,staticClass:"tl-lst-tst pt-0 pb-2"},[_c('router-link',{staticClass:"in-list-test",attrs:{"to":""}},[_c('div',{staticClass:"list-test",attrs:{"href":""}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"lft-lst-tst flex-grow-1 pe-4"},[_c('h5',[_vm._v(_vm._s(subtopic.topic.name))]),_c('p',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(subtopic.topic.description)+" ")]),_c('router-link',{attrs:{"to":{
                        name: 'Practice Test Details',
                        params: {
                          subId: _vm.$route.params.subscriptionId,
                          subTopicId: subtopic.id,
                        },
                      }}},[_c('p',{staticClass:"viw-dwn h6 py-2 mt-3",staticStyle:{"width":"fit-content"}},[_c('b',[_vm._v("View Details")])])])],1),_c('div',{staticClass:"rt-comp-img d-grid"},[_c('img',{attrs:{"src":subtopic.topic.image,"alt":""}})])])])])],1)}),0):_c('div',[_c('h5',{staticClass:"mx-5"},[_vm._v("you have exhausted your attempt for this Test")])])])])]),_c('b-overlay',{staticClass:"position-fixed",attrs:{"show":_vm.loader,"no-wrap":"","blur":"2px","opacity":"0.60"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('i',{staticClass:"fa fa-spinner fa-pulse fa-4x fa-fw text-primary"})]},proxy:true}])}),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }