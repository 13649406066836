<template>
  <div>
    <section class="other-banner">
      <Header />
      <div class="banner-content p-5">
        <div class="banner-caption prog-bar text-dark">
           <h1>Report Page</h1> 
          <radial-progress-bar style="margin: auto" inner-stroke-color="red" :stroke-width="15" :inner-stroke-width="13"
            start-color="rgb(134, 207, 7)" stop-color="rgb(66, 147, 33)" :diameter="200" :completed-steps="completedSteps"
            :total-steps="totalSteps">
            <h3>{{ completedSteps }}%</h3>
          </radial-progress-bar>
          
          <!--div class="banner-btn mt-1">
                <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
                <a class="lst-a mx-1">Test Result</a>
            </!--div>
            <h5 class="mt-2">34</h5> -->
          <!-- <button class="btn-sub-trail mt-3">Edit</button> -->
          <div class="d-flex flex-wrap short-rslt justify-content-around mt-4 text-dark">
            <div class="  col-md-3 col-12 px-3 my-2">
              <div class="design-box  ">
                <div class="">
                  <h5>Correct </h5>
                  <h3> {{ result.total_correct_answer }}</h3>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-12 px-3 my-2">
              <div class="design-box  ">
                <div class="">
                  <h5>InCorrect </h5>
                  <h3> {{ result.total_incorrect_answer }}</h3>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-12 px-3 my-2">
              <div class="design-box ">
                <div class="">
                  <h5>Unattempted </h5>
                  <h3> {{ result.total_unattempted }}</h3>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-12 px-3 my-2">
              <div class="design-box ">
                <div class="">
                  <h5>Rank </h5>
                  <h4> {{ result.rank ? result.rank : "Not Available" }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <p class="w-75 m-auto mt-3 max-mar text-start scr-mark">
      Score: <span>{{ result.score }}</span>
    </p>
    <p class="w-75 m-auto mt-3 max-mar text-start scr-mark">
      Total No. Of Questions: <span>{{ result.no_of_questions }}</span>
    </p>
    <br />
    <section class="test-rslt-pag mb-3">
      <table class="table table-bordered text-center w-75 m-auto striped" style="border: #6c757d">
        <thead>
          <tr class="theme-grad text-light align-middle">
            <th scope="col" style="border-color: #6c757d">Section</th>
            <td style="border-color: #6c757d">Correct</td>
            <td style="border-color: #6c757d">Incorrect</td>
            <td style="border-color: #6c757d">Unattempted</td>
            <td style="border-color: #6c757d">Total</td>
          </tr>
        </thead>
        <tbody>
          <tr class="align-middle" v-for="(topic, index) in topicWise" :key="index">
            <th scope="row" class="table-success" style="border-color: #6c757d">
              {{ topic.topic_name }}
            </th>
            <td class="table-warning" style="border-color: #6c757d">
              {{ topic.total_correct_answer }}
            </td>
            <td class="table-info" style="border-color: #6c757d">
              {{ topic.total_incorrect_answer }}
            </td>
            <td class="table-danger" style="border-color: #6c757d">
              {{ topic.total_unattempted }}
            </td>
            <td class="table-dark" style="border-color: #6c757d">
              {{
                topic.total_correct_answer +
                topic.total_incorrect_answer +
                topic.total_unattempted
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <div class="w-75 mx-auto mt-3" v-if="result.result_status == 2">
        <router-link :to="{ name: 'Solution', params: { testId: $route.params.testId } }"
          class="btn-sub-trail px-3">Solution</router-link>
      </div>
    </section>
    <!-- Loader -->
    <b-overlay :show="loader" no-wrap blur="2px" opacity="0.60" class="position-fixed">
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import RadialProgressBar from "vue-radial-progress";
export default {
  components: {
    Header,
    Footer,
    RadialProgressBar,
  },
  data() {
    return {
      percent: "",
      result: {},
      topicWise: [],
      completedSteps: 0,
      totalSteps: 100,
      loader: false,
    };
  },
  mounted() {
    this.getTestReports();
  },
  methods: {
    async getTestReports() {
      this.loader = true;
      const formdata = new FormData();
      formdata.append("user_test_id", this.$route.params.testId);
      await axios
        .post(
          `${this.$store.getters.baseUrl}/mock-tests/test-reports`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.result = response.data.test_report;
            this.topicWise = response.data.topic_wise;
            this.completedSteps = this.result.pass_percentage;
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "Questions") {
      this.$swal({
        title: "Info",
        icon: "warning",
        text:
          "You cannot go back to test screen after completing the test. Clicking Ok will take you to home screen",
        confirmButtonText: "Ok",
        showCancelButton: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({
            name: "Home"
          });
        }
      });
      next(false);
    } else next();
  },
};
</script>