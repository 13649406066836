<template>
  <div>
    <Header />
    <section
      class="other-banner"
      style="background-position: center"
      :style="{ backgroundImage: `url(${backgroundUrl})` }"
    >
      <div class="banner-content">
        <div class="banner-caption">
          <h1>About us</h1>
          <div class="banner-btn mt-1">
            <!--          <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
            <a class="lst-a mx-1">About us</a>
            -->
            <nav class="flex-sm-00-auto ml-sm-3" aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-alt">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Faq' }">Faq</router-link>
                </li>
              </ol>
            </nav>
          </div>
          <!-- <button class="btn-sub-trail mt-3">Edit</button> -->
        </div>
      </div>
    </section>
    <section class="abt-page w-80 m-auto p-3">
      <!-- <div class="m-auto">
        <img style="width:280px;" src="../../src/assets/img/icons/rate-us.png" alt="">
    </div> -->
      <p>
        Money-related Q: Will I get any special discount if I purchase more than
        one subscription at a time? A: Yes, it’s possible for select courses.
        You shall click “Buy Combo” and avail special discount. Q: I wish to
        continue the subscription plan. What should I do? A: It’s simple. You
        shall purchase it again, however the course details may vary. Q: Can I
        refer this app to as many friends as I wish to? What will be the reward
        for doing so? A: Yes, you can. There will be a cash reward up to Rs.500.
        Q: How to redeem my reward points? A:You shall redeem your points by
        subscribing to any of ouro plans.
      </p>
      <p>
        Course-related Q: How do I know my test results / rank for All India
        Mock Tests? A: You shall know your results instantly after the test.
        Rank can be viewed after the scheduled time and duration of the test.
        i.e.If the test is of 2 hours duration and is available up to 7 pm on a
        specified date, Rank can be viewed from 9.30 pm onwards. Q: How do I
        know the results for ongoing recruitment drive? A: Like all other
        competitive exams, you will be notified if you clear the test. Q: Will I
        be able to pause the test and resume it at a later date / time? A: Yes,
        you shall pause all “practice tests” and solve each question to your
        convenience Q: Does “Daily Challenge” test is scheduled once in a day?
        A: Yes, you shall be allowed to take up a Daily Challenge only once. Q:
        I’m unable to watch the solution / concept videos sometimes. What should
        I do? A: Such errors are less likely and occur due to poor connectivity.
        Kindly check your network connectivity once again. If the problem
        persists, don’t hesitate to contact us
      </p>
      <p>
        App-related Q: I need to change my mobile number. Please help A: You
        need to submit all necessary documents to change your mobile number to
        prove your identity. Do write support@aptitudebuster.com Q: I have
        misspelled my email id. How shall I edit it? A: You need to submit all
        necessary documents to change your mobile number to prove your identity.
        Do write support@aptitudebuster.com Q: I’ve asked for support by
        clicking “submit” button in Help & Support section. When can I get the
        reply from you? A: Your issue will be addressed on top priority probably
        within 2 working days. If not, do write support@aptitudebuster.com Q:
        Can I access this app from a PC / laptop? A: Yes, you can access almost
        all the features of this app on a PC / Laptop by visiting
        www.aptitudebuster.com For best experience - please use Chrome/Firefox.
        Others Q: I’ve made the payment, but the subscription is locked. A:
        Thank you for trusting us. This may happen due to following reasons: 1.
        Transaction failure, 2. You might have logged in and paid from a
        different email id. In case you still have any doubt kindly drop a mail
        support@aptitudebuster.com Q: Are all the tests active right at the
        start? A: Exams are added as per the study plan. Even before purchase,
        you can see the start date of each test.
      </p>
    </section>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import backgroundUrl from "../../src/assets/img/banner.png";
export default {
  name: "Profile",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      backgroundUrl,
    };
  },
};
</script>
