var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"other-banner",staticStyle:{"background":"url('../../src/assets/img/test/banner.jpg') no-repeat","background-position":"center","min-height":"auto"}},[_c('Header')],1),_c('section',{staticClass:"d-flex justify-content-center p-3"},[_c('aside',{staticClass:"my-auto",staticStyle:{"width":"430px"}},[_c('h5',[_vm._v(_vm._s(_vm.dailyChallenge.description))]),_c('br'),_c('table',{staticClass:"table table-bordered text-center"},[_vm._m(0),_c('tbody',_vm._l((_vm.topics),function(topic,index){return _c('tr',{key:index},[_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(topic.topic_name))]),_c('td',[_vm._v(_vm._s(topic.no_of_questions))]),_c('td',[_vm._v(_vm._s(topic.duration))])])}),0)]),_c('article',{staticClass:"detail-test-dur"},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('p',{staticClass:"left-data-text py-1"},[_vm._v("Duration")]),_c('p',{staticClass:"py-1"},[_vm._v(": "+_vm._s(_vm.dailyChallenge.duration)+" Mins")])]),_c('div',{staticClass:"d-flex justify-content-start"},[_c('p',{staticClass:"left-data-text py-1"},[_vm._v("No. of Questions")]),_c('p',{staticClass:"py-1"},[_vm._v(": "+_vm._s(_vm.dailyChallenge.no_of_questions))])]),_c('div',{staticClass:"d-flex justify-content-start"},[_c('p',{staticClass:"left-data-text py-1"},[_vm._v("Each Question Mark")]),_c('p',{staticClass:"py-1"},[_vm._v(": "+_vm._s(_vm.dailyChallenge.each_question_mark))])]),_c('div',{staticClass:"d-flex justify-content-start"},[_c('p',{staticClass:"left-data-text py-1"},[_vm._v("Negative Mark")]),_c('p',{staticClass:"py-1"},[_vm._v(": "+_vm._s(_vm.dailyChallenge.negative_mark))])])]),_c('router-link',{staticClass:"a-radious mt-2",attrs:{"to":{
          name: 'Questions',
          params: {
            type: 'practice-test',
            testId: _vm.dailyChallenge.id ? _vm.dailyChallenge.id : 0,
            submitId: _vm.dailyChallenge.id ? _vm.dailyChallenge.id : 0,
          },
        }}},[_vm._v("Start Test")])],1),_vm._m(1)]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"tit-text",attrs:{"scope":"col"}},[_vm._v("Section")]),_c('th',{staticClass:"tit-text",attrs:{"scope":"col"}},[_vm._v("Questions")]),_c('th',{staticClass:"tit-text",attrs:{"scope":"col"}},[_vm._v("Duration (Mins)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"my-auto mx-5"},[_c('img',{staticStyle:{"width":"380px"},attrs:{"src":require("../../src/assets/img/test/test-img.png"),"alt":""}})])
}]

export { render, staticRenderFns }