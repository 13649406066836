<template>
  <div>
    <section class="other-banner">
      <Header />
      <div class="banner-content">
        <div class="banner-caption py-3">
          <h1>Recruitment Drive Tests</h1>
          <div class="banner-btn mt-1 text-dark">
            <!-- <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
            <a class="lst-a mx-1">e-Library</a> -->
          </div>
        </div>
      </div>
    </section>

    <section class="plac-pre-prac-tst mt-4 my-2">
      <!-- Parent topics  -->
      <ul class="nav nav-tabs plac-prep-nav-link" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            @click="(type = 'ongoing'), getRecruitments()"
            class="nav-link"
            id="home-tab1"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            Ongoing
          </button>
        </li>

        <li class="nav-item" role="presentation">
          <button
            @click="(type = 'upcoming'), getRecruitments()"
            class="nav-link"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            Upcoming
          </button>
        </li>
      </ul>
      <!-- End of parent topic -->
    </section>

    <section class="tl-lst-tst">
      <div
        class="in-list-test"
        v-for="(recruitment, index) in type == 'ongoing'
          ? recruitments
          : recruitmentsUpcoming"
        :key="index"
      >
        <div class="list-test">
          <div class="d-flex">
            <div class="lft-lst-tst flex-grow-1 pe-4">
              <h5>{{ recruitment.name }}</h5>
              <p class="" v-if="type == 'upcoming'">
                {{ recruitment.created_at }}
              </p>
              <div class="d-flex">
                <router-link
                  id="ongoing"
                  class="mt-2"
                  v-if="type == 'ongoing'"
                  :to="{
                    name: 'Subscription Mock Test Details',
                    params: { testId: recruitment.id, testType: 'ongoing' },
                  }"
                >
                  <p class="viw-dwn h6 py-2 mt-2" style="width: fit-content">
                    <b>View Details</b>
                  </p>
                </router-link>

                <router-link
                  class="mt-2"
                  v-if="type == 'upcoming'"
                  :to="{
                    name: 'Subscription Description',
                    params: {
                      subscriptionName: recruitment.subscription.name,
                      subscriptionId: recruitment.subscription.id,
                    },
                  }"
                >
                  <p class="viw-dwn h6 py-2 mt-2" style="width: fit-content">
                    <b>View Details</b>
                  </p>
                </router-link>
              </div>
            </div>
            <div class="rt-comp-img d-grid">
              <img :src="recruitment.image" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      recruitments: [],
      recruitmentsUpcoming: [],
      type: "ongoing",
      loader: false,
    };
  },
  mounted() {
    this.getRecruitments();
  },
  methods: {
    async getRecruitments() {
      this.loader = true;
      const formdata = new FormData();
      formdata.append("type", this.type);
      await axios
        .post(`${this.$store.getters.baseUrl}/recruitment-drives?page_type=web`, formdata, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.type == "ongoing"
              ? (this.recruitments = response.data.ongoing_recruitments)
              : (this.recruitmentsUpcoming = response.data.subscriptions);
            if (this.type == "ongoing") {
              let onGoingActive = document.getElementById("home-tab1");
              onGoingActive.className = "nav-link active";

              this.loader = false;
              if (!response.data.ongoing_recruitments) {
                this.$swal("Info", response.data.message, "warning");
              }
              this.loader = false;
            } else {
              this.loader = false;
              if (!response.data.subscriptions) {
                this.$swal("Info", response.data.message, "warning");
              }
            }
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
