<template>
  <div>
    <section
      class="other-banner"
      style="
        background: url('assets/img/test/banner.jpg') no-repeat;
        background-position: center;
        min-height: auto;
      "
    >
      <Header />
    </section>
    <section class="d-flex justify-content-center p-0 mx-0">
      <aside class="p-3 w-100">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            px-2
            py-1
            mb-2
          "
        >
          <button
            class="btn-skip"
            @click="skipSection()"
            :style="topicIndex == topics.length - 1 ? 'visibility: hidden' : ''"
          >
            Skip Section
          </button>
          <div
            class="text-center"
            v-if="$route.params.type !== 'subscription_mock_test'"
          >
            <button
              v-if="$route.params.type == 'practice-test'"
              class="pass-play m-auto"
              @click="pausePlayTimer()"
            >
              <i class="fas fa-pause" id="pas-play"></i>
            </button>

            <button v-else class="pass-play m-auto">
              <i class="fas fa-pause" id="pas-play"></i>
            </button>
            <p class="text-center" id="timer"></p>
          </div>
          <div
            class="text-center"
            v-if="$route.params.type === 'subscription_mock_test'"
          >
            <p class="text-center" id="timer"></p>
          </div>
          <a
            :style="topicIndex != topics.length - 1 ? 'visibility: hidden' : ''"
            data-bs-toggle="modal"
            data-bs-target="#trail-det"
            class="btn-sbmt"
            id="endTest"
            @click="modalData()"
            >End Test</a
          >
          <!-- <button class="btn-sbmt">End Test</button> -->
        </div>
        <article
          class="question-img"
          v-for="(topic, index) in topics"
          :key="index"
          v-show="topicIndex == index"
        >
          {{ time == null ? timer(topic.duration) : "" }}
          <h4 class="text-center">
            <b>{{ topic.topic_name }}</b>
          </h4>
          <br />
          <div
            v-for="(question, index1) in topic.questions"
            :key="index1"
            v-show="questionIndex == index1"
          >
            <p class="mb-3">
              <span
                >Q.No <b>{{ question.qno }}</b> of
                <b>{{ totalQuestions }}</b>
                <a
                  class="btn-rmk"
                  @click="remark(topic.id, index1, question.qno)"
                  >REMARK
                </a>
              </span>
              <br /><br />
              <span
                v-if="question.group_question != null"
                :id="`group${question.qno}`"
                style="display: block"
                ><b v-html="question.group_question.question"></b> <br />
                <br v-if="question.group_question.image"/><img
                  :src="question.group_question.image"
                  style="width: 50%"
                  alt=""/>
                <br v-if="question.group_question.image"/>
                <br v-if="question.group_question.image"
              /></span>
              <span v-html="question.question"></span>
            </p>
            <div class="row">
              <div class="col-md-6">
                <div class="questions-img">
                  <img :src="question.image" style="width: 100%" alt="" />
                </div>
              </div>
              <div class="col-md-6" style="padding: 0">
                <div class="questions-read">
                  <button
                    class="read-more mx-2"
                    style="float: right"
                    v-if="question.group_question != null"
                    :id="`readMore${question.qno}`"
                    @click="readMore(question.qno)"
                  >
                    Read More
                  </button>
                </div>
              </div>
            </div>
            <div class="radi-box" v-if="question.question_type == 'objective'">
              <ul class="row">
                <li
                  class="w-50"
                  v-for="(option, index2) in question.options"
                  :key="index2"
                >
                  <input
                    type="radio"
                    :id="`${question.qno}${index2}`"
                    :name="`${question.qno}`"
                    :checked="question.userAnswer == index2 ? true : false"
                    @click="
                      optionClick(topic.id, index1, index2, question.qno),
                        updateUserAnswer(
                          option,
                          index,
                          index1,
                          question.question_type
                        )
                    "
                  />
                  <label
                    class="btn-cek blu-lb"
                    :for="`${question.qno}${index2}`"
                  >
                    <span>{{
                      index2 == 0
                        ? "A"
                        : index2 == 1
                        ? "B"
                        : index2 == 2
                        ? "C"
                        : index2 == 3
                        ? "D"
                        : "E"
                    }}</span
                    >{{ option.option }}</label
                  >
                  <div class="check blu-ck">
                    <div class="inside"></div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="form-group w-80" v-else-if="question.question_type == 'coding'">
              <div id="app">
                <span>Language: </span>
               <select
                :id="`coding-language${question.qno}`">
                <option value="cpp">C++</option>
                <option value="java">Java</option>
                <option value="php">PHP</option>
                <option value="python">Python 3.x</option>
               
              </select><br>
              <div id="MyAceEditor">
                <AceEditor 
                    
                    @init="editorInit" 
                    lang="javascript" 
                    theme="monokai" 
                    width="100%" 
                    height="200px"
                    :options="{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        fontSize: 14,
                        highlightActiveLine: true,
                        enableSnippets: true,
                        showLineNumbers: false,
                        tabSize: 2,
                        showPrintMargin: false,
                        showGutter: true,
                    }"
                    :commands="[
                        {
                            name: 'save',
                            bindKey: { win: 'Ctrl-s', mac: 'Command-s' },
                            exec: dataSumit,
                            readOnly: true,
                        },
                    ]"
                  @input="codingUpdate(question.qno, topic.id, index, index1)"
                  :id="`coding-source_code${question.qno}`"
                  :value="question.source_code"
                    />
                </div>
              </div>

            </div>
            <div class="form-group w-50" v-else>
              <input
                type="text"
                class="form-control"
                placeholder="Enter your answer here..."
                @keyup="fillupUpdate(question.qno, topic.id, index, index1)"
                :id="`fillup${question.qno}`"
                :value="question.userAnswer"
              />
            </div>
          </div>
        </article>

        <div class="d-flex my-3 justify-content-between">
          <button
            v-if="questionIndex == 0"
            class="btn-radious"
            :style="
              topicIndex == 0 && questionIndex == 0 ? 'visibility: hidden' : ''
            "
            @click="noBack()"
          >
            Previous
          </button>

          <button
            class="btn-radious"
            v-if="questionIndex != 0"
            :style="
              topicIndex == 0 && questionIndex == 0 ? 'visibility: hidden' : ''
            "
            @click="previous()"
          >
            Previous
          </button>
          <button
            class="btn-radious mx-2"
            style="float: right"
            @click="next()"
            :style="
              topicIndex == topics.length - 1 &&
              questionIndex == topics[topics.length - 1].questions.length - 1
                ? 'visibility: hidden'
                : ''
            "
          >
            Next
          </button>
        </div>
      </aside>
      <aside class="ques-ptn" style="width: 450px">
        <h4 class="mb-2">Question Palette</h4>
        <div class="ref-clr d-flex">
          <!-- <p class="bg-gn">Correct</p>
            <p class="bg-rd">Incorrect</p> -->
          <p class="bg-blu">Attempt</p>
          <p class="bg-def">Unattempted</p>
          <p class="bg-yel">Remark</p>
        </div>
        <div class="fst-qes-pag" v-for="(topic, index) in topics" :key="index">
          <h5 class="mb-1">{{ topic.topic_name }}</h5>
          <div
            :class="
              index != topicIndex ? 'qes-pag-p-tl pe-none' : 'qes-pag-p-tl'
            "
            :style="index != topicIndex ? 'opacity: 0.5' : ''"
            v-for="(question, index1) in topic.questions"
            :key="index1"
            @click="questionPallete(index1)"
          >
            <p
              :class="
                question.userAnswer
                  ? 'bg-blu'
                  : question.remarks
                  ? 'bg-yel'
                  : 'bg-def'
              "
              :id="`palette${question.qno}`"
            >
              {{ question.qno }}
            </p>
          </div>
        </div>
      </aside>
    </section>
    <Footer />

    <div
      class="modal fade p-5"
      id="trail-det"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" style="width: 300px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Submit Test</h5>
            <button
              style="visibility: hidden"
              id="modalClose"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <dl>
              <div>
                <dt class="red"></dt>
                <dd>Answered</dd>
                <span class="mb-0" style="float: right" id="answered"></span>
              </div>
              <div>
                <dt class="yellow"></dt>
                <dd>Unattempted</dd>
                <span class="mb-0" style="float: right" id="unattempted"></span>
              </div>
              <div>
                <dt class="rem"></dt>
                <dd>Remark</dd>
                <span class="mb-0" style="float: right" id="remarks"></span>
              </div>
              <div>
                <hr />
                <dd>Total Questions</dd>
                <span class="mb-0" style="float: right">{{
                  totalQuestions
                }}</span>
                <hr />
              </div>
            </dl>

            <div class="enq-form-index col-12 col-lg-12">
              <p class="mt-3">
                Clicking Submit will submit the test and not allow you to review
                or change your answers
              </p>
              <div class="mt-1 justify-content-center d-flex">
                <button
                  id="resume"
                  class="btn-sub-trail px-3 mt-2 me-2 px-2"
                  @click="resumeClick()"
                >
                  Resume
                </button>
                <button
                  class="btn-sub-trail px-3 mt-2 me-2 px-2"
                  @click="submitTest()"
                >
                  Submit
                </button>
              </div>
              <!-- <button class="btn-sub-trail mt-2">Mock Tests</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script src="https://code.jquery.com/jquery-3.5.0.js"></script>

    <script src="https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.13/ace.js" type="text/javascript" charset="utf-8"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.13/mode-php.min.js" type="text/javascript" charset="utf-8"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.13/mode-python.min.js" type="text/javascript" charset="utf-8"></script>
    <script>
        var editor = ace.edit("MyAceEditor");
        var code = editor.getSession().getValue();
        editor.setTheme("ace/theme/monokai");
        editor.session.setMode("ace/mode/javascript");
        editor.setOptions({
            minLines: 2
        });

        

        $( "#source_code_form" ).submit(function( event ) {
          console.log(editor.getValue());
          $( "#user_source_code" ).val(editor.getValue());
          
        });

        function getLanguage(sel)
        {
            alert(sel);
            if(sel == 29){
              editor.session.setMode("ace/mode/php");
            }

            if(sel == 116){
              editor.session.setMode("ace/mode/python");
            }
        }
    </script>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
// import CodeEditor from 'simple-code-editor';
import AceEditor from 'vuejs-ace-editor';

//import "highlight.min.js";
//import "code_editor.prod.js";
   

export default {
  components: {
    Header,
    Footer,
    // CodeEditor,
    AceEditor,
  },
  data() {
    return {
      durationOfFirstTopic: 0,
      topics: [],
      db: null,
      dbVersion: 1,
      objectStore: null,
      no: 0,
      topicIndex: 0,
      questionIndex: 0,
      time: null,
      refreshDuration: 0,
      totalQuestions: 0,
      submitAPI: 0,
    };
  },
  mounted: async function() {
    if (
      (await window.indexedDB.databases())
        .map((db) => db.name)
        .includes("QuestionsDB")
    ) {
      await this.refresh();
      this.reRender();
    } else {
      await this.getDB();
      this.reRender();
    }
  },
  watch: {
    topics: function() {
      console.log(window);
      console.log("data changed");
      this.$nextTick().then(() => {
        this.reRender();
      });
    },
    questionIndex: function() {
      console.log(window);
      //console.log("data changed");
      this.$nextTick().then(() => {
        this.reRender();
      });
    },
  },
  methods: {
    dataSumit() {
            //code here
        },
        editorInit: function () {
            require('brace/ext/language_tools') //language extension prerequsite...
            require('brace/mode/html')                
            require('brace/mode/javascript')    //language
            require('brace/mode/less')
            require('brace/theme/monokai')
            require('brace/snippets/javascript') //snippet
        },
    async reRender() {
      //console.log(window);
      if (window.MathJax) {
        console.log("rendering mathjax");
        await window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () =>
          console.log("done")
        );
      }
    },
    pausePlayTimer() {
      let playpauseButton = document.getElementById("pas-play");
      playpauseButton.className =
        playpauseButton.className == "fas fa-pause"
          ? "fas fa-play"
          : "fas fa-pause";
      if (playpauseButton.className == "fas fa-play") {
        clearInterval(this.time);
        this.updateStatus();
      } else {
        this.refresh();
      }
    },
    async updateUserAnswer(option, topicInx, questionInx, questionType) {
      let tx = this.db.transaction("questions", "readonly");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.get("finalObject");

      data.onsuccess = async (eve) => {
        let tx1 = this.db.transaction("questions", "readwrite");
        tx1.oncomplete = (ev) => {
          console.log(ev);
        };

        tx1.onerror = (err) => {
          console.log(err);
        };
        let dataToUpdate = eve.target.result;
        if (questionType == "objective") {
          dataToUpdate.topic[topicInx].questions[questionInx].option_id =
            option.id;
          dataToUpdate.topic[topicInx].questions[questionInx].user_answer =
            option.option;
          // dataToUpdate.topic[topicInx].questions[questionInx].remarks = null;
        }
        if (questionType == "fill_in_blank") {
          dataToUpdate.topic[topicInx].questions[questionInx].option_id = "";
          dataToUpdate.topic[topicInx].questions[questionInx].user_answer = "";
        }
        let dbData1 = await tx1.objectStore("questions");
        let data1 = await dbData1.put(dataToUpdate);

        data1.onsuccess = (ev) => {
          console.log("user answer updated successfully", ev);
        };

        data1.onerror = (err) => {
          console.log("error updating the user answer", err);
        };
      };

      data.onerror = (err) => {
        console.log(err);
      };
    },
    async optionClick(topicId, questionIndex, optionIndex, qno) {
      let tx = this.db.transaction("questions", "readonly");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.get(topicId);

      data.onsuccess = async (eve) => {
        let tx1 = this.db.transaction("questions", "readwrite");
        tx1.oncomplete = (ev) => {
          console.log(ev);
        };

        tx1.onerror = (err) => {
          console.log(err);
        };
        let dataToUpdate = eve.target.result;
        dataToUpdate.questions[questionIndex].userAnswer = optionIndex;
        dataToUpdate.questions[questionIndex].remarks = null;

        let dbData1 = await tx1.objectStore("questions");
        let data1 = await dbData1.put(dataToUpdate);

        data1.onsuccess = (ev) => {
          console.log("user answer updated successfully", ev);
        };

        data1.onerror = (err) => {
          console.log("error updating the user answer", err);
        };
        document.getElementById(`palette${qno}`).classList.remove("bg-def");
        document.getElementById(`palette${qno}`).classList.remove("bg-yel");
        document.getElementById(`palette${qno}`).classList.add("bg-blu");
      };

      data.onerror = (err) => {
        console.log("error updating the user answer", err);
      };
    },

    async remark(topicId, questionIndex, qno) {
      let tx = this.db.transaction("questions", "readonly");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.get(topicId);

      data.onsuccess = async (eve) => {
        let tx1 = this.db.transaction("questions", "readwrite");
        tx1.oncomplete = (ev) => {
          console.log(ev);
        };

        tx1.onerror = (err) => {
          console.log(err);
        };
        let dataToUpdate = eve.target.result;
        dataToUpdate.questions[questionIndex].userAnswer = null;
        dataToUpdate.questions[questionIndex].remarks = 1;
        let dbData1 = await tx1.objectStore("questions");
        let data1 = await dbData1.put(dataToUpdate);

        data1.onsuccess = (ev) => {
          console.log("user remarks updated successfully", ev);
        };

        data1.onerror = (err) => {
          console.log("error updating the user answer", err);
        };
        document.getElementById(`palette${qno}`).classList.remove("bg-def");
        document.getElementById(`palette${qno}`).classList.remove("bg-blu");
        document.getElementById(`palette${qno}`).classList.add("bg-yel");
      };

      data.onerror = (err) => {
        console.log("error updating the user answer", err);
      };
    },
    async refresh() {
      let DBOpenReq = indexedDB.open("QuestionsDB", this.dbVersion);

      DBOpenReq.addEventListener("error", (err) => {
        console.log(err);
      });

      DBOpenReq.addEventListener("success", async (ev) => {
        this.db = ev.target.result;
        console.log("success", this.db);
        let tx = this.db.transaction("questions", "readonly");
        tx.oncomplete = (ev) => {
          console.log(ev);
        };

        tx.onerror = (err) => {
          console.log(err);
        };

        let dbData = await tx.objectStore("questions");
        let data = await dbData.getAll();

        data.onsuccess = (ev) => {
          if (this.topics.length == 0) {
            ev.target.result.forEach((data) => {
              if (data.id != "currentStatus" && data.id != "finalObject") {
                this.topics.push(data);
              }
            });
          }

          // Total questions updating

          this.totalQuestions == 0
            ? this.topics.forEach(
                (topic) =>
                  (this.totalQuestions =
                    this.totalQuestions + topic.no_of_questions)
              )
            : "";

          clearInterval(this.time);
          let status = ev.target.result[ev.target.result.length - 2];
          this.timer(status.currentDuration / 60);
          this.topicIndex = status.currentTopic;
          this.questionIndex = status.currentQuestion;
        };

        data.onerror = (err) => {
          console.log("error updating the status", err);
        };
      });
    },

    // Db Intialize starts
    async getDB() {
      let DBOpenReq = indexedDB.open("QuestionsDB", this.dbVersion);

      DBOpenReq.addEventListener("error", (err) => {
        console.log(err);
      });
      //If Db Created Success it moves to getdata
      DBOpenReq.addEventListener("success", (ev) => {
        this.db = ev.target.result;
        console.log("success", this.db);
        this.getData();
      });
      DBOpenReq.addEventListener("upgradeneeded", async (ev) => {
        this.db = ev.target.result;
        let oldVersion = ev.oldVersion;
        let newVersion = ev.newVersion || this.db.version;
        console.log("DB updated from version ", oldVersion, "to", newVersion);
        if (!this.db.objectStoreNames.contains("questions")) {
          this.objectStore = await this.db.createObjectStore("questions", {
            keyPath: "id",
          });
        }
        const formdata = new FormData();
        if (this.$route.params.type == "daily_test") {
          formdata.append("type", "daily_test");
        }
        if (this.$route.params.type == "subscription_mock_test") {
          formdata.append("type", "subscription_mock_test");
          formdata.append("test_or_drive_id", this.$route.params.testId);
        }
        if (this.$route.params.type == "ongoing") {
          formdata.append("type", "ongoing");
          formdata.append("test_or_drive_id", this.$route.params.testId);
        }
        if (this.$route.params.type == "practice-test") {
          formdata.append("test_id", this.$route.params.testId);
        }
        if (this.$route.params.type == "all_india_mock_test") {
          formdata.append("type", "all_india_mock_test");
          formdata.append("test_or_drive_id", this.$route.params.testId);
        }
        await axios
          .post(
            this.$route.params.type != "practice-test"
              ? `${this.$store.getters.baseUrl}/mock-tests/make-test`
              : `${this.$store.getters.baseUrl}/subscription/test_questions`,
            formdata,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then(async (response) => {
            //Response is store using transation in the name of question with persmission to read anad write
            console.log(response);
            if (response.data.status == "success") {
              let tx = await this.db.transaction("questions", "readwrite");
              tx.oncomplete = (ev) => {
                console.log("transaction completed", ev);
              };
              tx.onerror = (err) => {
                console.log("transaction error", err);
              };

              response.data.topic_questions.forEach((data) => {
                data.questions = data.questions.map((question) => {
                  this.no++;
                  return {
                    ...question,
                    qno: this.no,
                    userAnswer: null,
                    remarks: null,
                  };
                });
                let request = tx.objectStore("questions").add(data);
                request.onsuccess = (ev) => {
                  console.log("successfully added an object", ev);
                };
                request.onerror = (err) => {
                  console.log("Error while adding object", err);
                };
              });
              this.no = 0;

              let request = tx.objectStore("questions").add({
                id: "currentStatus",
                currentTopic: this.topicIndex,
                currentQuestion: this.questionIndex,
                currentDuration: 0,
              });
              request.onsuccess = (ev) => {
                console.log("successfully added an object", ev);
              };
              request.onerror = (err) => {
                console.log("Error while adding object", err);
              };

              let finalTopicArray = [];
              for (var i = 0; i < response.data.topic_questions.length; i++) {
                finalTopicArray.push({
                  topic_id: response.data.topic_questions[i].topic_id,
                  no_of_questions:
                    response.data.topic_questions[i].no_of_questions,
                  questions: (response.data.topic_questions[
                    i
                  ].questions = response.data.topic_questions[i].questions.map(
                    (question) => {
                      return {
                        question_id: question.id,
                        question_type: question.question_type,
                        option_id: "",
                        user_answer: "",
                      };
                    }
                  )),
                });
              }
              var today = new Date();

              var date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate();

              var time =
                today.getHours() +
                ":" +
                today.getMinutes() +
                ":" +
                today.getSeconds();

              var dateTime = date + " " + time;
              let finalObject = {
                id: "finalObject",
                test_id: this.$route.params.submitId,
                start_date_time: dateTime,
                end_date_time: "",
                testExpiry: localStorage.getItem("testExpiry"),
                topic: finalTopicArray,
              };

              let request1 = tx.objectStore("questions").add(finalObject);
              request1.onsuccess = (ev) => {
                console.log("successfully added an object", ev);
              };
              request1.onerror = (err) => {
                console.log("Error while adding object", err);
              };

              this.getData();
            } else {
              await this.db.close();
              var req = indexedDB.deleteDatabase("QuestionsDB");
              req.onsuccess = function(e) {
                console.log("Deleted database successfully", e);
              };
              req.onblocked = function(e) {
                console.log("blocked", e);
              };
              req.onerror = function(e) {
                console.log("Couldn't delete database", e);
                return;
              };
              this.submitAPI = 1;
              this.$router.push({ name: "Home" });
              this.$swal("Error", response.data.message, "warning");
            }
          })
          .catch(async (error) => {
            await this.db.close();
            var req = indexedDB.deleteDatabase("QuestionsDB");
            req.onsuccess = function(e) {
              console.log("Deleted database successfully", e);
            };
            req.onblocked = function(e) {
              console.log("blocked", e);
            };
            req.onerror = function(e) {
              console.log("Couldn't delete database", e);
              return;
            };
            this.submitAPI = 1;
            this.$router.push({ name: "Home" });
            this.$swal("Error", error.response.data.message, "error");
          });
      });
    },
    // DB get function db transation means passing the data to the transation
    async getData() {
      let tx = this.db.transaction("questions", "readonly");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.getAll();
      data.onsuccess = (ev) => {
        if (this.topics.length == 0) {
          ev.target.result.forEach((data) => {
            if (data.id != "currentStatus" && data.id != "finalObject") {
              this.topics.push(data);
            }
          });
        }
        this.totalQuestions == 0
          ? this.topics.forEach(
              (topic) =>
                (this.totalQuestions =
                  this.totalQuestions + topic.no_of_questions)
            )
          : "";
      };
      data.onerror = (err) => {
        console.log(err);
      };
    },

    next() {
      if (
        this.questionIndex ==
        this.topics[this.topicIndex].questions.length - 1
      ) {
        this.$swal({
          title: "Are you sure?",
          icon: "warning",
          text:
            "Clicking Yes will take you to the next section and will not allow you to come back to this section again. Do you want to proceed ?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCancelButton: true,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            clearInterval(this.time);
            this.time = null;
            this.topicIndex = this.topicIndex + 1;
            this.questionIndex = 0;
            this.updateStatus();
          }
        });
      } else {
        this.questionIndex = this.questionIndex + 1;
        this.updateStatus();
      }
    },

    previous() {
      this.questionIndex = this.questionIndex - 1;
      this.updateStatus();
    },

    noBack() {
      this.$swal(
        "Warning",
        "You can't go back to the previous section",
        "warning"
      );
    },

    questionPallete(index1) {
      this.questionIndex = index1;
      this.updateStatus();
    },

    skipSection() {
      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        text:
          "Clicking Yes will skip this section and will not allow you to come back to this section again. Do you want to proceed ?",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          clearInterval(this.time);
          this.time = null;
          this.topicIndex = this.topicIndex + 1;
          this.questionIndex = 0;
          this.updateStatus();
        }
      });
    },

    async fillupUpdate(qno, topicId, topicInd, questionInd) {
      let tx = this.db.transaction("questions", "readonly");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.get(topicId);

      data.onsuccess = async (eve) => {
        let tx1 = this.db.transaction("questions", "readwrite");
        tx1.oncomplete = (ev) => {
          console.log(ev);
        };

        tx1.onerror = (err) => {
          console.log(err);
        };
        let fillup = document.getElementById(`fillup${qno}`).value;
        let dataToUpdate = eve.target.result;
        dataToUpdate.questions[questionInd].userAnswer = fillup;

        let dbData1 = await tx1.objectStore("questions");
        let data1 = await dbData1.put(dataToUpdate);

        data1.onsuccess = async (ev) => {
          console.log("user answer updated successfully", ev);
          let fillup = document.getElementById(`fillup${qno}`).value;
          this.topics[topicInd].questions[questionInd].userAnswer = fillup;

          let tx = this.db.transaction("questions", "readonly");
          tx.oncomplete = (ev) => {
            console.log(ev);
          };

          tx.onerror = (err) => {
            console.log(err);
          };

          let dbData = await tx.objectStore("questions");
          let data = await dbData.get("finalObject");

          data.onsuccess = async (eve) => {
            let tx1 = this.db.transaction("questions", "readwrite");
            tx1.oncomplete = (ev) => {
              console.log(ev);
            };

            tx1.onerror = (err) => {
              console.log(err);
            };
            let fillup = document.getElementById(`fillup${qno}`).value;
            let dataToUpdate = eve.target.result;
            dataToUpdate.topic[topicInd].questions[
              questionInd
            ].user_answer = fillup;

            let dbData1 = await tx1.objectStore("questions");
            let data1 = await dbData1.put(dataToUpdate);

            data1.onsuccess = (ev) => {
              console.log("Fillup updated successfully", ev);
            };

            data1.onerror = (err) => {
              console.log("Error while updating fillup", err);
            };
          };

          data1.onerror = (err) => {
            console.log("error updating the user answer", err);
          };
        };
      };

      data.onerror = (err) => {
        console.log("error updating the user answer", err);
      };
    },

    async codingUpdate(qno, topicId, topicInd, questionInd) {

      let tx = this.db.transaction("questions", "readonly");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.get(topicId);

      data.onsuccess = async (eve) => {
        let tx1 = this.db.transaction("questions", "readwrite");
        tx1.oncomplete = (ev) => {
          console.log(ev);
        };

        tx1.onerror = (err) => {
          console.log(err);
        };
        let coding_language = document.getElementById(`coding-language${qno}`).value;
        let dataToUpdate = eve.target.result;
        dataToUpdate.questions[questionInd].language = coding_language;
        var editor = ace.edit("MyAceEditor");
        var code = editor.getSession().getValue();
        let coding_source_code = editor.getValue();
        let dataToUpdatesc = eve.target.result;
        dataToUpdatesc.questions[questionInd].source_code = coding_source_code;

        console.log(coding_language);
        console.log(coding_source_code);

        let dbData1 = await tx1.objectStore("questions");
        let data1 = await dbData1.put(dataToUpdate);
        let data2 = await dbData1.put(dataToUpdatesc);

        data1.onsuccess = async (ev) => {
          console.log("user answer updated successfully", ev);
          let coding_language = document.getElementById(`coding-language${qno}`).value;
          this.topics[topicInd].questions[questionInd].language = coding_language;

          let tx = this.db.transaction("questions", "readonly");
          tx.oncomplete = (ev) => {
            console.log(ev);
          };

          tx.onerror = (err) => {
            console.log(err);
          };

          let dbData = await tx.objectStore("questions");
          let data = await dbData.get("finalObject");

          data.onsuccess = async (eve) => {
            let tx1 = this.db.transaction("questions", "readwrite");
            tx1.oncomplete = (ev) => {
              console.log(ev);
            };

            tx1.onerror = (err) => {
              console.log(err);
            };
            let coding_language = document.getElementById(`coding-language${qno}`).value;
            let dataToUpdate = eve.target.result;
            dataToUpdate.topic[topicInd].questions[
              questionInd
            ].language = coding_language;

            let dbData1 = await tx1.objectStore("questions");
            let data1 = await dbData1.put(dataToUpdate);

            data1.onsuccess = (ev) => {
              console.log("coding updated successfully", ev);
            };

            data1.onerror = (err) => {
              console.log("Error while updating coding", err);
            };
          };

          data1.onerror = (err) => {
            console.log("error updating the user answer", err);
          };
        };
        data2.onsuccess = async (ev) => {
          console.log("user answer updated successfully", ev);
          var editor = ace.edit("MyAceEditor");
          var code = editor.getSession().getValue();
          let coding_source_code = editor.getValue();
          this.topics[topicInd].questions[questionInd].source_code = coding_source_code;

          let tx = this.db.transaction("questions", "readonly");
          tx.oncomplete = (ev) => {
            console.log(ev);
          };

          tx.onerror = (err) => {
            console.log(err);
          };

          let dbData = await tx.objectStore("questions");
          let data = await dbData.get("finalObject");

          data.onsuccess = async (eve) => {
            let tx1 = this.db.transaction("questions", "readwrite");
            tx1.oncomplete = (ev) => {
              console.log(ev);
            };

            tx1.onerror = (err) => {
              console.log(err);
            };
            var editor = ace.edit("MyAceEditor");
            var code = editor.getSession().getValue();
            let coding_source_code = editor.getValue();
            let dataToUpdatesc = eve.target.result;
            dataToUpdatesc.topic[topicInd].questions[
              questionInd
            ].source_code = coding_source_code;

            let dbData1 = await tx1.objectStore("questions");
            let data1 = await dbData1.put(dataToUpdatesc);

            data1.onsuccess = (ev) => {
              console.log("coding updated successfully", ev);
            };

            data1.onerror = (err) => {
              console.log("Error while updating coding", err);
            };
          };

          data1.onerror = (err) => {
            console.log("error updating the user answer", err);
          };
        };
      };

      data.onerror = (err) => {
        console.log("error updating the user answer", err);
      };
      
      console.log("check");
      console.log(this.topics[topicInd].questions[questionInd]);

    },




    async updateStatus() {
      let tx = this.db.transaction("questions", "readwrite");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };
      let dataToUpdate = {
        id: "currentStatus",
        currentTopic: this.topicIndex,
        currentQuestion: this.questionIndex,
        currentDuration: this.refreshDuration,
      };
      let dbData = await tx.objectStore("questions");
      let data = await dbData.put(dataToUpdate);

      data.onsuccess = (ev) => {
        console.log("status updated successfully", ev);
      };

      data.onerror = (ev) => {
        console.log("error updating the status", ev);
      };
    },

    timer(duration) {
      if (duration) {
        var timeleft = duration * 60;
        this.time = setInterval(() => {
          timeleft = timeleft - 1;
          this.refreshDuration = timeleft == -1 ? 1 : timeleft;
          this.updateStatus();
          let timeFormat = `${
            Math.floor(timeleft / 60) < 10
              ? `0${Math.floor(timeleft / 60)}`
              : `${Math.floor(timeleft / 60)}`
          } : ${
            Math.floor(timeleft % 60) < 10
              ? `0${Math.floor(timeleft % 60)}`
              : `${Math.floor(timeleft % 60)}`
          }`;
          if (timeleft == -1) {
            if (this.topicIndex != this.topics.length - 1) {
              clearInterval(this.time);
              this.time = null;
              this.topicIndex = this.topicIndex + 1;
              this.questionIndex = 0;
              this.updateStatus();
            } else {
              this.updateStatus();
              clearInterval(this.time);
              this.submitTest();
            }
          } else document.getElementById("timer").innerHTML = timeFormat;
        }, 1000);
      }
    },

    readMore(qno) {
      let groupQuestion = document.getElementById(`group${qno}`);
      groupQuestion.style.display =
        groupQuestion.style.display == "none" ? "block" : "none";

      let readMore = document.getElementById(`readMore${qno}`);
      readMore.innerHTML =
        groupQuestion.style.display == "none" ? "read more" : "read less";
    },

    resumeClick() {
      document.getElementById("modalClose").click();
    },
    async modalData() {
      let tx = this.db.transaction("questions", "readonly");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.getAll();

      data.onsuccess = (ev) => {
        console.log("status updated successfully", ev);
        var unattempted = 0;
        var answered = 0;
        var remarks = 0;
        ev.target.result.forEach((topic) => {
          if (topic.id != "currentStatus" && topic.id != "finalObject") {
            topic.questions.forEach((question) => {
              if (question.userAnswer == null && question.remarks == null) {
                unattempted = unattempted + 1;
              } else if (question.remarks == 1) {
                remarks = remarks + 1;
              } else {
                answered = answered + 1;
              }
            });
          }
        });
        console.log(remarks);
        document.getElementById("answered").innerHTML = answered;
        document.getElementById("unattempted").innerHTML = unattempted;
        document.getElementById("remarks").innerHTML = remarks;
      };

      data.onerror = (ev) => {
        console.log("error updating the status", ev);
      };
    },

    async submitTest() {
      document.getElementById("modalClose").click();

      let tx = this.db.transaction("questions", "readonly");
      tx.oncomplete = (ev) => {
        console.log(ev);
      };

      tx.onerror = (err) => {
        console.log(err);
      };

      let dbData = await tx.objectStore("questions");
      let data = await dbData.get("finalObject");

      data.onsuccess = async (eve) => {
        let tx1 = this.db.transaction("questions", "readwrite");
        tx1.oncomplete = (ev) => {
          console.log(ev);
        };

        tx1.onerror = (err) => {
          console.log(err);
        };

        var today = new Date();

        var date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();

        var time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();

        var dateTime = date + " " + time;
        let dataToUpdate = eve.target.result;
        dataToUpdate.end_date_time = dateTime;

        let dbData1 = await tx1.objectStore("questions");
        let data1 = await dbData1.put(dataToUpdate);

        data1.onsuccess = async (ev) => {
          console.log(ev);

          let tx = this.db.transaction("questions", "readonly");
          tx.oncomplete = (ev) => {
            console.log(ev);
          };

          tx.onerror = (err) => {
            console.log(err);
          };

          let dbData = await tx.objectStore("questions");
          let data = await dbData.get("finalObject");
          data.onsuccess = async (ev) => {
            let final = ev.target.result;
            console.log(final.topic);
            const formdata = new FormData();
            formdata.append("test_id", final.test_id);
            formdata.append("start_date_time", final.start_date_time);
            formdata.append("end_date_time", final.end_date_time);
            for (var i = 0; i < final.topic.length; i++) {
              formdata.append(
                "topic[" + i + "][topic_id]",
                final.topic[i].topic_id
              );
              formdata.append(
                "topic[" + i + "][no_of_questions]",
                final.topic[i].no_of_questions
              );
              for (var j = 0; j < final.topic[i].questions.length; j++) {
                formdata.append(
                  "topic[" + i + "][questions][" + j + "][question_id]",
                  final.topic[i].questions[j].question_id
                );
                formdata.append(
                  "topic[" + i + "][questions][" + j + "][question_type]",
                  final.topic[i].questions[j].question_type
                );
                formdata.append(
                  "topic[" + i + "][questions][" + j + "][option_id]",
                  final.topic[i].questions[j].option_id
                );
                formdata.append(
                  "topic[" + i + "][questions][" + j + "][user_answer]",
                  final.topic[i].questions[j].user_answer
                );
                formdata.append(
                  "topic[" + i + "][questions][" + j + "][language]",
                  final.topic[i].questions[j].language
                );
                formdata.append(
                  "topic[" + i + "][questions][" + j + "][source_code]",
                  final.topic[i].questions[j].source_code
                );
              }
            }
            await axios
              .post(
                `${this.$store.getters.baseUrl}/mock-tests/submit-test`,
                formdata,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then(async (response) => {
                console.log(response);
                if (response.data.status == "success") {
                  clearInterval(this.time);
                  await this.db.close();
                  var req = indexedDB.deleteDatabase("QuestionsDB");
                  req.onsuccess = function(e) {
                    console.log("Deleted database successfully", e);
                  };
                  req.onblocked = async function(e) {
                    await this.db.close();
                    console.log("blocked", e);
                  };
                  req.onerror = function(e) {
                    console.log("Couldn't delete database", e);
                    return;
                  };
                  if (response.data.test_status == 3) {
                    this.submitAPI = 1;
                    this.$router.push({ name: "Home" });
                    this.$swal("Success", response.data.message, "success");
                  } else {
                    this.submitAPI = 1;
                    this.$router.push({ name: "Home" });
                    this.$swal({
                      title: "Success",
                      icon: "success",
                      text: response.data.message,
                      confirmButtonText: "View Result",
                      cancelButtonText: "Ok",
                      showCancelButton: true,
                      reverseButtons: true,
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.submitAPI = 1;
                        this.$router.push({
                          name: "Result",
                          params: { testId: response.data.user_test_id },
                        });
                      } else {
                        this.submitAPI = 1;
                        this.$route.name != "Home"
                          ? this.$router.push({ name: "Home" })
                          : "";
                      }
                    });
                  }
                } else {
                  this.$swal("Error", response.data.message, "warning");
                }
              })
              .catch((error) => {
                console.log(error);
                this.$swal("Error", error.response.data.message, "error");
              });
          };
        };

        data1.onerror = (err) => {
          console.log(err);
        };
      };

      data.onerror = (err) => {
        console.log(err);
      };
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.$route.params.type != "practice-test") {
      if (to.name == "Home") {
        next();
      } else {
        this.$swal(
          "Warning",
          "Please complete the test before moving to other screens",
          "warning"
        );
        next(false);
      }
    } else {
      if (this.submitAPI == 1) {
        if (to.name == "Home") {
          next();
        }
      } else {
        this.$swal({
          title: "Pause Test",
          icon: "warning",
          text: "You shall pause the test and continue at your convenience",
          confirmButtonText: "Pause & Exit",
          cancelButtonText: "Continue Test",
          showCancelButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.isConfirmed) {
            let playpauseButton = document.getElementById("pas-play");
            playpauseButton.className = "fas fa-play";
            clearInterval(this.time);
            next();
          }
          if (result.isDismissed) {
            let playpauseButton = document.getElementById("pas-play");
            playpauseButton.className = "fas fa-pause";
            this.refresh();
          }
        });
      }
    }
  },

  beforeDestroy() {
    if (this.time) clearInterval(this.time);
  },
};
</script>

<style scoped>
.read-more {
  font-size: 15px;
  color: #fff;
  background: linear-gradient(#0530ba, #021b79);
  border-radius: 60px;
  border: 2px solid #0530ba;
  transition: 0.2s all linear;
  text-transform: uppercase;
  font-weight: 600;
  /* display: inherit; */
  text-align: center;
  padding: 6px 15px;
  height: fit-content;
}

.read-more:hover {
  transition: 0.2s all linear;
  border: 2px solid #0530ba;
  background: #e2e5ff;
  color: #0530ba;
}

dl dt {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  border-radius: 50%;
  border: 2px solid #0530ba !important;
}
dl dd {
  display: inline-block;
  margin: 0px 10px;
  padding-bottom: 0;
  vertical-align: middle;
}
dl dt.red {
  background: #0530ba;
}
dl dt.yellow {
  background: #fff;
  border-color: #0530ba;
}
dl dt.rem {
  background: #c98c30;
  border-color: #0530ba;
}
.question-img {
  display: block !important;
}
</style>
