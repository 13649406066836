<template>
  <div>
    <Header />
    <section class="other-banner">
      <div class="banner-content">
        <div class="banner-caption">
          <h1>{{ $route.params.subscriptionName }}</h1>
          <div class="banner-btn mt-1 text-dark">
            <nav class="flex-sm-00-auto ml-sm-3" aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-alt">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </li>
                <li
                  v-if="$route.params.categoryName"
                  class="breadcrumb-item"
                  aria-current="page"
                >
                  <router-link :to="{ name: 'Placement Prep' }">{{
                    $route.params.categoryName
                  }}</router-link>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Subscription Description' }">{{
                    $route.params.subscriptionName
                  }}</router-link>
                </li>

                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Subscription Video List' }"
                    >Video List</router-link
                  >
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>

    <section class="plac-pre-prac-tst mt-4 my-2">
      <!-- Parent topics  -->
      <ul class="nav nav-tabs plac-prep-nav-link" id="myTab" role="tablist">
        <li
          class="nav-item"
          role="presentation"
          v-for="(parentTopic, index) in parentTopics"
          :key="index"
        >
          <button
            @click="(parentTopicId = parentTopic.id), getSubtopics()"
            class="nav-link"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            {{ parentTopic.topic_name }}
          </button>
        </li>
      </ul>
      <!-- End of parent topic -->

      <!-- video list -->
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <section
            class="tl-lst-tst pt-0 pb-2"
            v-for="(subtopic, index) in subtopics"
            :key="index"
          >
            <router-link
              class="in-list-test"
              :class="
                userSubscriptionStatus == 1 || subtopic.is_free == 1
                  ? 'pe-auto'
                  : 'disablee pe-none'
              "
              :to="{
                name: 'Subscription Videos',
                params: {
                  subscriptionId: $route.params.subscriptionId,
                  subscriptionName: $route.params.subscriptionName,
                  subtopicName: subtopic.topic.name,
                  subtopicId: subtopic.id,
                  categoryName: $route.params.categoryName,
                  categoryId: $route.params.categoryId,
                },
              }"
            >
              <div href="" class="list-test">
                <div class="d-flex">
                  <div class="lft-lst-tst flex-grow-1 pe-4">
                    <h5>{{ subtopic.topic.name }}</h5>
                    <!-- <h4>
                                    <span>₹</span>652
                                </h4> -->
                    <p class="text-primary">{{ subtopic.topic.description }}</p>
                    <div class="d-flex">
                      <p
                        class="play-vid me-3"
                        v-if="
                          userSubscriptionStatus == 1 || subtopic.is_free == 1
                        "
                      >
                        <i class="fas fa-play"></i>
                      </p>
                      <p
                        class="lock-vid"
                        v-else-if="
                          userSubscriptionStatus != 1 && subtopic.is_free == 0
                        "
                      >
                        <i class="fas fa-lock"></i>
                      </p>
                    </div>
                  </div>
                  <div class="rt-comp-img d-grid">
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
              <div href="" class="try-a-test">Watch Videos</div>
            </router-link>
          </section>
        </div>
        <!-- End of video list -->
      </div>
    </section>
    <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      parentTopics: [],
      parentTopicId: "",
      subtopics: [],
      loader: false,
      userSubscriptionStatus: 0,
    };
  },
  mounted: async function() {
    await this.getParentTopics();
    this.getSubtopics();
  },
  methods: {
    async getParentTopics() {
      this.loader = true;
      const formdata = new FormData();
      formdata.append("sub_id", this.$route.params.subscriptionId);
      formdata.append("sub_content", "videos");

      await axios
        .post(
          `${this.$store.getters.baseUrl}/subscription/parent_topics`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.parentTopics = response.data.topics;
            this.parentTopicId = this.parentTopics[0].id;
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async getSubtopics() {
      this.loader = true;
      console.log(this.parentTopicId);
      const formdata = new FormData();
      formdata.append("sub_id", this.$route.params.subscriptionId);
      formdata.append("sub_content", "videos");
      formdata.append("mapped_topic_id", this.parentTopicId);

      await axios
        .post(
          `${this.$store.getters.baseUrl}/subscription/sub_topics`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.subtopics = response.data.sub_topics;
            this.userSubscriptionStatus = response.data.user_sub_status;
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
