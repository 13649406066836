<template>
  <div>
    <section
      class="other-banner"
      style="background-position: center;min-height:auto;"
      :style="{ backgroundImage: `url(${backgroundUrl})` }"
    >
      <Header />
      <!-- <div class="banner-content">
        <div class="banner-caption">
            <h1>Profile</h1>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Error quaerat alias beatae laboriosam nobis
                tempore
                harum eos architecto illo laborum iure deleniti sunt</p>
            <div class="banner-btn mt-1">
                <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
                <a class="lst-a mx-1">Profile</a>
            </div>
            <button class="btn-sub-trail mt-3">Edit</button>
        </div>
    </div> -->
    </section>
    <section class="d-flex prof-page">
      <div class="left-prof-pag">
        <div class="prof-name text-center pb-5">
          <img
            :src="profileName.image"
            alt=""
            style="border-radius: 75px; width: 75px; height: 75px"
          />
          <label class="cameraButton">
            <i class="fas fa-camera"></i>
            <input
              type="file"
              accept="image/*;capture=camera"
              @change="imageEdit($event)"
            />
          </label>
          <button class="edit-btn" @click="edit = true">
            <i class="fas fa-pen-nib"></i>
          </button>
        </div>
         <div class="detail-prof-lst mt-4">
          <ul>
            <router-link :to="{ name: 'Profile' }">
            <li><a class="active prof-lft-lst">Profile</a></li>
            </router-link>
            <router-link :to="{ name: 'Subscription' }">
              <li>
                <a class="prof-lft-lst">Subscription</a>
              </li>
            </router-link>
            <router-link :to="{ name: 'About Us' }">
              <li><a class="active prof-lft-lst">About us</a></li>
            </router-link>
            <router-link :to="{ name: 'Invite Earn' }">
              <li>
                <a class="prof-lft-lst"> Invite & Earn</a>
              </li>
            </router-link>
            <router-link :to="{ name: 'Faq' }">
              <li>
                <a class="prof-lft-lst">FAQ</a>
              </li>
            </router-link>
            <router-link :to="{ name: 'Help And Support' }">
              <li>
                <a class="prof-lft-lst">Help And Support</a>
              </li>
            </router-link>

            <router-link :to="{ name: 'Rate Us' }">
              <li><a class="prof-lft-lst">Rate Us</a></li>
            </router-link>
           <!-- <li><a class="prof-lft-lst" href="#">Share</a></li> -->
          </ul>
        </div>
        <button class="logout-pag" @click="logout">
          <i class="fas fa-power-off me-2"></i>Logout
        </button>
      </div>
      <div class="rit-prof inv-earn-page px-3 flex-grow-1">
        <div class="row">
          <div class="col-6">
            <img
              class="w-100"
              src="../../src/assets/img/login/lft-img.jpg"
              alt=""
            />
          </div>
          <div class="col-6 m-auto">
            <div class="ind-rank-det ps-0">
              <h3 class="mb-0">Invite and Earn</h3>
            </div>
            <div class="ind-rank-det ps-0">
              <h5 class="mb-0">
                <b
                  >Share your referral code <br />
                  {{ profileName.mobile }}</b
                >
              </h5>
            </div>
            <div class="d-flex">
              <a href="#" class="play-shar me-3">
                <i class="fab fa-google-play"></i> Play store
              </a>
              <a href="#" class="play-shar">
                <i class="fab fa-google"></i> Google
              </a>
            </div>
            <div class="mt-3 acc-red">
              <p>
                <span>Accrued Points </span>:
                {{ profileName.redeem_points_earned }}
              </p>
              <p>
                <span>Redeemed Points </span>:
                {{ profileName.redeem_points_spent }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import backgroundUrl from "../../src/assets/img/banner.png";
export default {
  name: "Profile",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      backgroundUrl,
      profileName: localStorage.getItem("userDetails")
        ? JSON.parse(localStorage.getItem("userDetails"))
        : "",
    };
  },
  methods : {
     logout() {
      localStorage.clear();
      sessionStorage.clear();
      indexedDB.deleteDatabase("QuestionsDB");
      this.$router.push({ name: "Login" });
      window.location.reload();
    },
  }
};
</script>
<style scoped>
label.cameraButton i {
  border: 2px solid #fff;
  color: #fff;
  background: linear-gradient(#0530ba, #021b79);
  transition: 0.2s all linear;
  width: 35px;
  height: 35px;
  line-height: 30px;
  border-radius: 50px;
  position: absolute;
  top: 30%;
  right: 28%;
}

/* Look like a clicked/depressed button */
label.cameraButton:active {
  border-color: #ccc #eee #eee #ccc;
}

/* This is the part that actually hides the 'Choose file' text box for camera inputs */
label.cameraButton input[accept*="camera"] {
  display: none;
}
</style>
