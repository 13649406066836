<template>
  <header class="head-page">
    <div class="top-header d-flex justify-content-end px-3 py-2">
      <p class="me-3">
        <router-link :to="{ name: 'Profile' }">
          <a href="profile.php"
            ><img class="me-1" src="../../src/assets/img/icons/user.png" />{{
              name
            }}</a
          >
        </router-link>
      </p>
      <p class="notif">
        <router-link class="" :to="{ name: 'Notifications' }"
          ><img
            class="me-1"
            src="../../src/assets/img/icons/notification.png"
          />
        </router-link>
      </p>
    </div>
    <nav class="navbar navbar-expand-xl navbar-light p-0">
      <router-link
        :to="{ name: 'Home' }"
        v-if="
          $route.name == 'Questions' && $route.params.type == 'practice-test'
        "
      >
        <a class="navbar-brand"
          ><img src="../../src/assets/img/main-logo.png" alt=""
        /></a>
      </router-link>
      <router-link
        v-else
        :to="{
          name:
            $route.name == 'Questions' && $route.params.type != 'practice-test'
              ? ''
              : 'Home',
        }"
      >
        <a
          class="navbar-brand"
          @click="
            $route.name == 'Questions' && $route.params.type != 'practice-test'
              ? noBack()
              : ''
          "
          ><img src="../../src/assets/img/main-logo.png" alt=""
        /></a>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        onclick="menuBar()"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <div id="bar-cont1" class="bor-der"></div>
        <div id="bar-cont2" style="width: 15px" class="bor-der"></div>
        <div id="bar-cont3" class="bor-der"></div>
      </button>
      <div class="navbar-collapse nav-bar-coll d-lg-block" id="nav-bar-coll">
        <ul class="navbar-nav ms-auto nav-bar">
          <router-link
            :to="{ name: 'Home' }"
            v-if="
              $route.name == 'Questions' &&
                $route.params.type == 'practice-test'
            "
          >
            <li class="nav-item">
              <a class="nav-link active" aria-current="page">Home</a>
            </li>
          </router-link>
          <router-link
            v-else
            :to="{
              name:
                $route.name == 'Questions' &&
                $route.params.type != 'practice-test'
                  ? ''
                  : 'Home',
            }"
          >
            <li class="nav-item">
              <a
                class="nav-link active"
                aria-current="page"
                @click="
                  $route.name == 'Questions' &&
                  $route.params.type != 'practice-test'
                    ? noBack()
                    : ''
                "
              >
                Home</a
              >
            </li>
          </router-link>

          <li class="nav-item">
            <router-link
              :to="{ name: 'Discussion' }"
              class="nav-link"
              href="discussion.php"
              >Discussion</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="{
                name: 'Daily Challenge',
                params: { testType: 'daily_test' },
              }"
              class="nav-link"
              >Daily Challenge</router-link
            >
          </li>
          <!-- <li class="nav-item">
            <router-link :to="{ name: 'All India Mock Test', params: { testType: 'all_india_mock_test' } }" class="nav-link"
              >All India Mock Test</router-link
            >
             <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Test Categories
            </a>
            <ul
              class="
                dropdown-menu
                main-drop-down
                animate__animated animate__fadeInUp
              "
              aria-labelledby="navbarDropdownMenuLink"
            >
              <li v-for="(category, index) in categories" :key="index">
                <router-link
                  :to="{
                    name: 'Placement Prep',
                    params: {
                      categoryName: category.name,
                      categoryId: category.id,
                    },
                  }"
                  class="dropdown-item"
                  >{{ category.name }}</router-link
                >
              </li>
            </ul>
          </li>
          </li> -->
        </ul>
      </div>

      <!--start Loader -->
      <b-overlay
        :show="loader"
        no-wrap
        blur="2px"
        opacity="0.60"
        class="position-fixed"
      >
        <template #overlay>
          <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
        </template>
      </b-overlay>
      <!-- End Loader -->
    </nav>
  </header>
</template>

<script>
import axios from "axios";
import router from "../router/index";
export default {
  data() {
    return {
      name: localStorage.getItem("userDetails")
        ? JSON.parse(localStorage.getItem("userDetails")).name
        : "",
      categories: [],
      loader: false,
      message: "Hello, world!",
      tab_attempt: 0,
    };
  },
  mounted() {
    this.getDropdownItems();
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },
  methods: {
    beforeDestroy() {
      document.removeEventListener(
        "visibilitychange",
        this.handleVisibilityChange
      );
    },
    noBack() {
      this.$swal(
        "Warning",
        "Please complete the test before moving to other screens",
        "warning"
      );
    },
    async getDropdownItems() {
      await axios
        .get(`${this.$store.getters.baseUrl}/home_page`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          //console.log(response);
          if (response.data.status == "success") {
            this.categories = response.data.categories;
          } else {
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    async handleVisibilityChange() {
      if (document.hidden) {
        // User switched tabs or left the page
        this.message = "Goodbye, world!";
      } else {
        // User came back to the page
        this.tab_attempt += 1;
        this.message = "Welcome back!";
        if (
          this.tab_attempt == 1 ||
          this.tab_attempt == 2 ||
          this.tab_attempt == 3
        ) {
          if (
            this.$route.params.type == "daily_test" ||
            this.$route.params.type == "subscription_mock_test" ||
            this.$route.params.type == "ongoing" ||
            this.$route.params.type == "practice-test" ||
            this.$route.params.type == "all_india_mock_test"
          ) {
            if (this.tab_attempt == 1) {
              this.$swal(
                // "Error",
                "Warning",
                "You have changed the tab once. Doing it the third time will auto close the test.",
                "warning"
              );
            } else if (this.tab_attempt == 2) {
              this.$swal(
                // "Error",
                "Warning",
                "You have changed the tab twice. Doing it the third time will auto close the test.",
                "warning"
              );
            } else {
              this.$swal(
                // "Error",
                "Warning",
                "You have changed the tab thrice. Doing it the third time will auto close the test.",
                "warning"
              );
            }
          }
        }

        if (this.tab_attempt > 3) {
          if (
            this.$route.params.type == "daily_test" ||
            this.$route.params.type == "subscription_mock_test" ||
            this.$route.params.type == "ongoing" ||
            this.$route.params.type == "practice-test" ||
            this.$route.params.type == "all_india_mock_test"
          ) {
            console.log("test-page");
            // this.$swal("Error","You Have changed the tab more than 3 times !", "warning");
            // checking DB exists or not
            if (
              (await window.indexedDB.databases())
                .map((db) => db.name)
                .includes("QuestionsDB")
            ) {
              // 1. DB open
              let DBOpenReq = indexedDB.open("QuestionsDB", 1);
              DBOpenReq.addEventListener("error", (err) => {
                console.log("error");
                console.log(err);
              });

              DBOpenReq.addEventListener("success", async (ev) => {
                console.log(ev);

                var req = indexedDB.deleteDatabase("QuestionsDB");
                req.onsuccess = async function(e) {
                  console.log("Deleted database successfully", e);
                  // router.push({ name: "Home" });
                };
              });

              this.$swal(
                // "Error",
                "Warning",
                "You Have changed the tab more than 3 times,Please Rewrite The Test !",
                "warning"
              );
              console.log("oops-1");

              router.push({ name: "Home" });
              // window.location.reload();
            } else {
              this.$swal(
                // "Error",
                "Warning",
                "You Have changed the tab more than 3 times,Please Rewrite The Test !",
                "warning"
              );
              console.log("oops");

              router.push({ name: "Home" });
              // window.location.reload();
            }
          }
        }
      }
    },
  },
};
</script>
<style>
.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #cc0000;
}
</style>
