<template>
  <div>
    <section class="other-banner">
      <Header />
      <div class="banner-content">
        <div class="banner-caption py-3">
          <h1>{{ $route.params.categoryName }}</h1>
          <div class="banner-btn mt-1">
            <!--          <a class="lst-a me-1 text-primary" href="index.php">Home</a>/
            <a class="lst-a mx-1">Placement Prep</a>-->
            <nav class="flex-sm-00-auto ml-sm-3" aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-alt">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Home' }">Home</router-link>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <router-link :to="{ name: 'Placement Prep' }">{{
                    $route.params.categoryName
                  }}</router-link>
                </li>
              </ol>
            </nav>
          </div>
          <button
            class="btn-sub-trail mt-3"
            v-if="unsubscribedCount > 1"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Buy Combo
          </button>
        </div>
      </div>
    </section>
    <section class="tl-lst-tst">
      <div
        class="in-list-test"
        v-for="(categorySub, index) in categorySubscriptions"
        :key="index"
      >
        <router-link
          class="list-test text-dark"
          :to="{
            name: 'Subscription Description',
            params: {
              subscriptionName: categorySub.name,
              subscriptionId: categorySub.id,
              categrie_name: $route.params.categoryName,
            },
          }"
        >
          <div class="d-flex">
            <div class="lft-lst-tst flex-grow-1 pe-4">
              <h5>{{ categorySub.name }}</h5>
              <h4 v-if="categorySub.user_subscribed == null">
                <span>₹</span>{{ categorySub.price }}
              </h4>
              <p v-if="categorySub.user_subscribed == null">
                Validity {{ categorySub.validity }} days
              </p>
              <p v-if="categorySub.user_subscribed != null">
                Expires On {{ categorySub.user_subscribed.end_date_time }}
              </p>
            </div>
            <div class="rt-comp-img d-grid">
              <img
                :src="categorySub.image"
                style="border-radius: 100px; width: 100px; height: 100px"
              />
            </div>
          </div>
          <router-link
            :to="{
              name: 'Subscription Description',
              params: {
                subscriptionName: categorySub.name,
                subscriptionId: categorySub.id,
                categoryName: $route.params.categoryName,
                categoryId: $route.params.categoryId,
              },
            }"
            class="viw-dwn h6 py-2"
            style="width: fit-content"
            ><b>View Details</b></router-link
          >
        </router-link>
        <!-- <a href="placement-prep-description.php" class="try-a-test">Try Test</a> -->
      </div>
    </section>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Buy Combo Subscription
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModal()"
            ></button>
          </div>
          <div class="modal-body">
            <div
              class="form-check"
              v-for="(subscription, index) in unsubscribedSubscriptions"
              :key="index"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :value="subscription.id"
                :id="`flexCheckDefault${index}`"
                @change="amountCalculation(subscription.id)"
              />
              <label
                class="form-check-label"
                style="display: block"
                :for="`flexCheckDefault${index}`"
              >
                <span style="float: left">{{ subscription.name }}</span>
                <span style="float: right"
                  >&#x20b9;{{ subscription.price }}.00</span
                >
              </label>
            </div>
            <div style="display: none" id="calculation">
              <hr />
              <span style="float: left">Amount (&#x20b9;)</span>
              <span style="float: right" id="amount"></span><br />
              <div style="display: none" id="comboDisplay">
                <span style="float: left">Redeem Points</span>
                <span style="float: right">{{
                  gstAndRedeem.redeem_points
                    ? `${gstAndRedeem.redeem_points}.00`
                    : 0.0
                }}</span
                ><br />
                <span style="float: left">Discount (&#x20b9;)</span>
                <span style="float: right" id="discount"></span><br />
                <hr />
                <b><span style="float: left">Total (&#x20b9;)</span></b>
                <b><span style="float: right" id="total"></span></b>
                <br />
                <span style="float: left"
                  >GST - {{ gstAndRedeem.gst_percent }}%</span
                >
                <span style="float: right" id="gst"></span>
                <br />
                <hr />
                <b><span style="float: left">Grand Total (&#x20b9;)</span></b>
                <b><span style="float: right" id="grandTotal"></span></b>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="proceed">
            <button
              type="button"
              class="btn-sub-trail"
              @click="getComboPayment()"
            >
              Proceed To Pay
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Loader -->
    <b-overlay
      :show="loader"
      no-wrap
      blur="2px"
      opacity="0.60"
      class="position-fixed"
    >
      <template #overlay>
        <i class="fa fa-spinner fa-pulse fa-4x fa-fw text-primary"></i>
      </template>
    </b-overlay>
    <!-- End Loader -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      proceed: false,
      gstAndRedeem: {},
      comboDetails: [],
      selected_id: [], // for combo payment
      categorySubscriptions: [],
      unsubscribedCount: 0,
      unsubscribedSubscriptions: [],
      loader: false,
      discount: 0,
      total: 0,
      gst: 0,
      amount: 0,
      grandTotal: 0,
    };
  },
  mounted() {
    this.getCategorySubscription();
    this.getDiscount();
  },
  methods: {
    closeModal() {
      this.unsubscribedSubscriptions.forEach((subscription, index) => {
        document.getElementById(`flexCheckDefault${index}`).checked = false;
        subscription.checked = false;
      });
      this.proceed = false;
      document.getElementById("calculation").style.display = "none";
      document.getElementById("comboDisplay").style.display = "none";
    },
    async getDiscount() {
      this.loader = true;
      await axios
        .get(`${this.$store.getters.baseUrl}/sub_payment/combo_amount_detail`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.comboDetails = response.data.combo_discount;
            this.gstAndRedeem = response.data.sub_amount_detail;
            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    amountCalculation(id) {
      this.unsubscribedSubscriptions.forEach((subscription) => {
        if (subscription.id == id) {
          subscription.checked = subscription.checked == false ? true : false;
        }
      });
      let selected = [];
      this.selected_id = [];

      this.unsubscribedSubscriptions.forEach((subscription) => {
        if (subscription.checked == true) {
          selected.push(subscription);
          this.selected_id.push(subscription.id);
        }
      });
      console.log(this.selected_id);
      if (selected.length > 1) {
        this.proceed = true;
        document.getElementById("calculation").style.display = "block";
        document.getElementById("comboDisplay").style.display = "block";
        this.amount = 0;
        this.unsubscribedSubscriptions.forEach((subscription) => {
          if (subscription.checked == true) {
            this.amount = this.amount + subscription.price;
            document.getElementById("amount").innerHTML = `${this.amount}.00`;
          }
        });

        this.comboDetails.forEach((combo) => {
          if (selected.length > 5) {
            if (combo.sub_count == 5) {
              document.getElementById(
                "discount"
              ).innerHTML = `&#x20b9;${Math.round(
                Number(this.amount) * (Number(combo.discount) / 100)
              )}.00`;
            }
          } else {
            if (combo.sub_count == selected.length) {
              //console.log(combo.discount);
              document.getElementById("discount").innerHTML = `${Math.round(
                Number(this.amount) * (Number(combo.discount) / 100)
              )}`;
            }
          }
        });
        this.discount = document.getElementById("discount").innerHTML;
        console.log(this.discount);

        this.total = Number(this.amount) - Number(this.discount);
        document.getElementById("total").innerHTML = `${this.total}.00`;

        this.gst = Math.round(
          Number(this.total) * (Number(this.gstAndRedeem.gst_percent) / 100)
        );
        document.getElementById("gst").innerHTML = `${this.gst}.00`;
        document.getElementById("grandTotal").innerHTML = `${Number(
          this.total
        ) + Number(this.gst)}.00`;
        console.log("GST" + this.gst);
        this.grandTotal = this.total + this.gst;
        console.log("Hi" + this.grandTotal);
      }
      if (selected.length == 1) {
        this.proceed = true;
        this.unsubscribedSubscriptions.forEach((subscription) => {
          document.getElementById("calculation").style.display = "block";
          document.getElementById("comboDisplay").style.display = "none";
          if (subscription.checked == true) {
            document.getElementById(
              "amount"
            ).innerHTML = `&#x20b9;${subscription.price}.00`;
          }
        });
      }
      if (selected.length == 0) {
        this.proceed = false;
        document.getElementById("calculation").style.display = "none";
        document.getElementById("comboDisplay").style.display = "none";
      }
    },
    async getCategorySubscription() {
      this.loader = true;
      const formdata = new FormData();
      formdata.append("category_id", this.$route.params.categoryId);

      await axios
        .post(`${this.$store.getters.baseUrl}/subscriptions`, formdata, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          //console.log(response);
          if (response.data.status == "success") {
            this.categorySubscriptions = response.data.subscriptions;
            response.data.subscriptions.forEach((subscription) => {
              if (subscription.user_subscribed == null) {
                this.unsubscribedCount = this.unsubscribedCount + 1;
                this.unsubscribedSubscriptions.push({
                  ...subscription,
                  checked: false,
                });
              }
              this.loader = false;
              //console.log(this.unsubscribedSubscriptions);
            });
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },

    //Payment post
    async getComboPayment() {
      console.log("Combo Payment Intialize");
      console.log("Sub Id" + this.selected_id);
      console.log("redeem_points" + this.gstAndRedeem.redeem_points);
      console.log("discount" + this.discount);
      console.log("gst_amount" + this.gst);
      console.log("gst_percent" + this.gstAndRedeem.gst_percent);
      console.log("total_amount" + this.grandTotal);
      console.log("amount" + this.amount);
      this.loader = true;
      const formdata = new FormData();
      formdata.append("sub_id[]", this.selected_id);
      formdata.append("redeem_points", this.gstAndRedeem.redeem_points);
      formdata.append("discount", this.discount);
      formdata.append("gst_amount", this.gst);
      formdata.append("gst_percent", this.gstAndRedeem.gst_percent);
      formdata.append("total_amount", this.grandTotal);
      formdata.append("amount", this.amount);

      await axios
        .post(
          `${this.$store.getters.baseUrl}/subscription/payment_details`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.status == "success") {
            this.$router.push({
              name: "Subscription Payment Pay",
              params: {
                data: response.data.data,
                hash: response.data.hash,
              },
            }),
              console.log(response.data.message);

            this.loader = false;
          } else {
            this.loader = false;
            this.$swal("Error", response.data.message, "warning");
          }
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
          this.$swal("Error", error.response.data.message, "error");
        });
    },
  },
};
</script>
