<template>
  <div id="app">
    Language :
      <select v-model="selected"> 
      <option disabled value="">Please select one</option>
      <option>C++</option>
      <option>Python</option>
      <option>PHP</option>
      </select>
      <span>Source Code: {{ selected }}</span>
      <CodeEditor :hide_header="true"
      theme="dark"
      font_size="17px"
      width="540px"
      copycode="none"
      height="250px"
      autofocus="true">
      ></CodeEditor>

  </div>


  
</template>

<script>
import CodeEditor from 'simple-code-editor';
export default {
    components: {
      CodeEditor
    },
    data() {
        return {
          value: 'source_code',
          selected:''
        }
    }
}
</script>

<style>
#app {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
h2 {
  margin-bottom: 0;
  margin-top: 2rem;
}
.input {
  padding: 5px;
  max-width: 720px;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 90%;
}
.code_editor > .code_area > textarea[data-v-76b5d460] {
    -webkit-text-fill-color: rgb(255, 255, 255) !important;
}
.header[data-v-76b5d460] {
  height: 10px !important; 
}
.buttons {
  margin-top: 25px;
}
</style>
